import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const TableOverflow = styled.div`
  width: 100%;
  max-width: 100%;

  ${down('md')} {
    overflow-x: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border: 1px ${({ theme }) => theme.colors.inactiveGrey};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};

  tr {
    position: relative;
  }

  th {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
    background-color: ${({ theme }) => theme.colors.secondary};
    font-weight: 500;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  td {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
    font-size: 12px;
    line-height: 20px;
    vertical-align: top;
  }

  tbody {
    position: relative;

    tr:nth-child(even) td {
      background-color: ${({ theme }) => theme.colors.tableGrey};
    }
  }
`;
