import styled from 'styled-components';
import { ReactNode } from 'react';
import { SvgNewWindow } from '@partnerka-front/icons';

const LinkThemeWrapper = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.accent};
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 12px;
    margin-left: 8px;

    path {
      stroke: ${({ theme }) => theme.colors.accent};
    }
  }
`;

interface LinkThemeProps {
  children: ReactNode;
  href: string;
}

export function LinkTheme({ children, href }: LinkThemeProps) {
  return (
    <LinkThemeWrapper href={href} target={'_blank'}>
      {children}
      <SvgNewWindow />
    </LinkThemeWrapper>
  );
}
