import styled from 'styled-components';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useMemo } from 'react';
import { uid } from 'uid';

const MenuItemWrapper = styled.div`
  display: block;

  input {
    position: absolute;
    opacity: 0;
  }
`;

const MenuItemContent = styled.label`
  display: block;
  width: 100%;
  padding: 15px 20px 0;
  cursor: pointer;

  input:checked + & {
    background-color: ${({ theme }) => theme.colors.tableGrey};
  }
`;

const MenuItemTitle = styled.div`
  cursor: pointer;
`;

const MenuItemText = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.secondary};
`;

interface MenuItemProps {
  name: string;
  label: string;
  text: string;
  register: UseFormRegisterReturn<any>;
  value: string;
  onClose: any;
}

export function MenuItem({ label, value, name, text, register, onClose }: MenuItemProps) {
  const id = useMemo(() => uid(), []);

  return (
    <MenuItemWrapper
      onClick={(event) => {
        event.stopPropagation();
        setTimeout(() => {
          onClose();
        }, 100);
      }}>
      <input {...register} type={'radio'} name={name} value={value} id={id} />
      <MenuItemContent htmlFor={id}>
        <MenuItemTitle>{label}</MenuItemTitle>
        <MenuItemText>{text}</MenuItemText>
      </MenuItemContent>
    </MenuItemWrapper>
  );
}
