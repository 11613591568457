import { Currency, RewardDto } from '@partnerka-front/types';
import { Price } from '@partnerka-front/components';

interface RemunerationPercentProps {
  reward: RewardDto;
}

export function RemunerationPercent({ reward }: RemunerationPercentProps) {
  if (reward.type === 'Percent' && reward.from && reward.to) {
    return (
      <>
        {reward.from}
        {reward.to > reward.from && <>-{reward.to}</>}%
      </>
    );
  }

  if (reward.type === 'Fix' && reward.from && reward.to) {
    return (
      <>
        <Price amount={reward.from} currency={Currency.USD} />
        {reward.to > reward.from && (
          <>
            -
            <Price amount={reward.to} currency={Currency.USD} />
          </>
        )}
      </>
    );
  }

  if (reward.type === 'Hybrid') return <>Hybrid</>;

  return <>-</>;
}
