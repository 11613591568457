export function SvgUpdateArrows() {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.5306 11.4113L43.2431 16.7213C46.7869 20.2669 48.75 24.9825 48.75 30C48.75 40.3425 40.3425 48.75 30 48.75V45L22.5 52.5L30 60V56.25C44.4731 56.25 56.25 44.4731 56.25 30C56.25 22.9763 53.5106 16.3763 48.5306 11.4113Z"
        fill="#2356F6"
      />
      <path
        d="M37.5 7.5L30 0V3.75C15.5269 3.75 3.75 15.5269 3.75 30C3.75 37.0238 6.48938 43.6237 11.4694 48.5887L16.7569 43.2788C13.2131 39.7331 11.25 35.0175 11.25 30C11.25 19.6575 19.6575 11.25 30 11.25V15L37.5 7.5Z"
        fill="#2356F6"
      />
    </svg>
  );
}
