import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '@partnerka-front/api';
import { Modal, ModalProps, ModalLoading, Button } from '@partnerka-front/components';
import { useEventBus } from '@partnerka-front/utils';
import { PostbackEvents } from '@partnerka-front/types';

interface DeletePostbackModalProps extends Partial<ModalProps> {
  id: string;
}

export function DeletePostbackModal({ id, onClose }: DeletePostbackModalProps) {
  const { t } = useTranslation(['postback', 'common']);
  const { emit } = useEventBus();
  const [pending, setPending] = useState(false);

  const deletePostback = async () => {
    setPending(true);

    await Api.deletePostback(id).then(() => {
      emit(PostbackEvents.fetchPostbackTable);
      if (onClose) onClose();
    });

    setPending(false);
  };

  return (
    <Modal
      title={`ID ${id}`}
      onClose={onClose}
      leftBtn={
        <Button variant={'negative'} onClick={() => deletePostback()}>
          {t('common.delete', { ns: 'common' })}
        </Button>
      }
      rightBtn={
        <Button variant={'primary-inactive'} onClick={onClose}>
          {t('common.cancel', { ns: 'common' })}
        </Button>
      }>
      {pending && <ModalLoading />}
      <p>{t('postback.delete_postback_text', { ns: 'postback' })}</p>
    </Modal>
  );
}
