import styled from 'styled-components';
import { Card } from './card';
import { useTranslation } from 'react-i18next';

const TelegramWrapper = styled(Card)`
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 15px 20px;
`;

const Btn = styled.button`
  height: 30px;
  padding: 2px 10px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.accent};
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const TelegramLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-weight: 700;
    font-size: 16px;
  }

  img {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }
`;

export function Telegram() {
  const { t } = useTranslation(['common']);

  return (
    <TelegramWrapper>
      <TelegramLeft>
        <img src="./assets/img/telegram.png" alt="" />
        <p> {t('common.we_telegram', { ns: 'common' })}</p>
      </TelegramLeft>
      <a href={'https://t.me/myshare_systems'} target={'_blank'} rel="noreferrer">
        <Btn> {t('common.subscribe', { ns: 'common' })}</Btn>
      </a>
    </TelegramWrapper>
  );
}
