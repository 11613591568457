import { AxiosResponse } from 'axios';
import { CreateLinkPayload, LinkConstructorDto } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/link_constructor';

export async function createLink(
  payload: CreateLinkPayload
): Promise<AxiosResponse<LinkConstructorDto>> {
  return $api.post(`${basePath}/`, payload);
}

export async function patchLink(id: string, payload: CreateLinkPayload) {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function deleteLink(id: string) {
  return $api.delete(`${basePath}/${id}/`);
}
