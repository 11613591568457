import {
  DateRangeSelector,
  Pagination,
  SkeletonTable,
  TableItem,
  TableNoData,
  TableRow,
  TableSectionFilter,
  TableWrapper,
  Title,
  Container,
  Button,
} from '@partnerka-front/components';
import { usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { SvgStartPostback } from '@partnerka-front/icons';
import { PostbackLogsTableItem } from './item';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DateRange } from '@partnerka-front/types';
import { format } from 'date-fns';

export function PostbackLogsTable() {
  const { t } = useTranslation(['postback', 'validation', 'deals', 'errors', 'common']);

  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const dateFormat = 'yyyy-MM-dd';

  const {
    pending,
    items,
    fetchItems,
    page,
    setPage,
    pageSize,
    setPageSize,
    itemsTotal,
    pagesTotal,
  } = usePaginated((page, pageSize) =>
    Api.getPostbackLogs(page, pageSize, {
      created_at_after: startDate,
      created_at_before: endDate,
    })
  );

  useEffect(() => {
    if (dateRange) {
      setStartDate(format(dateRange[0], dateFormat));
      setEndDate(format(dateRange[1], dateFormat));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [dateRange]);

  return (
    <>
      <Container>
        <Title>{t('postback.logs_title', { ns: 'postback' })}</Title>

        <TableSectionFilter>
          <DateRangeSelector value={dateRange} setValue={setDateRange} />
          <div></div>
          <Button variant={'accent'} maxWidth={'full'} onClick={() => fetchItems()}>
            {t('common.apply', { ns: 'common' })}
          </Button>
        </TableSectionFilter>
      </Container>

      <TableWrapper>
        <TableRow gridTemplateColumns={'120px 200px 260px 80px 300px 230px'}>
          <TableItem>{t('postback.table.date', { ns: 'postback' })}</TableItem>
          <TableItem>PostBack</TableItem>
          <TableItem>{t('postback.table.catalog', { ns: 'postback' })}</TableItem>
          <TableItem>{t('postback.table.status', { ns: 'postback' })}</TableItem>
          <TableItem>{t('postback.table.response', { ns: 'postback' })}</TableItem>
          <TableItem>{t('postback.table.link', { ns: 'postback' })}</TableItem>
        </TableRow>

        {pending ? (
          <SkeletonTable
            gridTemplateColumns={'120px 200px 260px 80px 300px 230px'}
            width={'full'}
          />
        ) : (
          <>
            {items.length > 0 ? (
              items.map((log) => <PostbackLogsTableItem key={log.created_at} log={log} />)
            ) : (
              <TableNoData
                icon={<SvgStartPostback />}
                title={`${t('postback.logs_title', { ns: 'postback' })}`}
                descriptionWidth={'full'}
              />
            )}
          </>
        )}
      </TableWrapper>

      {items && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
