export function SvgSort() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4992 9.4C10.9037 9.4 11.133 9.89555 10.9171 10.2522L10.8832 10.3021L8.38399 13.6021C8.19748 13.8484 7.8642 13.8648 7.65813 13.6514L7.61601 13.6021L5.11681 10.3021C4.85784 9.96016 5.05701 9.44906 5.44423 9.4033L5.5008 9.4H10.4992ZM7.61601 3.1979C7.80252 2.95162 8.1358 2.93521 8.34188 3.14864L8.38399 3.1979L10.8832 6.4979C11.1422 6.83985 10.943 7.35094 10.5558 7.3967L10.4992 7.4H5.5008C5.09628 7.4 4.86695 6.90445 5.0829 6.54783L5.11681 6.4979L7.61601 3.1979Z"
        fill="#557EFF"
      />
    </svg>
  );
}
