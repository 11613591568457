export function SvgCalendar() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4588 4.20449H14.1878V3.54122C14.1878 3.24143 13.9463 3 13.6465 3C13.3467 3 13.1053 3.24143 13.1053 3.54122V4.20449H7.89469V3.54122C7.89469 3.24143 7.65327 3 7.35347 3C7.05367 3 6.81224 3.24143 6.81224 3.54122V4.20449H4.54122C4.24143 4.20449 4 4.44592 4 4.74571V15.4588C4 15.7586 4.24143 16 4.54122 16H16.4588C16.7586 16 17 15.7586 17 15.4588V4.74571C17 4.44592 16.7586 4.20449 16.4588 4.20449ZM6.81224 5.28694V5.70612C6.81224 6.00592 7.05367 6.24735 7.35347 6.24735C7.65327 6.24735 7.89469 6.00592 7.89469 5.70612V5.28694H13.1027V5.70612C13.1027 6.00592 13.3441 6.24735 13.6439 6.24735C13.9437 6.24735 14.1878 6.00592 14.1878 5.70612V5.28694H15.9176V7.48898H5.08245V5.28694H6.81224ZM5.08245 14.9176V8.57143H15.9149V14.9149H5.08245V14.9176Z"
        fill="#868E95"
      />
    </svg>
  );
}
