export function SvgSetting() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61925 11.3807 7.5 10 7.5C8.61925 7.5 7.5 8.61925 7.5 10C7.5 11.3807 8.61925 12.5 10 12.5Z"
        stroke="#35434E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8919 3.7842C11.8919 3.07496 11.3169 2.5 10.6077 2.5H9.39217C8.68292 2.5 8.10794 3.07496 8.10794 3.7842C8.10794 4.32979 7.75556 4.80342 7.27611 5.06382C7.20114 5.10453 7.12721 5.14687 7.05435 5.19079C6.58361 5.47457 5.99367 5.5427 5.51658 5.26972C4.89402 4.91351 4.10068 5.12607 3.73961 5.74584L3.1526 6.75346C2.79296 7.37079 3.0052 8.16291 3.62531 8.51775C4.10036 8.7895 4.33683 9.33267 4.32526 9.87983C4.32442 9.91975 4.32399 9.95983 4.32399 10C4.32399 10.0402 4.32442 10.0803 4.32527 10.1203C4.33684 10.6674 4.10036 11.2105 3.62533 11.4822C3.00523 11.8371 2.79299 12.6292 3.15262 13.2465L3.73966 14.2542C4.10071 14.8739 4.89405 15.0865 5.51661 14.7302C5.99369 14.4573 6.58361 14.5254 7.05436 14.8092C7.12721 14.8532 7.20115 14.8955 7.27611 14.9362C7.75556 15.1966 8.10794 15.6702 8.10794 16.2158C8.10794 16.9251 8.68292 17.5 9.39217 17.5H10.6077C11.3169 17.5 11.8919 16.9251 11.8919 16.2158C11.8919 15.6702 12.2442 15.1966 12.7237 14.9362C12.7987 14.8955 12.8726 14.8532 12.9455 14.8092C13.4162 14.5254 14.0062 14.4573 14.4832 14.7302C15.1057 15.0865 15.8991 14.8739 16.2602 14.2542L16.8472 13.2465C17.2068 12.6292 16.9946 11.8371 16.3745 11.4823C15.8995 11.2105 15.663 10.6674 15.6746 10.1203C15.6754 10.0803 15.6758 10.0402 15.6758 10C15.6758 9.95983 15.6754 9.91975 15.6746 9.87983C15.663 9.33267 15.8995 8.7895 16.3745 8.51775C16.9947 8.1629 17.2068 7.37078 16.8472 6.75345L16.2602 5.74584C15.8992 5.12607 15.1058 4.9135 14.4832 5.26972C14.0062 5.54269 13.4162 5.47457 12.9455 5.19078C12.8726 5.14687 12.7987 5.10452 12.7237 5.06382C12.2442 4.80342 11.8919 4.32979 11.8919 3.7842Z"
        stroke="#35434E"
      />
    </svg>
  );
}
