import { DateFormat, TableItem, TableRow } from '@partnerka-front/components';
import { PostbackLogDto } from '@partnerka-front/types';
import styled from 'styled-components';
import { SvgCopy } from '@partnerka-front/icons';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';

const LogName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LogLink = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
  gap: 16px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const LogLingCopy = styled.div`
  width: 24px;
  height: 24px;
  line-height: 1;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.accent};
    }
  }
`;

interface PostbackLogsTableItemProps {
  log: PostbackLogDto;
}

export function PostbackLogsTableItem({ log }: PostbackLogsTableItemProps) {
  const { t } = useTranslation();

  return (
    <TableRow gridTemplateColumns={'120px 200px 260px 80px 300px 230px'}>
      <TableItem>
        <DateFormat date={log.created_at} dateFormat={'dd.MM.yyyy HH:mm'} />
      </TableItem>
      <TableItem>
        <LogName>{log.postback}</LogName>
      </TableItem>
      <TableItem>{log.catalog}</TableItem>
      <TableItem>{log.status}</TableItem>
      <TableItem>{log.response}</TableItem>
      <TableItem>
        <LogLink>
          <p>{log.url}</p>
          <LogLingCopy
            onClick={() => {
              copy(log.url);
              toast.success(`${t('postback.table.copy_link', { ns: 'postback' })}`);
            }}>
            <SvgCopy />
          </LogLingCopy>
        </LogLink>
      </TableItem>
    </TableRow>
  );
}
