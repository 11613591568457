import { AxiosResponse } from 'axios';
import { TextRulePayload } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/text_rule';

export async function createTextRule(payload: TextRulePayload): Promise<AxiosResponse<null>> {
  return $api.post(`${basePath}/`, payload);
}

export async function patchTextRule(id: string, payload: Partial<TextRulePayload>) {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function deleteTextRule(id: string) {
  return $api.delete(`${basePath}/${id}/`);
}
