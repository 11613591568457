import { LinkConstructorDto, OfferDto, PromoMaterialDto } from '@partnerka-front/types';

export interface CatalogDto {
  id: string;
  catalog_id: string;
  catalog_code: string;
  title: string;
  icon: string;
  company_logo: string | null;
  company_name: string | null;
  preview_image: string | null;
  description: string;
  reward: RewardDto;
  promotional_url: string;
  lending_url: string;
  hold_duration: string;
  canceled_duration: string;
  offers: OfferDto[];
  offer_geo: string[] | null;
  promo?: PromoDto[] | null;
  is_confirmed: boolean;
  is_coupon_allowed: boolean;
  is_promocode_allowed: boolean;
  is_published: boolean;
  approval_rate: number | string;
  reward_type?: RewardType | string | null;
  modified_at: string;
  category: string | null;
  devices: string;
  cookies_storage_days: string | null;
  localizations: string[] | null;
  text_rule: TextRule[];
  promo_materials: PromoMaterialDto[];
  landings?: Landing[];
  rules: Record<Rules, RuleDto>;
  moderation_type: string;
  min_promo_code_percent: number;
  max_promo_code_percent: number;
  list_domains: Domain[];
  coupons: CouponDto[];
}

export interface CouponDto {
  id: string;
  code: string;
  created_at: string;
  fix_discount: number;
  type_code: 'COUPON';
}

export interface CreateCatalogPayload {
  title: string;
  catalog_code: string;
  description?: string;
  preview_image?: string;
}

export interface PromoDto {
  id: string;
  code: string;
  created_at: string;
  percent_discounts: number;
}

export interface TextRule {
  id: string;
  language: string;
  text: string;
}

// TODO: сделать LandingDto и вообще вынести в отдельный файл Landing
export interface Landing {
  id: string;
  title: string;
  image_url: string | null;
  modified_at: string;
  url: string;
  adaptability: 'All' | string;
  catalog: string;
  link_constructor: LinkConstructorDto[];
}

export interface RuleDto {
  is_permitted?: boolean;
  label: string;
  description?: string;
}

export interface Domain {
  id: string;
  domain: string;
}

export interface RewardDto {
  from: number | null;
  to: number | null;
  type: 'Percent' | 'Hybrid' | 'Fix' | null;
}

export type SettingRuleTrafficPayload = Partial<Record<Rules, boolean | null>>;

export enum Rules {
  WEBSITE = 'WEBSITE',
  ONION_SITE = 'ONION_SITE',
  FORUM = 'FORUM',
  WHITE_DOORWAYS = 'WHITE_DOORWAYS',
  GRAY_DOORWAYS = 'GRAY_DOORWAYS',
  BLACK_DOORWAYS = 'BLACK_DOORWAYS',
  CASHBACK = 'CASHBACK',
  NOTICE_BOARDS = 'NOTICE_BOARDS',
  CONTEXTUAL_ADVERTISING = 'CONTEXTUAL_ADVERTISING',
  CONTEXTUAL_ADVERTISING_ON_THE_BRAND = 'CONTEXTUAL_ADVERTISING_ON_THE_BRAND',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  TEASER_ADVERTISING = 'TEASER_ADVERTISING',
  BANNER_ADVERTISING = 'BANNER_ADVERTISING',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
  CLICK_UNDER = 'CLICK_UNDER',
  POP_UP = 'POP_UP',
  POP_UNDER = 'POP_UNDER',
  TOOLBAR = 'TOOLBAR',
  TARGETED_ADVERTISING_IN_SOCIAL_MEDIA = 'TARGETED_ADVERTISING_IN_SOCIAL_MEDIA',
  TELEGRAM_ADS = 'TELEGRAM_ADS',
  VIDEO_ADS = 'VIDEO_ADS',
  NATIVE_ADVERTISING = 'NATIVE_ADVERTISING',
  MOTIVATED_TRAFFIC = 'MOTIVATED_TRAFFIC',
  MOBILE_TRAFFIC = 'MOBILE_TRAFFIC',
  PWA = 'PWA',
  ADULT_TRAFFIC = 'ADULT_TRAFFIC',
}

export enum RewardType {
  RevShare = 'RevShare',
  CPA = 'CPA',
  CPS = 'CPS',
  CPL = 'CPL',
  COD = 'COD',
  CPV = 'CPV',
  CPO = 'CPO',
  CPC = 'CPC',
  CPA_MIN_DEPOSIT = 'CPA_MIN_DEPOSIT',
  CPA_BASELINE = 'CPA_BASELINE',
}
