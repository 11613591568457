import { useEffect } from 'react';
import mitt from 'mitt';
import type { Emitter, EventType } from 'mitt';
import { GlobalEvents, UnknownFunction } from '@partnerka-front/types';

type Events = Record<EventType | keyof typeof GlobalEvents, unknown>;
const emitters: Record<string, Emitter<Events>> = {};

export const useEventBus = <Event = keyof typeof GlobalEvents>(name = 'default') => {
  if (!emitters[name]) {
    emitters[name] = mitt();
  }

  const emitter = emitters[name];
  const subscribe = (event: Extract<Event, string>, handler: UnknownFunction) => {
    useEffect(() => {
      emitter.on(event, handler);
      return () => emitter.off(event, handler);
    });
  };

  const emit = (event: Extract<Event, string>, payload?: unknown) => {
    emitter.emit(event, payload);
  };

  return { subscribe, emit };
};
