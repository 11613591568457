import { Card, Title3 } from '@partnerka-front/components';
import styled from 'styled-components';
import { useStatistic } from '@partnerka-front/utils';

const Items = styled.ul`
  margin-top: 24px;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    color: ${({ theme }) => theme.colors.secondary};
  }

  span {
    font-weight: 700;
    font-size: 16px;
  }
`;

export function CardStatistics() {
  const { statistic } = useStatistic();

  return (
    <Card>
      <Title3>Статистика</Title3>
      <Items>
        <li>
          <label>Подтвержденные</label>
          <span>{statistic.count_by_deal.deal_approved}</span>
        </li>
        <li>
          <label>Сейчас в холде</label>
          <span>{statistic.count_by_deal.deal_paid}</span>
        </li>
        <li>
          <label>Созданные сделки</label>
          <span>{statistic.count_by_deal.deal_created}</span>
        </li>
        <li>
          <label>Отмененные</label>
          <span>{statistic.count_by_deal.deal_canceled}</span>
        </li>
        <li>
          <label>Спорные сделки</label>
          <span>{statistic.count_by_deal.deal_dispute}</span>
        </li>
      </Items>
    </Card>
  );
}
