import styled from 'styled-components';
import { Card, CardLink, Price, Title3 } from '@partnerka-front/components';
import { Currency } from '@partnerka-front/types';
import { useStatistic } from '@partnerka-front/utils';

const Items = styled.ul`
  margin-top: 24px;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.tableGrey};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    label {
      color: ${({ theme }) => theme.colors.secondary};
    }

    b {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

export function CardFinancialExpenditure() {
  const { statistic } = useStatistic();

  return (
    <Card>
      <div>
        <Title3>Расход финансов</Title3>
        <Items>
          <li>
            <label>За всё время</label>
            <span>
              <b>
                <Price
                  amount={statistic.spending_by.spend_by_time.by_all_time}
                  currency={Currency.USD}
                />
              </b>
            </span>
          </li>
          <li>
            <label>За месяц</label>
            <span>
              <Price
                amount={statistic.spending_by.spend_by_time.by_month}
                currency={Currency.USD}
              />
            </span>
          </li>
          <li>
            <label>За вчера</label>
            <span>
              <Price
                amount={statistic.spending_by.spend_by_time.by_yesterday}
                currency={Currency.USD}
              />
            </span>
          </li>
        </Items>
      </div>
      <CardLink text={'Анализ сделок'} to={'/deals'} />
    </Card>
  );
}
