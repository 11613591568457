import { useEffect } from 'react';
import styled from 'styled-components';
import { SelectNav } from '@partnerka-front/components';
import { SvgChevronPagination } from '@partnerka-front/icons';
import { useTranslation } from 'react-i18next';

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  width: 100%;
  margin-top: 8px;
  font-size: 12px;
`;

const PaginationShow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const PaginationButtons = styled.div`
  display: flex;
`;

const PaginationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:last-child svg {
    transform: rotate(180deg);
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

interface PaginationProps {
  current: number;
  setCurrent: (value: number) => void;
  itemsTotal: number;
  pageSize: number;
  setPageSize: (value: number) => void;
  pagesTotal: number;
}

export function Pagination({
  current,
  setCurrent,
  itemsTotal,
  pageSize,
  setPageSize,
  pagesTotal,
}: PaginationProps) {
  const { t } = useTranslation(['common']);

  const options = {
    10: '10',
    20: '20',
    50: '50',
    100: '100',
  };

  useEffect(() => {
    if (pageSize) localStorage.setItem('pageSize', `${pageSize}`);
  }, [pageSize]);

  return (
    <PaginationWrapper>
      <PaginationShow>
        {t('common.show_page', { ns: 'common' })}
        <SelectNav
          options={options}
          value={pageSize}
          onChange={(value) => setPageSize(Number(value))}
        />
      </PaginationShow>
      <div>
        {itemsTotal === 0 ? (
          <>
            0 {t('common.of', { ns: 'common' })} {itemsTotal}
          </>
        ) : (
          <>
            <span>{current === 1 ? 1 : (current - 1) * pageSize + 1}</span>-
            <span>{current * pageSize > itemsTotal ? itemsTotal : current * pageSize}</span>{' '}
            {t('common.of', { ns: 'common' })} {itemsTotal}
          </>
        )}
      </div>
      <PaginationButtons>
        <PaginationButton disabled={current === 1} onClick={() => setCurrent(current - 1)}>
          <SvgChevronPagination />
        </PaginationButton>
        <PaginationButton disabled={current >= pagesTotal} onClick={() => setCurrent(current + 1)}>
          <SvgChevronPagination />
        </PaginationButton>
      </PaginationButtons>
    </PaginationWrapper>
  );
}
