import { AxiosResponse } from 'axios';
import { PromoMaterialDto, PromoMaterialPayload } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/promo_material';

export async function createPromoMaterial(payload: PromoMaterialPayload) {
  return $api.post(`${basePath}/`, payload);
}

export async function patchPromoMaterial(
  id: string,
  payload: Partial<PromoMaterialPayload>
): Promise<AxiosResponse<PromoMaterialDto>> {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function deletePromoMaterial(id: string) {
  return $api.delete(`${basePath}/${id}/`);
}
