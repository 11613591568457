import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLang } from '@partnerka-front/api';
import { PartnerRoutes } from '@partnerka-front/components';
import { ScrollToTop, UserProvider } from '@partnerka-front/utils';
import { UserDto } from '@partnerka-front/types';

export function App() {
  const { t, i18n } = useTranslation(['errors']);
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState<UserDto | undefined>(undefined);

  useEffect(() => {
    changeLang(i18n.language, t('errors.bad_connection', { ns: 'errors' }));
  }, [i18n.language]);

  return (
    <UserProvider value={{ isAuth, setIsAuth, user, setUser }}>
      <PartnerRoutes />
      <ScrollToTop />
    </UserProvider>
  );
}
