import { Domain, LinkConstructorDto, UnknownFunction } from '@partnerka-front/types';
import { CatalogLinkConstructor, DeleteLinkModal, SimpleInput } from '@partnerka-front/components';
import styled from 'styled-components';
import { SvgDelete, SvgPlusRectangle, SvgSetting } from '@partnerka-front/icons';
import { useState } from 'react';

const CatalogLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkItem = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  & > *:first-child {
    width: 100%;
  }
`;

const LinkPanel = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
  font-size: 0;
`;

interface LinkPanelItemProps {
  styleHover?: 'default' | 'red';
}

const LinkPanelItem = styled.div<LinkPanelItemProps>`
  cursor: pointer;

  &:hover {
    svg path {
      stroke: ${({ theme, styleHover }) => {
        if (styleHover === 'red') return theme.colors.negative;
        return theme.colors.accent;
      }};
    }
  }
`;

interface CatalogLinkProps {
  link: LinkConstructorDto;
  domains: Domain[];
  createLink: UnknownFunction;
  lastLink: boolean;
  currentEdit: string | null;
  setCurrentEdit: (value: string | null) => void;
  landingId: string;
  updateCatalog: UnknownFunction;
}

export function CatalogLink({
  link,
  domains,
  createLink,
  lastLink,
  currentEdit,
  setCurrentEdit,
  landingId,
  updateCatalog,
}: CatalogLinkProps) {
  const [modal, setModal] = useState(false);

  const handlerSetting = (id: string) => {
    id === currentEdit ? setCurrentEdit(null) : setCurrentEdit(id);
  };

  return (
    <>
      <CatalogLinkWrapper>
        {currentEdit !== link.id ? (
          <LinkItem>
            <SimpleInput key={link.id} currentValue={link.parking_url} mode={'copy'} />
            <LinkPanel>
              <LinkPanelItem styleHover={'red'} onClick={() => setModal(true)}>
                <SvgDelete />
              </LinkPanelItem>
              <LinkPanelItem onClick={() => handlerSetting(link.id)}>
                <SvgSetting />
              </LinkPanelItem>
              {lastLink && (
                <LinkPanelItem onClick={() => createLink()}>
                  <SvgPlusRectangle />
                </LinkPanelItem>
              )}
            </LinkPanel>
          </LinkItem>
        ) : (
          <CatalogLinkConstructor
            id={link.id}
            link={link}
            domains={domains}
            landingId={landingId}
            onEdit={setCurrentEdit}
            updateCatalog={updateCatalog}
            setCurrentEdit={setCurrentEdit}
          />
        )}
      </CatalogLinkWrapper>
      {modal && (
        <DeleteLinkModal
          id={link.id}
          updateCatalog={updateCatalog}
          onClose={() => setModal(false)}
        />
      )}
    </>
  );
}
