import styled from 'styled-components';
import { SvgUserMock } from '@partnerka-front/icons';
import { SkeletonStyle } from './skeleton-style';

const PreviewPartnerWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 700;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
  }
`;

const MockLogoPartner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.support};
`;

interface PreviewPartnerProps {
  name: string | null;
  logo?: string | null;
}

export function PreviewPartner({ logo, name }: PreviewPartnerProps) {
  return (
    <PreviewPartnerWrapper>
      {logo ? (
        <img src={logo} alt={''} />
      ) : (
        <MockLogoPartner>
          <SvgUserMock />
        </MockLogoPartner>
      )}
      {name}
    </PreviewPartnerWrapper>
  );
}

const ImgSkeleton = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 10px;

  ${SkeletonStyle};

  border-radius: 5px;
`;

export function PreviewPartnerSkeleton() {
  return (
    <PreviewPartnerWrapper>
      <ImgSkeleton />
    </PreviewPartnerWrapper>
  );
}
