import { AxiosResponse } from 'axios';
import { LandingPayload } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/landing';

export async function createLanding(payload: LandingPayload): Promise<AxiosResponse<any>> {
  return $api.post(`${basePath}/`, payload);
}

export async function patchLanding(
  id: string,
  payload: LandingPayload
): Promise<AxiosResponse<any>> {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function deleteLanding(id: string) {
  return $api.delete(`${basePath}/${id}/`);
}
