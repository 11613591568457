export function SvgMonitor() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_219_3569)">
        <path
          d="M15.3073 0.00146484H0.659995C0.313661 0.00146484 0 0.315126 0 0.66146V12.0709C0 12.4172 0.277721 12.7309 0.659995 12.7309H7.33836V14.6782H5.07738C4.73105 14.6782 4.41739 14.9559 4.41739 15.3382C4.41739 15.6845 4.69511 15.9982 5.07738 15.9982H10.9226C11.2689 15.9982 11.5826 15.6845 11.5826 15.3382C11.5826 14.9918 11.3049 14.6782 10.9226 14.6782H8.66161V12.7309H15.34C15.6863 12.7309 16 12.4531 16 12.0709V0.66146C16.0032 0.315126 15.6896 0.00146484 15.3073 0.00146484ZM14.6473 11.4109H1.32326V1.32472H14.6473V11.4109Z"
          fill="#89C541"
        />
        <path
          d="M5.04468 8.59424C5.41715 9.05819 5.84517 8.83928 5.94972 8.7674L12.7686 4.27813C13.0822 4.06903 13.1508 3.65081 12.9417 3.34042C12.7326 3.02676 12.3144 2.95814 12.004 3.16725L5.77655 7.34286L4.17558 4.83684C4.00241 4.52318 3.58419 4.41863 3.27053 4.62773C2.95687 4.8009 2.85232 5.21912 3.06143 5.53278L5.04468 8.59424Z"
          fill="#89C541"
        />
      </g>
      <defs>
        <clipPath id="clip0_219_3569">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
