import { useState } from 'react';
import { PostbackEvents, PostbackFieldDto } from '@partnerka-front/types';
import { useEventBus } from '@partnerka-front/utils';
import { DeletePostbackModal } from './delete-postback-modal';
import { TestingRequestModal } from './testing-request-modal';

export function Modals() {
  const { subscribe } = useEventBus();

  const [deletePostbackModal, setDeletePostbackModal] = useState<{ id: string } | null>(null);
  const [testingPostbackModal, setTestingPostbackModal] = useState<{
    url: string;
    postback_field: PostbackFieldDto[];
  } | null>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  subscribe(PostbackEvents.openModalDeletePostback, (id: string) => setDeletePostbackModal({ id }));
  subscribe(
    PostbackEvents.openModalTestingPostback,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (payload: { url: string; postback_field: PostbackFieldDto[] }) =>
      setTestingPostbackModal({ url: payload.url, postback_field: payload.postback_field })
  );

  return (
    <>
      {deletePostbackModal && (
        <DeletePostbackModal
          id={deletePostbackModal.id}
          onClose={() => setDeletePostbackModal(null)}
        />
      )}
      {testingPostbackModal && (
        <TestingRequestModal
          url={testingPostbackModal.url}
          postback_field={testingPostbackModal.postback_field}
          onClose={() => setTestingPostbackModal(null)}
        />
      )}
    </>
  );
}
