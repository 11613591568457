import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Portal, LinkNext, Preview, PreviewYoutube } from '@partnerka-front/components';
import { InfoDto, UrlInfoDto } from '@partnerka-front/types';

const PopperCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);
  background-color: #fff;
  color: ${({ theme }) => theme.colors.headliner};
`;

const PopperTitle = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
`;

const PopperDescription = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`;

const PopperLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const POPPER_ROOT_ID = 'popper-root';

export function PopperRoot() {
  return <div id={POPPER_ROOT_ID} />;
}

interface PopperProps {
  children: ReactNode;
  videoUrl?: string;
  imageUrl?: string;
  title?: string;
  description?: string;
  links?: UrlInfoDto[];
}

export function Tooltip({ children, videoUrl, imageUrl, title, description, links }: PopperProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'auto',
    modifiers: [{ name: 'arrow', options: { element: arrowEl } }],
  });

  return (
    <span
      style={{ display: 'flex', justifyContent: 'center' }}
      onMouseLeave={() => setAnchorEl(null)}>
      <span onMouseEnter={(event) => setAnchorEl(event.currentTarget)}>{children}</span>
      {anchorEl && (
        <Portal targetId={POPPER_ROOT_ID}>
          <div
            ref={setPopperEl}
            style={{
              background: 'transparent',
              color: 'white',
              padding: '0',
              borderRadius: '10px',
              width: '300px',
              ...styles.popper,
            }}
            {...attributes.popper}>
            <PopperCard>
              {videoUrl && <PreviewYoutube url={videoUrl} />}
              {imageUrl && <Preview src={imageUrl} />}
              {title && <PopperTitle>{title}</PopperTitle>}
              {description && (
                <PopperDescription dangerouslySetInnerHTML={{ __html: description }} />
              )}
              {links && links.length > 0 && (
                <PopperLinks>
                  {links.map((link, i) => (
                    <LinkNext key={i} to={link.url}>
                      {link.label}
                    </LinkNext>
                  ))}
                </PopperLinks>
              )}
            </PopperCard>
            <div ref={setArrowEl} style={{ ...styles.arrow }} />
          </div>
        </Portal>
      )}
    </span>
  );
}

interface InfoTooltipProps {
  children: ReactNode;
  name: string;
  tooltips?: InfoDto[] | null;
}

export function InfoTooltip({ children, name, tooltips }: InfoTooltipProps) {
  const tooltip = tooltips?.find((item: InfoDto) => item.fields_name === name);

  if (!tooltip) return null;

  return (
    <Tooltip
      videoUrl={tooltip.video_url}
      imageUrl={tooltip.image_url}
      title={tooltip.label}
      description={tooltip.description}
      links={tooltip.urls}>
      {children}
    </Tooltip>
  );
}
