import styled from 'styled-components';
import { Card, Price, Title3 } from '@partnerka-front/components';
import { Currency } from '@partnerka-front/types';
import { useStatistic } from '@partnerka-front/utils';

const CardBalanceContent = styled.div`
  margin-bottom: 24px;
`;

const CardBalanceTotal = styled.div`
  margin-top: 24px;
  font-weight: 700;
  font-size: 36px;

  abbr {
    padding-right: 4px;
    color: ${({ theme }) => theme.colors.support};
  }

  span {
    font-size: 24px;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.tableGrey};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  label {
    color: ${({ theme }) => theme.colors.secondary};
  }

  b {
    font-weight: 500;
  }
`;

export function CardHold() {
  const { statistic } = useStatistic();

  return (
    <Card>
      <CardBalanceContent>
        <Title3>Средства в холде</Title3>
        <CardBalanceTotal>
          <Price amount={statistic.in_holding.sum_holding} currency={Currency.USD} />
        </CardBalanceTotal>
        <Item>
          <label>Спорные финансы</label>
          <span>
            <Price amount={statistic.in_dispute} currency={Currency.USD} />
          </span>
        </Item>
        <Item>
          <label>Сделок в холде</label>
          <span>
            <b>{statistic.in_holding.count_holding}</b>
          </span>
        </Item>
      </CardBalanceContent>
    </Card>
  );
}
