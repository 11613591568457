import styled from 'styled-components';
import { CatalogDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { Card } from '../card';

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  label {
    line-height: 1.43;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Title = styled.h1`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.5;
`;

interface CatalogDescriptionProps {
  catalog: CatalogDto;
}

export function CatalogDescription({ catalog }: CatalogDescriptionProps) {
  const { t } = useTranslation(['common', 'catalog']);

  return (
    <Card>
      <Labels>
        <label>{t('common.description', { ns: 'common' })}</label>
        <label>
          {t('catalog.id_catalog', { ns: 'catalog' })}: {catalog.catalog_id}
        </label>
      </Labels>
      <Title>{catalog.title}</Title>
      {catalog.description && <p>{catalog.description}</p>}
    </Card>
  );
}
