import { format, parseISO } from 'date-fns';

interface DateProps {
  date: string;
  dateFormat?: string;
}

export function DateFormat({ date, dateFormat }: DateProps) {
  const parsed = parseISO(date);

  return <>{format(parsed, dateFormat ? dateFormat : 'dd.MM.yyyy')}</>;
}

export function dateFormat(date: string) {
  const parsed = parseISO(date);

  return format(parsed, 'dd.MM.yyyy');
}
