import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '@partnerka-front/components';
import { useTranslation } from 'react-i18next';

export function UpdatedPostbackModal() {
  const { t } = useTranslation(['postback', 'common']);
  const navigate = useNavigate();

  return (
    <Modal
      title={`${t('postback.postback_changed', { ns: 'postback' })}`}
      onClose={() => navigate('/postback')}
      rightBtn={
        <Button variant={'primary-inactive'} onClick={() => navigate('/postback')}>
          {t('common.clearly', { ns: 'common' })}
        </Button>
      }
    />
  );
}
