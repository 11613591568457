import { useTranslation } from 'react-i18next';
import {
  Title,
  AccountStatistics,
  Telegram,
  Banner,
  GeneralStatisticsTable,
  Balance,
  Container,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const InfoBlocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  ${down('xl')} {
    grid-template-columns: 1fr 1fr;
  }

  ${down('sm')} {
    grid-template-columns: 1fr;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function MainPage() {
  const { t } = useTranslation(['main']);

  return (
    <>
      <Container>
        <Title>{t('main.title', { ns: 'main' })}</Title>

        <InfoBlocks>
          <Balance flexDirection={'column'} />
          <AccountStatistics />
          <InfoColumn>
            <Telegram />
            <Banner />
          </InfoColumn>
        </InfoBlocks>
      </Container>

      <GeneralStatisticsTable title={`${t('main.title_table', { ns: 'main' })}`} />
    </>
  );
}
