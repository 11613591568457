import styled from 'styled-components';
import { Card, CardLink, Price, Title3 } from '@partnerka-front/components';
import { Currency } from '@partnerka-front/types';
import { useStatistic } from '@partnerka-front/utils';

const Items = styled.ul`
  margin-top: 24px;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.tableGrey};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    label {
      color: ${({ theme }) => theme.colors.secondary};
    }

    b {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

export function CardAccountStatus() {
  const { statistic } = useStatistic();

  return (
    <Card>
      <div>
        <Title3>Состояние счета</Title3>
        <Items>
          <li>
            <label>Баланс</label>
            <span>
              <b>
                <Price amount={statistic.balance_usd} currency={Currency.USD} />
              </b>
            </span>
          </li>
          <li>
            <label>Прогноз расхода</label>
            <span>≈ {statistic.spending_by.consumption_forecast} дней</span>
          </li>
          <li>
            <label>Средства в холде</label>
            <span>
              <Price amount={statistic.in_holding.sum_holding} currency={Currency.USD} />
            </span>
          </li>
        </Items>
      </div>
      <CardLink text={'Управление балансом'} to={'/finance'} />
    </Card>
  );
}
