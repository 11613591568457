import { useEffect, useState } from 'react';
import {
  SkeletonTable,
  TableFilterButtons,
  TableItem,
  TableNoData,
  TableRow,
  TableSectionFilter,
  TableSorting,
  TableWrapper,
} from '../../UI/table';
import { SvgNoDeals } from '@partnerka-front/icons';
import { Pagination } from '../../UI/pagination';
import { useTranslation } from 'react-i18next';
import { usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { DealsTableItem } from './item';
import { DateRange } from '@partnerka-front/types';
import { DateRangeSelector } from '../../UI/date-range-selector';
import { Button } from '../../UI/button';
import { format } from 'date-fns';

export function DealsTable() {
  const { t } = useTranslation(['deals', 'errors', 'common']);
  const {
    firstLoading,
    pending,
    items: deals,
    fetchItems,
    itemsTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    pagesTotal,
  } = usePaginated(
    (page, pageSize) =>
      Api.getDeals(page, pageSize, {
        conversion_time_after: startDate,
        conversion_time_before: endDate,
        o: ordering,
      }),
    `${t('errors.try_again_later', { ns: 'errors' })}`
  );

  const [ordering, setOrdering] = useState('-conversion_time');
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const dateFormat = 'yyyy-MM-dd';

  const submitFilter = () => {
    page === 1 ? fetchItems() : setPage(1);
  };

  useEffect(() => {
    if (dateRange) {
      setStartDate(format(dateRange[0], dateFormat));
      setEndDate(format(dateRange[1], dateFormat));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [dateRange]);

  useEffect(() => {
    if (firstLoading) {
      page === 1 ? fetchItems() : setPage(1);
    }
  }, [ordering]);

  return (
    <>
      <TableSectionFilter>
        <DateRangeSelector value={dateRange} setValue={setDateRange} />
        <div />
        <TableFilterButtons>
          <div />
          {/*<Button variant={'basic'} maxWidth={'full'}>*/}
          {/*  {t('common.clear_all', { ns: 'common' })}*/}
          {/*</Button>*/}
          <Button variant={'accent'} maxWidth={'full'} onClick={submitFilter}>
            {t('common.apply', { ns: 'common' })}
          </Button>
        </TableFilterButtons>
      </TableSectionFilter>

      <TableWrapper width={'100%'}>
        <TableRow gridTemplateColumns={'100px 115px 90px 1fr 160px 110px 110px'}>
          <TableItem noRightBorder>{t('deals.id_deals', { ns: 'deals' })}</TableItem>
          <TableItem noRightBorder>{t('deals.status', { ns: 'deals' })}</TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() =>
                ordering === '-conversion_time'
                  ? setOrdering('conversion_time')
                  : setOrdering('-conversion_time')
              }>
              {t('deals.created', { ns: 'deals' })}
            </TableSorting>
          </TableItem>
          <TableItem noRightBorder minWidth={230}>
            {t('deals.offer', { ns: 'deals' })}
          </TableItem>
          <TableItem noRightBorder>{t('deals.promo', { ns: 'deals' })}</TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() => (ordering === '-cost' ? setOrdering('cost') : setOrdering('-cost'))}>
              {t('deals.sum', { ns: 'deals' })}
            </TableSorting>
          </TableItem>
          <TableItem>{t('deals.accruals', { ns: 'deals' })}</TableItem>
        </TableRow>

        {pending ? (
          <SkeletonTable
            gridTemplateColumns={'100px 115px 90px 1fr 160px 110px 110px'}
            width={'full'}
          />
        ) : (
          <>
            {deals.length > 0 ? (
              deals.map((deal) => <DealsTableItem key={deal.id} deal={deal} />)
            ) : (
              <TableNoData icon={<SvgNoDeals />} title={t('deals.no_data', { ns: 'deals' })} />
            )}
          </>
        )}
      </TableWrapper>
      {deals && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
