import { useEffect, useState } from 'react';
import { ControlledRadio, Inline, RadioOption, Row } from '@partnerka-front/components';
import { PostbackTemplatesDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';

interface PostbackTemplatesProps {
  templates: PostbackTemplatesDto[];
}

export function PostbackTemplates({ templates }: PostbackTemplatesProps) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<RadioOption[]>([
    { value: 'none', label: `${t('postback.template_none', { ns: 'postback' })}` },
  ]);

  useEffect(() => {
    if (templates) {
      const newOptions: RadioOption[] = [
        { value: 'none', label: `${t('postback.template_none', { ns: 'postback' })}` },
      ];
      templates.forEach((item) =>
        newOptions.push({ value: item.id, label: item.name, img: item.image })
      );
      setOptions(newOptions);
    }
  }, [templates]);

  return (
    <Row gap={24}>
      <Row gap={16}>
        <Inline gap={20}>
          <ControlledRadio name={'postback_template'} options={options} />
        </Inline>
      </Row>
    </Row>
  );
}
