import { PreviewPartner } from '../preview-partner';
import { Preview } from '../preview';
import { CatalogDto, UnknownFunction } from '@partnerka-front/types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Api } from '@partnerka-front/api';
import { useState } from 'react';
import { toast } from 'react-toast';
import { Card } from '../card';

interface CatalogBtnProps {
  isConfirmed?: boolean;
}

const CatalogBtn = styled.button<CatalogBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme, isConfirmed }) =>
    isConfirmed ? theme.colors.inactiveGrey : theme.colors.accent};
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: ${({ theme, isConfirmed }) => (isConfirmed ? theme.colors.secondary : theme.colors.white)};
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

const CatalogBtnPending = styled(CatalogBtn)`
  background-color: ${({ theme }) => theme.colors.notification};
  color: ${({ theme }) => theme.colors.headliner};
`;

interface CatalogPreviewProps {
  catalog: CatalogDto;
  updateCatalog?: UnknownFunction;
}

export function CatalogPreview({ catalog, updateCatalog }: CatalogPreviewProps) {
  const { t } = useTranslation(['catalog', 'errors']);
  const [pending, setPending] = useState(false);

  const catalogConfirmed = async () => {
    setPending(true);

    !catalog.is_confirmed &&
      updateCatalog &&
      (await Api.addUserCatalog(catalog.id)
        .then(() => updateCatalog())
        .catch(() => toast.error(`${t('errors.try_again_later', { ns: 'errors' })}`)));

    setPending(false);
  };

  return (
    <Card size={'mini'}>
      <PreviewPartner logo={catalog.company_logo} name={catalog.company_name} />
      <Preview src={catalog.preview_image} />
      {updateCatalog && (
        <>
          {pending ? (
            <CatalogBtnPending>
              {t('catalog.awaiting_moderation', { ns: 'catalog' })}
            </CatalogBtnPending>
          ) : (
            <CatalogBtn
              onClick={catalogConfirmed}
              isConfirmed={catalog.is_confirmed}
              disabled={catalog.is_confirmed}>
              {catalog.is_confirmed
                ? t('catalog.access_confirmed', { ns: 'catalog' })
                : t('catalog.join', { ns: 'catalog' })}
            </CatalogBtn>
          )}
        </>
      )}
    </Card>
  );
}
