import styled from 'styled-components';
import { Controller, useFormContext, UseFormRegisterReturn } from 'react-hook-form';
import React, { ReactNode, useMemo } from 'react';
import { uid } from 'uid';

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 20px;

  input {
    position: absolute;
    opacity: 0;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;

  min-height: 20px;
  padding-top: 2px;
  padding-left: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 100%;
    cursor: pointer;
    transition: 0.3s;
    box-sizing: border-box;
  }

  &:after {
    content: '';
    position: absolute;
    left: 4px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.accent};
    transform: scale(0);
    opacity: 0;
    transition: 0.3s;
    box-sizing: border-box;
  }

  input:checked + &::before {
    border-color: ${({ theme }) => theme.colors.accent};
  }

  input:checked + &::after {
    transform: scale(1);
    opacity: 1;
  }

  input:disabled + &::before {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }

  input:disabled + & {
    cursor: default;
  }
`;

interface RadioImgProps {
  isActive?: boolean;
  src?: string;
}

const RadioImg = styled.label<RadioImgProps>`
  position: relative;
  display: flex;
  align-items: center;

  width: 100px;
  height: 50px;
  margin-left: 24px;

  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.accent : '#d9d9d9')};
  border-radius: 5px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;

    display: block;
    width: 16px;
    height: 16px;
    margin-left: -24px;

    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 100%;
    cursor: pointer;
    transition: 0.3s;
    box-sizing: border-box;
  }

  &:after {
    content: '';
    position: absolute;
    left: 4px;

    display: block;
    width: 8px;
    height: 8px;
    margin-left: -24px;

    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.accent};
    transform: scale(0);
    opacity: 0;
    transition: 0.3s;
    box-sizing: border-box;
  }

  input:checked + &::before {
    border-color: ${({ theme }) => theme.colors.accent};
  }

  input:checked + &::after {
    transform: scale(1);
    opacity: 1;
  }

  input:disabled + &::before {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }

  input:disabled + & {
    cursor: default;
  }
`;

interface RadioProps {
  name: string;
  value: string;
  children?: ReactNode;
  register?: UseFormRegisterReturn<any>;
  disabled?: boolean;
}

export function Radio({ name, register, value, children, disabled }: RadioProps) {
  const id = useMemo(() => uid(), []);

  return (
    <RadioWrapper>
      <input type={'radio'} {...register} value={value} name={name} id={id} disabled={disabled} />
      <RadioLabel htmlFor={id}>{children}</RadioLabel>
    </RadioWrapper>
  );
}

export interface RadioOption {
  value: string;
  label: string;
  img?: string;
}

interface SimpleRadioProps {
  name: string;
  options: RadioOption[];
  value?: string;
  onChange?: (value: string, originalEvent: React.FormEvent<HTMLInputElement>) => void;
}

export function ListRadio({ name, value, onChange, options }: SimpleRadioProps) {
  const id = useMemo(() => uid(), []);

  const handleInput: React.FormEventHandler<HTMLInputElement> = (e) => {
    if (onChange) onChange(e.currentTarget.value, e);
  };

  return (
    <>
      {options.map((option) => (
        <RadioWrapper key={option.value}>
          <input
            id={`${id}-${option.value}`}
            aria-labelledby={`${id}-label-${option.value}`}
            name={name + id}
            type={'radio'}
            value={option.value}
            checked={option.value === value}
            onChange={handleInput}
          />
          {option.img ? (
            <RadioImg
              src={option.img}
              htmlFor={`${id}-${option.value}`}
              id={`${id}-label-${option.value}`}
            />
          ) : (
            <RadioLabel htmlFor={`${id}-${option.value}`} id={`${id}-label-${option.value}`}>
              {option.label}
            </RadioLabel>
          )}
        </RadioWrapper>
      ))}
    </>
  );
}

export function ControlledRadio({ name, options }: SimpleRadioProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <ListRadio name={name} options={options} value={value} onChange={onChange} />
      )}
    />
  );
}
