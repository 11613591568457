export function SvgInterfaceLoading() {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 7C7.63181 7 7.33334 7.29847 7.33334 7.66666V9.66666C7.33334 10.0348 7.63181 10.3333 8 10.3333C8.36819 10.3333 8.66666 10.0348 8.66666 9.66666V8.36625C12.0355 8.70075 14.6667 11.5432 14.6667 15C14.6667 18.6818 11.6818 21.6667 8 21.6667C4.31819 21.6667 1.33334 18.6818 1.33334 15C1.33334 13.2744 1.99072 11.6537 3.15141 10.4243C3.40419 10.1566 3.39206 9.73469 3.12434 9.48194C2.85662 9.22916 2.43469 9.24128 2.18194 9.509C0.789781 10.9834 0 12.9307 0 15C0 19.4182 3.58181 23 8 23C12.4182 23 16 19.4182 16 15C16 10.5818 12.4182 7 8 7Z"
        fill="white"
      />
      <path
        d="M7.52866 15.4712C7.78901 15.7316 8.21113 15.7316 8.47147 15.4712C8.73182 15.2109 8.73182 14.7888 8.47147 14.5284L5.80482 11.8618C5.54447 11.6014 5.12235 11.6014 4.86201 11.8618C4.60166 12.1221 4.60166 12.5442 4.86201 12.8046L7.52866 15.4712Z"
        fill="white"
      />
    </svg>
  );
}
