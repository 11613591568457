export interface PromoMaterialDto {
  id: string;
  catalog_id: string;
  modified_at: string;
  title: string;
  type_material: TypeMaterial;
  url: string;
}

export interface PromoMaterialPayload {
  title: string;
  catalog: string;
  type_material: TypeMaterial;
  url: string;
}

export enum TypeMaterial {
  URL = 'URL',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}
