import styled from 'styled-components';

interface TitleProps {
  marginBottom?: number;
}

export const Title = styled.h1<TitleProps>`
  margin-bottom: ${({ marginBottom }) => {
    if (marginBottom === 0) return '0';
    if (marginBottom) return `${marginBottom}px`;
    return '24px';
  }};
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
`;

export const TitleSection = styled.div<TitleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '20px')};
`;

export const TitleH2 = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
`;

export const Title3 = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.43;
`;

// TODO Заменить тайтлы в в каталоге, заменить на один этот
export const TitleH4 = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
