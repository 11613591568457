export function SvgDelete() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.91884 5.21353C6.38811 4.55654 7.1458 4.16663 7.95317 4.16663H15C16.3807 4.16663 17.5 5.28592 17.5 6.66663V13.3333C17.5 14.714 16.3807 15.8333 15 15.8333H7.95317C7.1458 15.8333 6.38811 15.4434 5.91884 14.7864L3.19191 10.9687C2.77799 10.3892 2.77799 9.61071 3.19191 9.03121L5.91884 5.21353Z"
        stroke="#35434E"
      />
      <path
        d="M9.16669 7.5L14.1667 12.5M14.1667 7.5L9.16669 12.5"
        stroke="#35434E"
        strokeLinecap="round"
      />
    </svg>
  );
}
