import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { LinkTheme } from '../UI/link-theme';
import { useTranslation } from 'react-i18next';

const FooterAuthWrapper = styled.div`
  margin-top: 30px;

  ${up('lg')} {
    display: none;
  }

  & > * {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FooterAuthContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FooterAuthLang = styled.div`
  color: ${({ theme }) => theme.colors.headliner};
  font-size: 12px;
  line-height: 24px;

  span {
    cursor: pointer;

    &.active {
      color: ${({ theme }) => theme.colors.accent};
      cursor: default;
    }
  }
`;

const FooterAuthLink = styled.a`
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #121312;
  opacity: 0.3;
  text-decoration: none;

  &:nth-of-type(2n) {
    text-align: right;
  }
`;

export function FooterAuth() {
  const { t, i18n } = useTranslation(['common']);

  return (
    <FooterAuthWrapper>
      <FooterAuthContent>
        <LinkTheme href={'https://t.me/aff_good_boy'}>
          {t('common.support', { ns: 'common' })}
        </LinkTheme>
        <FooterAuthLang>
          <span
            className={i18n.language === 'ru' ? 'active' : ''}
            onClick={() => i18n.changeLanguage('ru')}>
            RU
          </span>
          {' | '}
          <span
            className={i18n.language === 'en' ? 'active' : ''}
            onClick={() => i18n.changeLanguage('en')}>
            EN
          </span>
        </FooterAuthLang>
      </FooterAuthContent>
      <FooterAuthContent>
        <FooterAuthLink
        // href={'https://academy.cyberyozh.com/privacy_policy'}
        // target={'_blank'}
        // rel={'noreferrer'}
        >
          {t('common.privacy_policy', { ns: 'common' })}
        </FooterAuthLink>
        <FooterAuthLink
        // href={'https://academy.cyberyozh.com/affiliate/ru/contact#rec501383449'}
        // target={'_blank'}
        // rel={'noreferrer'}
        >
          {t('common.legal_information', { ns: 'common' })}
        </FooterAuthLink>
      </FooterAuthContent>
    </FooterAuthWrapper>
  );
}
