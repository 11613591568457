export function SvgTrash() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66666 4.1665H16.3333" stroke="#35434E" strokeLinecap="round" />
      <path
        d="M7.16666 4.16667H7.25925C8.02631 4.16667 8.64814 3.54484 8.64814 2.77777C8.64814 2.62437 8.77251 2.5 8.92591 2.5H12.0741C12.2275 2.5 12.3518 2.62437 12.3518 2.77777C12.3518 3.54484 12.9737 4.16667 13.7407 4.16667H13.8333"
        stroke="#35434E"
      />
      <path
        d="M15.5 7.5L14.8577 15.2076C14.7498 16.5033 13.6666 17.5 12.3663 17.5H8.63367C7.33344 17.5 6.25028 16.5033 6.1423 15.2076L5.5 7.5"
        stroke="#35434E"
        strokeLinecap="round"
      />
    </svg>
  );
}
