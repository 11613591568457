import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Api } from '@partnerka-front/api';
import {
  FormAuth,
  Paragraph,
  ButtonSubmit,
  FormAfter,
  TitleH2,
  PageLoading,
  ButtonAction,
  PageAuth,
  FooterAuth,
  ControlledInput,
  Message,
} from '@partnerka-front/components';
import { ResponseError } from '@partnerka-front/types';
import { SvgEmailSuccessfully, SvgUpdateArrows } from '@partnerka-front/icons';
import { regExpEmail, regExpEmailCharacter } from '@partnerka-front/utils';
import { useTranslation } from 'react-i18next';

enum RecoverySteps {
  confirm = 'confirm',
  success = 'success',
}

interface PasswordResetPageProps {
  isBusiness?: boolean;
}

export function PasswordResetPage({ isBusiness }: PasswordResetPageProps) {
  const { t } = useTranslation(['auth', 'validation', 'errors', 'common']);

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(regExpEmailCharacter, `${t('validation.invalid_character', { ns: 'validation' })}`)
      .matches(regExpEmail, `${t('validation.incorrect_email', { ns: 'validation' })}`),
  });
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const [step, setStep] = useState(RecoverySteps.confirm);
  const [pending, setPending] = useState(false);
  const [successResend, setSuccessResend] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const submit = handleSubmit(async (data) => {
    setPending(true);
    await Api.resetPassword(data)
      .then(() => {
        step === RecoverySteps.confirm ? setStep(RecoverySteps.success) : setSuccessResend(true);
      })
      .catch(setError)
      .finally(() => setPending(false));
  });

  useEffect(() => {
    setError(null);
  }, [step]);

  if (step === RecoverySteps.success) {
    return (
      <PageAuth>
        <FormAuth isBusiness={isBusiness}>
          {pending && <PageLoading />}
          <SvgEmailSuccessfully />
          <TitleH2>{t('auth.link_sent', { ns: 'auth' })}</TitleH2>
          <Paragraph>{t('auth.successfully_forgot_password', { ns: 'auth' })}</Paragraph>
          <ButtonAction onClick={() => submit()}>
            {t('auth.send_again', { ns: 'auth' })}
          </ButtonAction>
          {successResend && (
            <Message variant={'success'} onClose={() => setSuccessResend(false)}>
              {t('auth.send_success_forgot_password', { ns: 'auth' })}
            </Message>
          )}
          {error && (
            <Message onClose={() => setError(null)}>
              {t('errors.try_again_later', { ns: 'errors' })}
            </Message>
          )}
          <ButtonAction
            onClick={() => {
              setStep(RecoverySteps.confirm);
              setError(null);
              setSuccessResend(false);
            }}>
            {t('auth.change_email', { ns: 'auth' })}
          </ButtonAction>
        </FormAuth>
        {!isBusiness && <FooterAuth />}
      </PageAuth>
    );
  }

  return (
    <PageAuth>
      <FormProvider {...formMethods}>
        {pending && <PageLoading />}
        <FormAuth onSubmit={submit} isBusiness={isBusiness}>
          <SvgUpdateArrows />
          <TitleH2>{t('auth.forgot_password_title', { ns: 'auth' })}</TitleH2>
          <Paragraph>{t('auth.forgot_password_description', { ns: 'auth' })}</Paragraph>
          <ControlledInput name={'email'} label={'Email'} />
          {error && (
            <Message onClose={() => setError(null)}>
              {t('errors.try_again_later', { ns: 'errors' })}
            </Message>
          )}
          <ButtonSubmit disabled={!isValid}>{t('common.send', { ns: 'common' })}</ButtonSubmit>
          <FormAfter>
            {t('auth.remembered_password', { ns: 'auth' })}{' '}
            <Link to="/login">{t('auth.login', { ns: 'auth' })}</Link>
          </FormAfter>
        </FormAuth>
      </FormProvider>
      {!isBusiness && <FooterAuth />}
    </PageAuth>
  );
}
