import { price } from '@partnerka-front/utils';
import { Currency } from '@partnerka-front/types';

interface PriceProps {
  amount: number;
  currency: Currency;
}

export function Price({ amount, currency }: PriceProps) {
  return <div dangerouslySetInnerHTML={{ __html: `${price(amount, currency)}` }} />;
}
