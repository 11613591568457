import { AxiosResponse } from 'axios';
import { GetPostbackLogsOptions, Paginated, PostbackLogDto } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/postback_log';

export async function getPostbackLogs(
  page = 1,
  pageSize = 10,
  options: GetPostbackLogsOptions = {}
): Promise<AxiosResponse<Paginated<PostbackLogDto>>> {
  return $api.get(`${basePath}/`, {
    params: {
      page,
      page_size: pageSize,
      created_at_after: options.created_at_after,
      created_at_before: options.created_at_before,
    },
  });
}
