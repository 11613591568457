import { AccountStatisticsTable, Balance, Container, Title } from '@partnerka-front/components';
import { useTranslation } from 'react-i18next';

export function StatisticsPage() {
  const { t } = useTranslation(['deals']);

  return (
    <>
      <Container>
        <Title>{t('deals.account_statistics', { ns: 'deals' })}</Title>
        <Balance />
      </Container>
      <AccountStatisticsTable />
    </>
  );
}
