import styled from 'styled-components';

interface BurgerWrapperProps {
  isOpen: boolean;
}

const BurgerWrapper = styled.div<BurgerWrapperProps>`
  position: fixed;
  top: 15px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.negative : theme.colors.headliner};
  cursor: pointer;
  transition: 0.3s;

  & > div:first-child {
    position: ${({ isOpen }) => (isOpen ? 'absolute' : 'static')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
  }

  & > div:nth-child(2) {
    display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
  }

  & > div:last-child {
    position: ${({ isOpen }) => (isOpen ? 'absolute' : 'static')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`;

const Line = styled.div`
  width: 16px;
  height: 1.5px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  transition: 0.3s;
`;

interface BurgerProps {
  open: boolean;
  setOpen: any;
}

export function Burger({ open, setOpen }: BurgerProps) {
  return (
    <BurgerWrapper onClick={() => setOpen(!open)} isOpen={open}>
      <Line />
      <Line />
      <Line />
    </BurgerWrapper>
  );
}
