export function SvgCheckRectangleWhite() {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 11.1111C1 9.39289 2.39289 8 4.11111 8H11.8889C13.6071 8 15 9.39289 15 11.1111V18.8889C15 20.6071 13.6071 22 11.8889 22H4.11111C2.39289 22 1 20.6071 1 18.8889V11.1111Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M11 12L7.25 17L5 14.7273"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
