import { Title } from './styles';
import { Card } from '../card';
import { Domain, Landing, UnknownFunction } from '@partnerka-front/types';
import styled from 'styled-components';
import { SvgCopyNew, SvgExternalLink, SvgPlusRectangle } from '@partnerka-front/icons';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toast';
import { down } from 'styled-breakpoints';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateFormat, Preview, CatalogLinks, Button } from '@partnerka-front/components';

const Label = styled(Title)`
  margin-bottom: 10px;
`;

const LandingWrapper = styled.div`
  margin-bottom: 20px;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};

  &:last-child {
    margin-bottom: 0;
    padding: 0;
    border-bottom: none;
  }
`;

const Info = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;

  ${down('sm')} {
    flex-direction: column;
    gap: 10px;
  }
`;

const InfoPreview = styled.div`
  width: 160px;
  min-width: 160px;
`;

const InfoDescription = styled.div`
  width: 100%;
`;

const InfoTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.88;
`;

const InfoDescriptionAll = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  ${down('xl')} {
    flex-direction: column;
  }
`;

const InfoDescriptionLeft = styled.div`
  width: 180px;
  min-width: 180px;
`;

const LinkLanding = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors.headliner};
  text-decoration: none;
`;

const InfoId = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.secondary};

  span {
    display: flex;
    align-items: center;
    gap: 3px;

    &:hover {
      cursor: pointer;
      stroke: ${({ theme }) => theme.colors.accent};
      transition: 0.3s;

      svg path {
        stroke: ${({ theme }) => theme.colors.accent};
      }
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        stroke: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

const InfoDescriptionRight = styled.div`
  width: 100%;
`;

const InfoUpdate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.secondary};

  ${down('xl')} {
    justify-content: left;
  }
`;

const InfoAdaptability = styled.div`
  display: flex;
  gap: 5px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const BtnRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

interface CatalogPostbackProps {
  idCatalog: string;
  landings: Landing[];
  domains: Domain[];
  updateCatalog?: UnknownFunction;
}

export function CatalogLandings({
  landings,
  idCatalog,
  domains,
  updateCatalog,
}: CatalogPostbackProps) {
  const { t } = useTranslation(['landing', 'postback', 'common']);
  const navigate = useNavigate();

  const handleCopy = (value: string) => {
    copy(value);
    toast(`${t('common.copied', { ns: 'common' })}`, {
      backgroundColor: '#868E95',
      color: '#ffffff',
    });
  };

  return (
    <Card childrenBottom={10}>
      <Label>{t('landing.title', { ns: 'landing' })}</Label>
      <div>
        {landings.map((landing) => (
          <LandingWrapper key={landing.id}>
            <Info>
              <InfoPreview>
                <Preview src={landing.image_url} />
              </InfoPreview>
              <InfoDescription>
                <InfoTitle>{landing.title}</InfoTitle>
                <InfoDescriptionAll>
                  <InfoDescriptionLeft>
                    <LinkLanding href={landing.url} target={'_blank'}>
                      <SvgExternalLink />
                      {t('landing.visit_page', { ns: 'landing' })}
                    </LinkLanding>
                    <InfoId>
                      <label>Landing ID: </label>
                      <span onClick={() => handleCopy(landing.id)}>
                        {landing.id} <SvgCopyNew />
                      </span>
                    </InfoId>
                  </InfoDescriptionLeft>
                  <InfoDescriptionRight>
                    <InfoUpdate>
                      <label>{t('landing.update_date', { ns: 'landing' })}:</label>
                      <span>
                        <DateFormat date={landing.modified_at} />
                      </span>
                    </InfoUpdate>
                    <InfoAdaptability>
                      <label>{t('landing.adaptability', { ns: 'landing' })}:</label>
                      <span>
                        {landing.adaptability === 'All'
                          ? `${t('landing.all_devices', { ns: 'landing' })}`
                          : landing.adaptability}
                      </span>
                    </InfoAdaptability>
                  </InfoDescriptionRight>
                </InfoDescriptionAll>
              </InfoDescription>
            </Info>
            {updateCatalog && (
              <CatalogLinks
                landingId={landing.id}
                links={landing.link_constructor}
                domains={domains}
                updateCatalog={updateCatalog}
              />
            )}
          </LandingWrapper>
        ))}
      </div>
      <BtnRight>
        {updateCatalog ? (
          <Button variant={'basic'} onClick={() => navigate(`/postback/new/${idCatalog}`)}>
            <SvgPlusRectangle />
            {t('postback.create_postback', { ns: 'postback' })}
          </Button>
        ) : (
          <Button variant={'basic'}>
            <SvgPlusRectangle />
            {t('postback.create_postback', { ns: 'postback' })}
          </Button>
        )}
      </BtnRight>
    </Card>
  );
}
