export function SvgExternalLink() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33333 2H4.66667C3.19391 2 2 3.19391 2 4.66667V11.3333C2 12.8061 3.19391 14 4.66667 14H11.3333C12.8061 14 14 12.8061 14 11.3333V8.66667"
        stroke="#35434E"
        strokeLinecap="round"
      />
      <path d="M8 8L14 2" stroke="#35434E" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 6V2H10" stroke="#35434E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
