export function SvgWw() {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="16" rx="2" fill="#198FE6" />
      <path
        d="M16.5208 4.97055L16.3594 5.02305L15.5 5.09888L15.253 5.48777L15.0761 5.43138L14.378 4.8111L14.2769 4.48833L14.1389 4.14222L13.7053 3.75333L13.1939 3.65416L13.1822 3.88749L13.6839 4.37749L13.9289 4.66527L13.6528 4.8111L13.4292 4.74499L13.0928 4.60305V4.33277L12.665 4.14999L12.5192 4.78971L12.0739 4.88888L12.1186 5.24666L12.7019 5.35749L12.803 4.78777L13.2814 4.85972L13.503 4.98999H13.8608L14.1 5.47222L14.7494 6.12944L14.7008 6.3861L14.1778 6.31999L13.2755 6.77499L12.6261 7.55277L12.5425 7.89888H12.3072L11.8736 7.70444L11.4517 7.89888L11.5567 8.34416L11.7394 8.13222H12.0642L12.0408 8.5211L12.3092 8.59888L12.5833 8.90805L13.0111 8.77777L13.5108 8.85555L14.0942 9.0111L14.3819 9.0461L14.8719 9.60222L15.8189 10.1583L15.2064 11.325L14.5608 11.6244L14.3158 12.2933L13.3805 12.9175L13.2814 13.2772C14.1311 13.0696 14.9183 12.6604 15.5763 12.084C16.2343 11.5077 16.7438 10.7814 17.0616 9.96645C17.3794 9.15152 17.4962 8.272 17.4021 7.40237C17.308 6.53274 17.0057 5.69859 16.5208 4.97055Z"
        fill="white"
      />
      <path
        d="M12.6261 10.8447L12.2372 10.1097L12.5931 9.36111L12.2294 9.25223L11.8211 8.84195L10.915 8.6475L10.6156 8V8.37334H10.4833L9.70555 7.31556V6.44445L9.13389 5.515L8.22583 5.67639H7.61139L7.30222 5.48195L7.69111 5.17084L7.30222 5.26028C6.82074 6.08712 6.56566 7.02625 6.56268 7.98306C6.5597 8.93987 6.80893 9.88056 7.28525 10.7104C7.76158 11.5402 8.44818 12.2298 9.27589 12.7098C10.1036 13.1898 11.0432 13.4432 12 13.4444C12.2283 13.4421 12.4562 13.4258 12.6825 13.3958L12.6261 12.7367C12.6261 12.7367 12.8769 11.7644 12.8769 11.7256C12.8769 11.6867 12.6261 10.8447 12.6261 10.8447Z"
        fill="white"
      />
      <path
        d="M8.57972 4.30556L9.55194 4.16945L9.99722 3.9264L10.4989 4.07028L11.3 4.02556L11.5761 3.5939L11.9747 3.66001L12.9469 3.56862L13.2153 3.27306L13.6042 3.02223L14.1369 3.10195L14.3314 3.07278C13.2383 2.55465 12.0035 2.41654 10.823 2.6804C9.64249 2.94427 8.58398 3.59497 7.81555 4.52917L8.57972 4.30556ZM12.2294 3.09612L12.7778 2.79084L13.1356 2.99695L12.6183 3.38584L12.1244 3.4364L11.9028 3.29251L12.2294 3.09612ZM10.5825 3.1389L10.8333 3.2439L11.1503 3.1389L11.3253 3.44223L10.5825 3.63667L10.2267 3.43056C10.2267 3.43056 10.5747 3.20695 10.5825 3.1389Z"
        fill="white"
      />
    </svg>
  );
}
