import {
  Card,
  CardDropdownInCard,
  PostbackFields,
  PostbackTemplates,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { PostbackFieldDto, PostbackTemplatesDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';

const PostbackRequestParametersLabel = styled.label`
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 16px;
`;

interface PostbackRequestParametersProps {
  templates: PostbackTemplatesDto[] | null;
  postback_field?: PostbackFieldDto[];
  watch: any;
  setFields: any;
  setUrlParameters: any;
}

export function PostbackRequestParameters({
  templates,
  postback_field,
  watch,
  setFields,
  setUrlParameters,
}: PostbackRequestParametersProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <PostbackRequestParametersLabel>
        {t('postback.formation_parameters_title', { ns: 'postback' })}
      </PostbackRequestParametersLabel>

      {templates && (
        <CardDropdownInCard title={t('postback.template_title', { ns: 'postback' })}>
          <PostbackTemplates templates={templates} />
        </CardDropdownInCard>
      )}

      <PostbackFields
        watch={watch}
        templates={templates}
        postback_field={postback_field}
        setFields={setFields}
        setUrlParameters={setUrlParameters}
      />
    </Card>
  );
}
