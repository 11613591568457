export function SvgLogo() {
  return (
    <svg
      width="111"
      height="30"
      viewBox="0 0 111 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.625 20.944H28.0359V13.8149C28.0359 12.9767 28.2667 12.3384 28.7281 11.9C29.1664 11.454 29.7317 11.231 30.4238 11.231C31.116 11.231 31.6851 11.454 32.1311 11.9C32.5695 12.3384 32.7886 12.9767 32.7886 13.8149V20.944H35.1996V13.8149C35.1996 12.9767 35.4265 12.3384 35.8802 11.9C36.3262 11.454 36.8953 11.231 37.5875 11.231C38.2796 11.231 38.8448 11.454 39.2832 11.9C39.7216 12.3384 39.9407 12.9767 39.9407 13.8149V20.944H42.3517V12.9844C42.3209 11.6155 41.8979 10.5773 41.0828 9.86975C40.2599 9.16992 39.3524 8.82001 38.3604 8.82001C36.7838 8.80463 35.5264 9.32758 34.5882 10.3889C34.1729 9.92743 33.7153 9.54675 33.2155 9.24683C32.7079 8.97766 32.0388 8.83539 31.2083 8.82001C29.9163 8.82001 28.8742 9.29297 28.0821 10.2389H28.0359V8.96997H25.625V20.944Z"
        fill="#2356F6"
      />
      <path
        d="M48.2003 20.471L47.5427 22.4782C47.4351 22.8243 47.2466 23.0858 46.9775 23.2626C46.7083 23.4318 46.4161 23.5164 46.1008 23.5164H45.3971V25.7889H46.1931C46.939 25.7889 47.6081 25.6236 48.2003 25.2929C48.7847 24.9468 49.2423 24.3239 49.573 23.4241L54.7294 8.96997H52.1685L49.3423 17.3564H49.2961L46.4584 8.96997H43.909L48.2003 20.471Z"
        fill="#2356F6"
      />
      <path
        d="M56.4828 17.2872L54.7986 18.9368C56.3598 20.3672 58.1171 21.0824 60.0704 21.0824C61.5085 21.067 62.689 20.7363 63.6119 20.0903C64.5424 19.4597 65.0154 18.5638 65.0308 17.4025C65.0308 16.4105 64.7731 15.5914 64.2579 14.9454C63.7042 14.2841 62.8044 13.9072 61.5585 13.8149L59.5744 13.665C58.9899 13.6035 58.5823 13.4612 58.3516 13.2382C58.0978 13.0382 57.9786 12.8036 57.994 12.5345C57.994 12.1115 58.1517 11.7654 58.467 11.4963C58.7669 11.2271 59.2937 11.0925 60.0474 11.0925C60.7087 11.0925 61.2932 11.1887 61.8008 11.3809C62.316 11.5963 62.8044 11.8693 63.2658 12.2L64.7539 10.4581C64.1694 9.9505 63.5081 9.54675 62.7698 9.24683C62.3853 9.1084 61.9738 9.00073 61.5355 8.92383C61.0971 8.85461 60.6011 8.82001 60.0474 8.82001C58.7708 8.82001 57.7172 9.12762 56.8866 9.74286C56.033 10.3581 55.5984 11.2809 55.5831 12.5114C55.5984 13.5342 55.9291 14.3302 56.5751 14.8993C57.2057 15.4838 58.0248 15.8221 59.0322 15.9144L61.1548 16.099C61.6008 16.1144 61.9584 16.2105 62.2276 16.3874C62.4891 16.5566 62.6198 16.8565 62.6198 17.2872C62.6198 17.7717 62.4006 18.1485 61.9623 18.4177C61.5239 18.6868 60.9587 18.8214 60.2665 18.8214C58.7208 18.8214 57.4595 18.31 56.4828 17.2872Z"
        fill="#2356F6"
      />
      <path
        d="M67.4763 20.944H69.8873V13.8149C69.8873 12.9767 70.1141 12.3384 70.5679 11.9C71.0139 11.454 71.583 11.231 72.2751 11.231C72.9673 11.231 73.5325 11.454 73.9709 11.9C74.4169 12.3384 74.64 12.9767 74.64 13.8149V20.944H77.0509V12.9844C77.0125 11.6155 76.5856 10.5773 75.7704 9.86975C74.9476 9.16992 74.0401 8.82001 73.048 8.82001C71.7637 8.82001 70.7255 9.29297 69.9334 10.2389H69.8873V4.125H67.4763V20.944Z"
        fill="#2356F6"
      />
      <path
        d="M86.4525 20.944H88.8634V12.7652C88.848 11.304 88.3904 10.2735 87.4907 9.67365C86.5909 9.08917 85.3873 8.80463 83.88 8.82001C82.0958 8.78925 80.7462 9.37372 79.831 10.5734L81.7228 12.0154C81.9305 11.6693 82.215 11.4232 82.5765 11.2771C82.9225 11.1541 83.3955 11.0925 83.9954 11.0925C85.6488 11.0618 86.4678 11.5732 86.4525 12.6268V13.8842H83.1187C81.842 13.8995 80.873 14.2456 80.2117 14.9224C79.5503 15.5837 79.2196 16.4182 79.2196 17.4256C79.2196 18.4792 79.6003 19.3444 80.3616 20.0211C81.0845 20.7133 82.1151 21.067 83.4532 21.0824C84.2684 21.0824 84.9067 20.9824 85.3681 20.7825C85.8372 20.5902 86.1833 20.2903 86.4063 19.8827H86.4525V20.944ZM86.4525 16.7335C86.4525 17.6332 86.264 18.21 85.8872 18.4638C85.4719 18.7176 84.7606 18.8368 83.7531 18.8214C82.9533 18.8214 82.3804 18.6638 82.0343 18.3484C81.6729 18.0639 81.4921 17.7178 81.4921 17.3102C81.4767 16.3797 82.1689 15.899 83.5685 15.8683H86.4525V16.7335Z"
        fill="#2356F6"
      />
      <path
        d="M91.978 20.944H94.389V13.7342C94.4044 12.8575 94.6581 12.223 95.1503 11.8308C95.5887 11.4309 96.1155 11.231 96.7307 11.231C97.2767 11.231 97.8035 11.4309 98.3111 11.8308L100.065 9.74286C99.3493 9.14301 98.511 8.83539 97.5497 8.82001C96.2654 8.82001 95.2272 9.29297 94.4351 10.2389H94.389V8.96997H91.978V20.944Z"
        fill="#2356F6"
      />
      <path
        d="M110.031 15.8683V13.8842C110.016 12.3076 109.528 11.0695 108.566 10.1697C107.62 9.28528 106.463 8.83539 105.094 8.82001C104.525 8.82001 103.948 8.92383 103.364 9.13147C102.772 9.33911 102.237 9.67749 101.76 10.1466C101.291 10.608 100.907 11.2386 100.607 12.0385C100.307 12.8229 100.157 13.8073 100.157 14.9916C100.157 16.222 100.33 17.2218 100.676 17.9908C100.991 18.7906 101.403 19.4136 101.91 19.8596C102.172 20.075 102.452 20.2557 102.752 20.4018C103.037 20.571 103.329 20.7056 103.629 20.8055C104.229 20.9901 104.844 21.0824 105.475 21.0824C107.128 21.0978 108.566 20.4518 109.789 19.1444L108.047 17.6563C107.278 18.433 106.405 18.8214 105.429 18.8214C104.59 18.8214 103.914 18.5676 103.398 18.0601C102.845 17.5602 102.568 16.8296 102.568 15.8683H110.031ZM102.568 13.8842C102.614 12.9536 102.864 12.2499 103.318 11.7731C103.779 11.3194 104.371 11.0925 105.094 11.0925C105.817 11.0925 106.409 11.3194 106.871 11.7731C107.34 12.2499 107.59 12.9536 107.62 13.8842H102.568Z"
        fill="#2356F6"
      />
      <path
        d="M6.25 21.25C6.25 22.9759 4.85089 24.375 3.125 24.375C1.39911 24.375 0 22.9759 0 21.25C0 19.5241 1.39911 18.125 3.125 18.125C4.85089 18.125 6.25 19.5241 6.25 21.25Z"
        fill="#2356F6"
      />
      <path
        d="M18.75 21.25C18.75 22.9759 17.3509 24.375 15.625 24.375C13.8991 24.375 12.5 22.9759 12.5 21.25C12.5 19.5241 13.8991 18.125 15.625 18.125C17.3509 18.125 18.75 19.5241 18.75 21.25Z"
        fill="#2356F6"
      />
      <path
        d="M6.24999 8.75812C6.25 8.75541 6.25 8.75271 6.25 8.75C6.25 7.02411 4.85089 5.625 3.125 5.625C1.39911 5.625 0 7.02411 0 8.75C0 10.4746 1.39702 11.8729 3.12113 11.875C3.12241 11.875 3.12369 11.875 3.12498 11.875C4.99997 11.875 6.24999 13.125 6.24998 15C6.24998 15.0025 6.24999 15.005 6.25001 15.0075C6.25407 16.73 7.65162 18.125 9.375 18.125C11.1009 18.125 12.5 16.7259 12.5 15C12.5 13.2741 11.1009 11.875 9.375 11.875C9.37361 11.875 9.37222 11.875 9.37084 11.875C7.50084 11.8732 6.25359 10.6272 6.24999 8.75812Z"
        fill="#2356F6"
      />
      <path
        d="M9.38311 11.875C9.38041 11.875 9.3777 11.875 9.375 11.875C7.64911 11.875 6.24998 13.2741 6.24998 15C6.24998 16.7259 7.64911 18.125 9.375 18.125C11.0996 18.125 12.4979 16.728 12.5 15.0039C12.5 15.0026 12.5 15.0013 12.5 15C12.5 13.125 13.75 11.875 15.625 11.875C15.6275 11.875 15.63 11.875 15.6325 11.875C17.3549 11.8709 18.75 10.4734 18.75 8.75C18.75 7.02411 17.3509 5.625 15.625 5.625C13.8991 5.625 12.5 7.02411 12.5 8.75C12.5 8.75136 12.5 8.75272 12.5 8.75408C12.4982 10.6241 11.2523 11.8714 9.38311 11.875Z"
        fill="#2356F6"
      />
    </svg>
  );
}
