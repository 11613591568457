import { ReactNode } from 'react';
import styled from 'styled-components';
import { UnknownFunction } from '@partnerka-front/types';
import { SvgAlertCircle, SvgXCircle } from '@partnerka-front/icons';

const MessageWrapper = styled.div<MessageProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: 1px solid
    ${({ theme, variant }) => {
      if (variant === 'success') return theme.colors.positive;
      return theme.colors.negative;
    }};
  border-radius: 5px;
  background-color: ${({ theme, variant }) => {
    if (variant === 'success') return theme.colors.positive;
    return 'rgba(245, 214, 216, 0.5)';
  }};
`;

const MessageIcon = styled.div<MessageProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 24px;
  height: 24px;
  margin-right: 16px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: ${({ theme, variant }) => {
      if (variant === 'success') return theme.colors.white;
      return theme.colors.negative;
    }};
  }
`;

const MessageClose = styled.div<MessageProps>`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 18px;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: ${({ theme, variant }) => {
      if (variant === 'success') return theme.colors.white;
      return theme.colors.negative;
    }};
  }
`;

const MessageDescription = styled.div<MessageProps>`
  color: ${({ theme, variant }) => {
    if (variant === 'success') return theme.colors.white;
    return theme.colors.moreBlack;
  }};

  a {
    color: ${({ theme }) => theme.colors.moreBlack};
  }
`;

interface MessageProps {
  children?: ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'error';
  onClose?: UnknownFunction;
}

export function Message({ children, onClose, variant }: MessageProps) {
  return (
    <MessageWrapper variant={variant}>
      {onClose && (
        <MessageClose variant={variant} onClick={onClose}>
          <SvgXCircle />
        </MessageClose>
      )}
      <MessageIcon variant={variant}>
        <SvgAlertCircle />
      </MessageIcon>
      <MessageDescription variant={variant}>{children}</MessageDescription>
    </MessageWrapper>
  );
}
