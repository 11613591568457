import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  ControlledCheckbox,
  ControlledRadio,
  ControlledSelect,
  ControlledSimpleInput,
  Inline,
  PostbackItem,
  RadioOption,
  Row,
  TitleH4,
} from '@partnerka-front/components';
import { CatalogDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';

const PostbackBasicHeader = styled.div`
  margin-bottom: 24px;

  label {
    font-weight: 700;
    font-size: 24px;
  }
`;

const StatusError = styled.div`
  margin-top: -22px;
  padding: 0 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.negative};
`;

interface PostbackBasicProps {
  catalogs: CatalogDto[] | null;
  typePostback: string;
  watch: any;
}

export function PostbackBasic({ catalogs, typePostback, watch }: PostbackBasicProps) {
  const { t } = useTranslation(['postback']);

  const options: RadioOption[] = [
    { value: 'global', label: `${t('postback.type_global', { ns: 'postback' })}` },
    { value: 'local', label: `${t('postback.type_local', { ns: 'postback' })}` },
  ];

  const [optionsCatalog, setOptionsCatalog] = useState<object>({});

  const handleCatalogs = (catalogs: CatalogDto[]) => {
    const options: { [key: string]: string } = {
      none: `${t('postback.select_catalog', { ns: 'postback' })}`,
    };

    for (let i = 0; i < catalogs.length; i++) {
      const catalog = catalogs[i];

      options[catalog.id] = catalog.title;
    }

    setOptionsCatalog(options);
  };

  useEffect(() => {
    if (catalogs) handleCatalogs(catalogs);
  }, [catalogs]);

  return (
    <Card>
      <PostbackBasicHeader>
        <label>{t('postback.create_title', { ns: 'postback' })}</label>
      </PostbackBasicHeader>

      <PostbackItem>
        <TitleH4>{t('postback.select_type', { ns: 'postback' })}</TitleH4>

        <Inline gap={40}>
          <ControlledRadio name={'type_postback'} options={options} />
        </Inline>

        <Row>
          <ControlledSimpleInput
            name={'name'}
            placeholder={`${t('postback.placeholder_name', { ns: 'postback' })}`}
          />

          {typePostback === 'local' && (
            <ControlledSelect name={'catalog'} options={optionsCatalog} />
          )}
        </Row>
      </PostbackItem>

      <PostbackItem>
        <TitleH4>{t('postback.status_title', { ns: 'postback' })}</TitleH4>

        <Inline gap={40}>
          <ControlledCheckbox name={'approved'}>
            {t('postback.approved', { ns: 'postback' })}
          </ControlledCheckbox>
          <ControlledCheckbox name={'paid'}>
            {t('postback.hold', { ns: 'postback' })}
          </ControlledCheckbox>
          <ControlledCheckbox name={'created'}>
            {t('postback.created', { ns: 'postback' })}
          </ControlledCheckbox>
          <ControlledCheckbox name={'canceled'}>
            {t('postback.canceled', { ns: 'postback' })}
          </ControlledCheckbox>
        </Inline>

        {!watch('approved') && !watch('paid') && !watch('created') && !watch('canceled') && (
          <StatusError>{t('postback.status_valid', { ns: 'postback' })}</StatusError>
        )}
      </PostbackItem>

      <PostbackItem>
        <TitleH4>{t('postback.title_url', { ns: 'postback' })}</TitleH4>

        <ControlledSimpleInput name={'url'} placeholder={'https://postback'} />
      </PostbackItem>
    </Card>
  );
}
