import { Card } from '../card';
import styled from 'styled-components';
import { OfferDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { SvgComment } from '@partnerka-front/icons';
import { Tooltip, Price } from '@partnerka-front/components';

const CardOffers = styled(Card)`
  overflow: auto;
`;

const Wrapper = styled.div`
  min-width: fit-content;
`;

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 40px 120px 120px 80px;
  margin-bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.support};
  border-right: 1px solid ${({ theme }) => theme.colors.support};
  border-left: 1px solid ${({ theme }) => theme.colors.support};

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: ${({ theme }) => theme.colors.secondary};

    svg path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.support};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.basicLight};
  }
`;

interface ItemProps {
  isChildren?: boolean;
}

const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: ${({ isChildren }) => (isChildren ? '0' : '10px')};
  border-right: 1px solid ${({ theme }) => theme.colors.support};

  &:last-child {
    border-right: none;
  }
`;

const ItemPrice = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.support};

  &:last-child {
    border: none;
  }
`;

const ItemIconDescription = styled.div`
  position: relative;
  font-size: 0;

  &:hover > div {
    display: block;
    cursor: default;
  }
`;

interface CatalogOffersProps {
  hold: string;
  offers: OfferDto[];
}

export function CatalogOffers({ hold, offers }: CatalogOffersProps) {
  const { t } = useTranslation(['catalog', 'common']);

  return (
    <CardOffers>
      <Wrapper>
        <Row>
          <Item>{t('catalog.offers', { ns: 'catalog' })}</Item>
          <Item>
            <SvgComment />
          </Item>
          <Item>{t('catalog.cost', { ns: 'catalog' })}</Item>
          <Item>{t('catalog.payment', { ns: 'catalog' })}</Item>
          <Item>{t('catalog.hold', { ns: 'catalog' })}</Item>
        </Row>
        {offers.map((offer) => (
          <Row key={offer.id}>
            <Item>{offer.name}</Item>
            <Item>
              {offer.description && (
                <ItemIconDescription>
                  <Tooltip description={offer.description}>
                    <SvgComment />
                  </Tooltip>
                </ItemIconDescription>
              )}
            </Item>
            <Item isChildren>
              {offer.prices.map((item) =>
                item.cost && item.currency ? (
                  <ItemPrice key={item.id}>
                    <Price amount={item.cost} currency={item.currency} />
                  </ItemPrice>
                ) : (
                  <>-</>
                )
              )}
            </Item>
            <Item isChildren>
              {offer.prices.map((item) =>
                item.cost && item.currency ? (
                  <ItemPrice key={item.id}>
                    {offer.fix_remuneration && (
                      <Price amount={offer.fix_remuneration} currency={item.currency} />
                    )}
                    {offer.reward_percent && <>{offer.reward_percent}%</>}
                    {!offer.fix_remuneration && !offer.reward_percent && <>-</>}
                  </ItemPrice>
                ) : (
                  <ItemPrice>-</ItemPrice>
                )
              )}
            </Item>
            <Item isChildren>
              {offer.prices.map((item) =>
                item.cost && item.currency ? (
                  <ItemPrice key={item.id}>
                    {hold.split(' ')[0]} {t('common.days', { ns: 'common' })}
                  </ItemPrice>
                ) : (
                  <ItemPrice>-</ItemPrice>
                )
              )}
            </Item>
          </Row>
        ))}
      </Wrapper>
    </CardOffers>
  );
}
