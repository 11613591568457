import { useTranslation } from 'react-i18next';
import { TableItem, TableRow, DateFormat, Price } from '@partnerka-front/components';
import { DealDto } from '@partnerka-front/types';

interface DealsTableItemProps {
  deal: DealDto;
}

export function DealsTableItem({ deal }: DealsTableItemProps) {
  const { t } = useTranslation(['deals']);

  return (
    <TableRow gridTemplateColumns={'100px 115px 90px 1fr 160px 110px 110px'}>
      <TableItem noRightBorder>{deal.id}</TableItem>
      <TableItem noRightBorder>
        {deal.status === 'Approved' && t('deals.approved', { ns: 'deals' })}
        {deal.status === 'Paid' && t('deals.paid', { ns: 'deals' })}
        {deal.status === 'Created' && t('deals.created', { ns: 'deals' })}
        {deal.status === 'Canceled' && t('deals.canceled', { ns: 'deals' })}
        {deal.status === 'Rejected' && t('deals.rejected', { ns: 'deals' })}
      </TableItem>
      <TableItem noRightBorder>
        <DateFormat date={deal.conversion_time} />
      </TableItem>
      <TableItem noRightBorder minWidth={230}>
        {deal.offer.catalog.title}
      </TableItem>
      <TableItem noRightBorder>{deal.promo}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.cost} currency={deal.currency} />
      </TableItem>
      <TableItem>
        <Price amount={deal.payment_sum} currency={deal.currency} />
      </TableItem>
    </TableRow>
  );
}
