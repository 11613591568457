import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SvgArrowRight } from '@partnerka-front/icons';

const LinkNextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
  }

  &:hover {
    span {
      border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }
`;

const LinkNextA = styled.a`
  text-decoration: none;
`;

interface LinkNextProps {
  to: string;
  children: ReactNode;
  type?: 'link' | 'a';
}

export function LinkNext({ to, children, type }: LinkNextProps) {
  const navigate = useNavigate();

  if (type === 'a')
    return (
      <LinkNextA href={to} target={'_blank'} rel={'noreferrer'}>
        <LinkNextWrapper>
          <span>{children}</span>
          <SvgArrowRight />
        </LinkNextWrapper>
      </LinkNextA>
    );

  return (
    <LinkNextWrapper onClick={() => navigate(to)}>
      <span>{children}</span>
      <SvgArrowRight />
    </LinkNextWrapper>
  );
}
