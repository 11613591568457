import styled from 'styled-components';
import { Price, TitleH2 } from '@partnerka-front/components';
import { useNavigate } from 'react-router-dom';
import { Currency } from '@partnerka-front/types';
import { down, up } from 'styled-breakpoints';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../src/card';
import { useUser } from '@partnerka-front/utils';

const BalanceCard = styled(Card)`
  ${up('xl')} {
    max-width: 360px;
  }
`;

interface BalanceWrapperProps {
  flexDirection?: 'row' | 'column';
}

const BalanceWrapper = styled.div<BalanceWrapperProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => {
    if (flexDirection === 'column') return 'column';
    return 'row';
  }};
  gap: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${down('xl')} {
    flex-direction: row;
    grid-column: 1 / 3;
  }

  ${down('sm')} {
    grid-column: 1;
    flex-direction: column;
  }
`;

interface ItemProps {
  variant?: 'default' | 'secondary';
}

const Item = styled.div<ItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  color: ${({ variant, theme }) => {
    if (variant === 'secondary') return theme.colors.secondary;
    return theme.colors.headliner;
  }};

  div {
    font-weight: ${({ variant }) => {
      if (variant === 'secondary') return '400';
      return '700';
    }};
  }

  span {
    font-weight: ${({ variant }) => {
      if (variant === 'secondary') return '400';
      return '500';
    }};
    color: ${({ variant, theme }) => {
      if (variant === 'secondary') return theme.colors.support;
      return theme.colors.secondary;
    }};
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const BtnWithdrawal = styled.button`
  height: 30px;
  padding: 2px 10px;
  border: none;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.inactiveGrey};
  font-size: 12px;
  cursor: pointer;
`;

interface BalanceProps {
  flexDirection?: 'row' | 'column';
}

export function Balance({ flexDirection }: BalanceProps) {
  const { t, i18n } = useTranslation(['balance']);
  const navigate = useNavigate();
  const { user, fetchUser } = useUser();

  useEffect(() => {
    fetchUser();
  }, [i18n.language]);

  if (!user) return null;

  return (
    <BalanceWrapper flexDirection={flexDirection}>
      <BalanceCard>
        <Item>
          <TitleH2>USD</TitleH2>
          <BtnWithdrawal onClick={() => navigate('/support')}>
            {t('balance.withdrawal', { ns: 'balance' })}
          </BtnWithdrawal>
        </Item>
        <Item>
          <label>{t('balance.available_withdrawal', { ns: 'balance' })}:</label>
          <Price amount={user.balance_usd} currency={Currency.USD} />
        </Item>
        <Item variant={'secondary'}>
          <label>{t('balance.hold', { ns: 'balance' })}:</label>
          <Price amount={user.hold_balance.sum_hold_usd} currency={Currency.USD} />
        </Item>
      </BalanceCard>

      <BalanceCard>
        <Item>
          <TitleH2>RUB</TitleH2>
          <BtnWithdrawal onClick={() => navigate('/support')}>
            {t('balance.withdrawal', { ns: 'balance' })}
          </BtnWithdrawal>
        </Item>
        <Item>
          <label>{t('balance.available_withdrawal', { ns: 'balance' })}:</label>
          <Price amount={user.balance_rub} currency={Currency.RUB} />
        </Item>
        <Item variant={'secondary'}>
          <label>{t('balance.hold', { ns: 'balance' })}:</label>
          <Price amount={user.hold_balance.sum_hold_rub} currency={Currency.RUB} />
        </Item>
      </BalanceCard>
    </BalanceWrapper>
  );
}
