export function SvgCopy() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3333 12.8335C10.3333 11.4527 11.4525 10.3335 12.8333 10.3335H16.9999C18.3807 10.3335 19.4999 11.4527 19.4999 12.8335V17.0002C19.4999 18.3809 18.3807 19.5002 16.9999 19.5002H12.8333C11.4525 19.5002 10.3333 18.3809 10.3333 17.0002V12.8335Z"
        stroke="#35434E"
      />
      <path
        d="M13.6667 7.17361V7C13.6667 5.61929 12.5474 4.5 11.1667 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V11.1667C4.5 12.5474 5.61929 13.6667 7 13.6667H7.17361"
        stroke="#35434E"
        strokeLinecap="round"
      />
    </svg>
  );
}
