import { AxiosResponse } from 'axios';
import { $api } from '@partnerka-front/api';
import {
  ActivationPayload,
  LoginPayload,
  LoginDto,
  RegistrationPayload,
  RegistrationDto,
  ResendActivationPayload,
  ResetPasswordConfirmPayload,
  ResetPasswordPayload,
  UserDto,
} from '@partnerka-front/types';

const basePath = '/auth';

export async function getUser(): Promise<AxiosResponse<UserDto>> {
  return $api.get(`${basePath}/users/me/`);
}

export async function login(payload: LoginPayload): Promise<AxiosResponse<LoginDto>> {
  return $api.post(`${basePath}/jwt/create/`, payload);
}

export async function registration(
  payload: RegistrationPayload
): Promise<AxiosResponse<RegistrationDto>> {
  return $api.post(`${basePath}/users/`, payload);
}

export async function resendActivation(
  payload: ResendActivationPayload
): Promise<AxiosResponse<void>> {
  return $api.post(`${basePath}/users/resend_activation/`, payload);
}

export async function activation(payload: ActivationPayload): Promise<AxiosResponse<void>> {
  return $api.post(`${basePath}/users/activation/`, payload);
}

export async function resetPassword(payload: ResetPasswordPayload): Promise<AxiosResponse<void>> {
  return $api.post(`${basePath}/users/reset_password/`, payload);
}

export async function resetPasswordConfirm(
  payload: ResetPasswordConfirmPayload
): Promise<AxiosResponse<void>> {
  return $api.post(`${basePath}/users/reset_password_confirm/`, payload);
}
