import { useEffect, useState } from 'react';
import { CatalogDto, GlobalEvents } from '@partnerka-front/types';
import { BreadCrumbs, Catalog, PageLoading, Container } from '@partnerka-front/components';
import { Api } from '@partnerka-front/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEventBus } from '@partnerka-front/utils';

export function CatalogPage() {
  const { id } = useParams();
  const { t, i18n } = useTranslation(['offers']);
  const { subscribe } = useEventBus();
  const navigate = useNavigate();

  const [catalog, setCatalog] = useState<CatalogDto | null>(null);
  const [pending, setPending] = useState(true);

  const getCatalog = async (id?: string) => {
    setPending(true);

    if (id)
      await Api.getCatalog(id)
        .then((res) => setCatalog(res.data))
        .catch(() => navigate('/offers'));

    setPending(false);
  };
  subscribe(GlobalEvents.fetchCatalog, () => getCatalog(id));

  useEffect(() => {
    if (id) getCatalog(id);
  }, [i18n.language]);

  return (
    <Container>
      {pending && <PageLoading />}

      {catalog && (
        <>
          <BreadCrumbs
            list={[{ label: `${t('offers.title', { ns: 'offers' })}`, to: '/offers' }]}
            current={catalog.title}
          />
          <Catalog catalog={catalog} updateCatalog={() => id && getCatalog(id)} />
        </>
      )}
    </Container>
  );
}
