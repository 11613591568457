import { ReactNode } from 'react';
import styled from 'styled-components';
import { UnknownFunction } from '@partnerka-front/types';
import { SvgDown } from '@partnerka-front/icons';

interface FilterItemStyleProps {
  isOpen: boolean;
}

const FilterItemWrapper = styled.div``;

const FilterItemTitle = styled.div<FilterItemStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  cursor: pointer;

  h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
  }

  span {
    color: ${({ theme }) => theme.colors.accent};
  }

  svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
    transition: 0.3s;

    path:last-child {
      stroke: ${({ theme }) => theme.colors.headliner};
    }
  }
`;

const FilterItemContent = styled.div`
  padding: 10px 0;
`;

interface FilterItemProps {
  title: string;
  isOpen: boolean;
  onClick: UnknownFunction;
  count: number;
  children?: ReactNode;
}

export function FilterItem({ title, isOpen, onClick, count, children }: FilterItemProps) {
  return (
    <FilterItemWrapper>
      <FilterItemTitle isOpen={isOpen} onClick={onClick}>
        <h4>
          {title} {count > 0 && <span>({count})</span>}
        </h4>
        <SvgDown />
      </FilterItemTitle>
      {isOpen && <FilterItemContent>{children}</FilterItemContent>}
    </FilterItemWrapper>
  );
}
