import { AxiosResponse } from 'axios';
import { CouponPayload, PromoPayload } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/promo';

export async function createPromo(payload: PromoPayload): Promise<AxiosResponse<any>> {
  return $api.post(`${basePath}/`, payload);
}

export async function createCoupon(payload: CouponPayload): Promise<AxiosResponse<any>> {
  return $api.post(`${basePath}/`, { ...payload, type_code: 'COUPON' });
}

export async function patchPromo(
  id: string,
  payload: Partial<PromoPayload>
): Promise<AxiosResponse<any>> {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function patchCoupon(
  id: string,
  payload: Partial<CouponPayload>
): Promise<AxiosResponse<any>> {
  return $api.patch(`${basePath}/${id}/`, payload);
}
