import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { DateRange } from '@partnerka-front/types';
import { format } from 'date-fns';
import { SvgCalendar, SvgCloseCalendar } from '@partnerka-front/icons';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

const DateRangeWrapper = styled.div`
  position: relative;

  .react-daterange-picker {
    &__wrapper {
      display: none;
    }

    &__calendar {
      width: auto !important;

      button {
        background: transparent;
        border: none;
      }
    }
  }

  .react-calendar {
    padding: 0;
    margin: 0;

    &__navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding: 0 0 12px;

      &__label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #120d26;

        pointer-events: none;

        &__labelText {
          width: 100%;
          text-align: center;
        }
      }

      &__arrow {
        font-size: 14px;
        color: red;
      }

      &__prev-button,
      &__next-button {
        display: block;
        position: relative;
        color: transparent;

        width: 40px;
        height: 40px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.29289 5.29289C7.90237 5.68342 7.90237 6.31658 8.29289 6.70711L13.5858 12L8.29289 17.2929C7.90237 17.6834 7.90237 18.3166 8.29289 18.7071C8.68342 19.0976 9.31658 19.0976 9.70711 18.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L9.70711 5.29289C9.31658 4.90237 8.68342 4.90237 8.29289 5.29289Z' fill='%23485AFF'/%3E%3C/svg%3E%0A");
        }
      }

      &__prev-button::before {
        transform: rotate(180deg);
      }

      &__prev2-button,
      &__next2-button {
        display: none;
      }
    }

    &__month-view {
      &__weekdays {
        padding: 12px 0;
        border-bottom: 1px solid #f2f3f5;

        &__weekday {
          width: 40px;
          flex: 0 0 auto !important;
          text-align: center;
          font-size: 14px;

          abbr {
            text-decoration: none;
          }
        }
      }

      &__days {
        padding: 12px 0 0;
      }
    }

    &__tile {
      position: relative;

      width: 40px;
      height: 40px;
      padding: 0;
      font-size: 14px;
      flex: 0 0 auto !important;

      abbr {
        position: relative;
        z-index: 3;
      }

      &--active {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;

          background: #f2f3f5;
        }
      }

      &--rangeStart {
        &::before {
          left: 50%;
        }
      }

      &--rangeEnd {
        &::before {
          right: 50%;
        }
      }

      &--rangeStart,
      &--rangeEnd {
        color: #ffffff;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 2;

          width: 40px;
          height: 40px;

          border-radius: 50%;

          transform: translate3d(-50%, -50%, 0);

          background-color: ${({ theme }) => theme.colors.accent};
        }
      }
    }
  }
`;

const DateRangeSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ced2d6;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }
`;

interface SectionIconProps {
  isClose?: boolean;
}

const SectionIcon = styled.div<SectionIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  line-height: 1;
  font-size: 0;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme, isClose }) => (isClose ? theme.colors.tableGrey : 'transparent')};
  }

  svg {
    path {
      transition: 0.3s;
    }
  }
`;

interface DateRangePopupProps {
  visible?: boolean;
}
const DateRangePopup = styled.div<DateRangePopupProps>`
  display: none;

  ${({ visible }) => visible && 'display: flex;'}

  position: absolute;
  left: 0;
  top: 44px;
  z-index: 500;

  flex-direction: column;
  align-items: center;
  padding: 16px 16px;
  max-width: 310px;

  background: #ffffff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  button {
    position: relative;
    z-index: 1000;
  }
`;

const DATE_FORMAT = 'yyyy.MM.dd';

interface DateRangeSelectorProps {
  value: DateRange | undefined;
  setValue: (value?: DateRange) => void;
}

export function DateRangeSelector({ value, setValue }: DateRangeSelectorProps) {
  const { t } = useTranslation(['common']);
  const [open, setOpen] = useState(false);
  const portalRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (!open) setOpen(true);
  };

  const close = () => setTimeout(() => setOpen(false));

  const reset = (e: any) => {
    e.stopPropagation();

    setValue(undefined);
    setOpen(false);
  };

  return (
    <DateRangeWrapper>
      <DateRangeSelect className={open ? 'active' : ''} onClick={handleClick}>
        <div>
          {value
            ? `${format(value[0], DATE_FORMAT)} - ${format(value[1], DATE_FORMAT)}`
            : `${t('common.select_period', { ns: 'common' })}`}
        </div>
        {value ? (
          <SectionIcon onClick={reset} isClose>
            <SvgCloseCalendar />
          </SectionIcon>
        ) : (
          <SectionIcon>
            <SvgCalendar />
          </SectionIcon>
        )}
      </DateRangeSelect>
      {open && (
        <>
          <DateRangePicker
            onChange={setValue}
            minDate={new Date('2022.01.01')}
            value={value}
            isOpen={open}
            onCalendarClose={close}
            portalContainer={portalRef?.current}
          />
        </>
      )}
      <DateRangePopup visible={open}>
        <div ref={portalRef} />
      </DateRangePopup>
    </DateRangeWrapper>
  );
}
