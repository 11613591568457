import { AxiosResponse } from 'axios';
import { $api } from '@partnerka-front/api';
import {
  BusinessCatalogDto,
  BusinessDealDto,
  BusinessFinanceDto,
  BusinessPartnerDto,
  BusinessStatisticDto,
  CompanyDto,
  GetBusinessDealsOptions,
  GetBusinessFinancesOptions,
  Paginated,
  TokenDto,
} from '@partnerka-front/types';

const basePath = '/business_partner';

export async function getCompany(): Promise<AxiosResponse<CompanyDto>> {
  return $api.get(`${basePath}/`);
}

export async function patchCompany(payload: CompanyDto): Promise<AxiosResponse<CompanyDto>> {
  return $api.patch(`${basePath}/setting_business_partner/`, payload);
}

export async function getPartners(
  page = 1,
  pageSize = 10
): Promise<AxiosResponse<Paginated<BusinessPartnerDto>>> {
  return $api.get(`${basePath}/get_list_partners/`, { params: { page, page_size: pageSize } });
}

export async function getBusinessStatistic(): Promise<AxiosResponse<BusinessStatisticDto>> {
  return $api.get(`${basePath}/get_statistic_by_company/`);
}

export async function getBusinessCatalog(id: string): Promise<AxiosResponse<BusinessCatalogDto>> {
  return $api.get(`${basePath}/${id}/retrieve_catalog/`);
}

export async function getBusinessCatalogs(): Promise<AxiosResponse<BusinessCatalogDto[]>> {
  return $api.get(`${basePath}/catalog/`);
}

export async function getBusinessFinances(
  page = 1,
  pageSize = 10,
  options: GetBusinessFinancesOptions
): Promise<AxiosResponse<Paginated<BusinessFinanceDto>>> {
  return $api.get(`${basePath}/get_story_expenses/`, {
    params: {
      page,
      page_size: pageSize,
      o: options.o,
    },
  });
}

export async function getBusinessDeals(
  page = 1,
  pageSize = 10,
  options: GetBusinessDealsOptions
): Promise<AxiosResponse<Paginated<BusinessDealDto>>> {
  return $api.get(`${basePath}/get_list_deals/`, {
    params: {
      page,
      page_size: pageSize,
      o: options.o,
    },
  });
}

export async function refreshBusinessToken(): Promise<AxiosResponse<TokenDto>> {
  return $api.patch(`${basePath}/refresh_business_token/`);
}
