function isLastPoint(str: string) {
  const value = str.toLowerCase().split('');
  let count = 0;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === '.') count = count + 1;
  }

  if (count === 1 && str.endsWith('.') && str.length > 1) {
    return true;
  } else {
    return false;
  }
}

export function isNumberValid(str: string) {
  const regInteger = /^-?\d+$/;
  const regFloat = /^\d+\.\d+$/;

  if (str.match(regInteger) || str.match(regFloat) || str === '' || isLastPoint(str)) {
    return true;
  } else return false;
}
