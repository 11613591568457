export function SvgCreateWhite() {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 16.7778V21.2222C16 22.2044 15.2044 23 14.2222 23H1.77778C0.795556 23 0 22.2044 0 21.2222V8.77778C0 7.79556 0.795556 7 1.77778 7H6.22222V8.77778H1.77778V21.2222H14.2222V16.7778H16Z"
        fill="white"
      />
      <path
        d="M16.0001 10.5556H12.4445V7H10.6668V10.5556H7.11121V12.3333H10.6668V15.8889H12.4445V12.3333H16.0001"
        fill="white"
      />
    </svg>
  );
}
