import {
  Button,
  Container,
  Line,
  Message,
  PostbackLogsTable,
  Title,
} from '@partnerka-front/components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const HeaderPostbackLogs = styled.div`
  display: flex;
  gap: 64px;
  margin-bottom: 10px;

  ${down('md')} {
    flex-direction: column;
    gap: 0;
  }

  & > div {
    margin-top: -20px;

    ${down('lg')} {
      margin-top: 0;
    }
  }
`;

export function PostbackLogsPage() {
  const { t } = useTranslation(['postback']);
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <HeaderPostbackLogs>
          <Title>PostBack</Title>
          <Message>
            <div
              dangerouslySetInnerHTML={{ __html: t('postback.logs_warning', { ns: 'postback' }) }}
            />
          </Message>
        </HeaderPostbackLogs>
        <Line>
          <Button variant="inactive" onClick={() => navigate('/postback')}>
            {t('postback.btn_my', { ns: 'postback' })}
          </Button>
          <Button variant="inactive" onClick={() => navigate('/postback/new')}>
            {t('postback.btn_new', { ns: 'postback' })}
          </Button>
          <Button variant="active" onClick={() => navigate('/postback/logs')}>
            {t('postback.btn_logs', { ns: 'postback' })}
          </Button>
        </Line>
      </Container>

      <PostbackLogsTable />
    </>
  );
}
