export function SvgUserMock() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25 23.75C6.25 20.9886 8.48857 18.75 11.25 18.75H18.75C21.5114 18.75 23.75 20.9886 23.75 23.75C23.75 25.1307 22.6308 26.25 21.25 26.25H8.75C7.36929 26.25 6.25 25.1307 6.25 23.75Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M15 13.75C17.7614 13.75 20 11.5114 20 8.75C20 5.98858 17.7614 3.75 15 3.75C12.2386 3.75 10 5.98858 10 8.75C10 11.5114 12.2386 13.75 15 13.75Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
