import { AxiosResponse } from 'axios';
import { CreateOfferPayload, OfferDto } from '@partnerka-front/types';
import { $api } from './index';

const basePath = '/offer';

export async function createOffer(payload: CreateOfferPayload) {
  return $api.post(`${basePath}/`, payload);
}

export async function patchOffer(
  id: string,
  payload: Partial<CreateOfferPayload>
): Promise<AxiosResponse<OfferDto>> {
  return $api.patch(`${basePath}/${id}/`, payload);
}
