export function SvgEyeClose() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.84968 1.8498C1.62779 2.0717 1.60763 2.41891 1.78917 2.66359L1.84968 2.73369L5.21183 6.09583C3.61017 7.22039 2.4131 8.9 1.91569 10.8871C1.83188 11.2219 2.03538 11.5613 2.37022 11.6452C2.70507 11.7289 3.04446 11.5255 3.12828 11.1906C3.56951 9.428 4.65923 7.94964 6.11176 6.99551L7.61978 8.50366C7.03019 9.10491 6.66663 9.92866 6.66663 10.8372C6.66663 12.6782 8.15901 14.1706 9.99996 14.1706C10.9086 14.1706 11.7323 13.807 12.3336 13.2174L17.2664 18.1503C17.5105 18.3944 17.9061 18.3944 18.1502 18.1503C18.3721 17.9285 18.3923 17.5812 18.2107 17.3366L18.1502 17.2665L13.0556 12.1713L13.0566 12.1701L12.0565 11.1718L9.66496 8.78075L9.66663 8.78008L7.2656 6.3816L7.26663 6.38008L6.32223 5.43799L2.73357 1.8498C2.48949 1.60573 2.09376 1.60573 1.84968 1.8498ZM8.50338 9.388L11.4493 12.3339C11.0743 12.6971 10.5632 12.9206 9.99996 12.9206C8.84938 12.9206 7.91663 11.9878 7.91663 10.8372C7.91663 10.274 8.14016 9.76291 8.50338 9.388ZM9.99996 4.58341C9.16638 4.58341 8.35754 4.7068 7.59253 4.93758L8.62338 5.96774C9.06988 5.87951 9.53029 5.83341 9.99996 5.83341C13.2692 5.83341 16.0915 8.06696 16.8725 11.1944C16.9562 11.5292 17.2955 11.7329 17.6304 11.6493C17.9653 11.5657 18.169 11.2264 18.0853 10.8915C17.1661 7.21068 13.8462 4.58341 9.99996 4.58341ZM10.1622 7.50781L13.33 10.6751C13.2451 8.961 11.8726 7.58978 10.1622 7.50781Z"
        fill="#868E95"
      />
    </svg>
  );
}
