export function SvgHome() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 10.8073C3 9.90934 3.40231 9.05854 4.09639 8.4887L9.46186 4.08372C10.9372 2.87248 13.0628 2.87249 14.5381 4.08372L19.9036 8.4887C20.5977 9.05854 21 9.90934 21 10.8073V17.9999C21 19.6568 19.6569 20.9999 18 20.9999H6C4.34315 20.9999 3 19.6568 3 17.9999V10.8073Z"
        stroke="#6E84A3"
        strokeWidth="1.5"
      />
    </svg>
  );
}
