import { Title } from './styles';
import { Card } from '../card';
import styled from 'styled-components';
import { PromoMaterialDto } from '@partnerka-front/types';
import { DateFormat, TableItem, TableRow, TableWrapper } from '@partnerka-front/components';
import { SvgFile, SvgImage, SvgPaperclip } from '@partnerka-front/icons';
import { useTranslation } from 'react-i18next';

const Label = styled.label`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.88;
`;

const Type = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const A = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;
`;

interface CatalogMaterialsProps {
  materials: PromoMaterialDto[];
}

export function CatalogMaterials({ materials }: CatalogMaterialsProps) {
  const { t } = useTranslation(['promo-materials']);

  return (
    <Card childrenBottom={10}>
      <Title>{t('promo_materials.title', { ns: 'promo-materials' })}</Title>
      <Label>{t('promo_materials.label', { ns: 'promo-materials' })}</Label>
      <TableWrapper width={'100%'}>
        <TableRow gridTemplateColumns={'1fr 100px 60px 100px'}>
          <TableItem minWidth={210}>
            {t('promo_materials.name', { ns: 'promo-materials' })}
          </TableItem>
          <TableItem>{t('promo_materials.created', { ns: 'promo-materials' })}</TableItem>
          <TableItem>{t('promo_materials.type', { ns: 'promo-materials' })}</TableItem>
          <TableItem>{t('promo_materials.action', { ns: 'promo-materials' })}</TableItem>
        </TableRow>
        {materials.map((material, i) => (
          <TableRow key={i} gridTemplateColumns={'1fr 100px 60px 100px'}>
            <TableItem minWidth={210}>{material.title}</TableItem>
            <TableItem>
              <DateFormat date={material.modified_at} />
            </TableItem>
            <TableItem>
              <Type>
                {material.type_material === 'IMAGE' && <SvgImage />}
                {material.type_material === 'FILE' && <SvgFile />}
                {material.type_material === 'URL' && <SvgPaperclip />}
              </Type>
            </TableItem>
            <TableItem>
              <A href={material.url} target={'_blank'} rel="noreferrer">
                {t('promo_materials.open', { ns: 'promo-materials' })}
              </A>
            </TableItem>
          </TableRow>
        ))}
      </TableWrapper>
    </Card>
  );
}
