import { Navigate } from 'react-router-dom';
import { useUser } from '@partnerka-front/utils';

interface RequireAuthProps {
  children: JSX.Element;
}

export function RequireAuth({ children }: RequireAuthProps) {
  const { isAuth } = useUser();

  if (!isAuth) {
    return <Navigate to="/" />;
  }

  return children;
}

export function NoRequireAuth({ children }: RequireAuthProps) {
  const { isAuth } = useUser();

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return children;
}
