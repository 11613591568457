import styled from 'styled-components';
import { SvgYoutube } from '@partnerka-front/icons';

interface PreviewYoutubeStyleProps {
  bg: string;
}

const PreviewYoutubeStyle = styled.a<PreviewYoutubeStyleProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 5px;
  transform: translate3d(0, 0, 0);

  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  svg {
    position: absolute;
    top: 24px;
    width: 48px;
  }
`;

interface PreviewYoutubeProps {
  url: string;
}

export function PreviewYoutube({ url }: PreviewYoutubeProps) {
  const match = url.match(/v=([^&]+)/);
  const videoId = match ? match[1] : 'no-id';

  if (videoId === 'no-id') return null;

  return (
    <PreviewYoutubeStyle
      href={url}
      target={'_blank'}
      rel={'noreferrer'}
      bg={`https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`}>
      <SvgYoutube />
    </PreviewYoutubeStyle>
  );
}
