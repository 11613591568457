import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SkeletonStyle } from './skeleton-style';

interface PreviewStyleProps {
  src: string;
}

const PreviewStyle = styled.div<PreviewProps>`
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9
  border-radius: 5px;
  transform: translate3d(0, 0, 0);

  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
`;

const PreviewNone = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9
  border-radius: 5px;
  transform: translate3d(0, 0, 0);

  background-color: ${({ theme }) => theme.colors.support};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.344 31.0556C13.344 21.3599 21.235 13.5 30.969 13.5H75.0315C84.7653 13.5 92.6565 21.3599 92.6565 31.0556V74.9444C92.6565 84.6399 84.7653 92.5 75.0315 92.5H30.969C21.235 92.5 13.344 84.6399 13.344 74.9444V31.0556Z" stroke="white" stroke-width="2"/><path d="M39.7815 48.6112C44.6486 48.6112 48.594 44.6814 48.594 39.8334C48.594 34.9856 44.6486 31.0557 39.7815 31.0557C34.9145 31.0557 30.969 34.9856 30.969 39.8334C30.969 44.6814 34.9145 48.6112 39.7815 48.6112Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.7502 88.1113L30.413 72.3451C33.2205 68.8498 38.2452 68.0725 41.9846 70.5557C45.7242 73.039 50.7486 72.2617 53.5563 68.7659L60.6028 59.993C63.6863 56.1536 69.3338 55.5857 73.1258 58.7329L92.6565 74.9446" stroke="white" stroke-width="2"/></svg>');
  background-size: 60% 60%;
`;

interface PreviewProps {
  src: string | null;
}

export function Preview({ src }: PreviewProps) {
  const [isLoading, setIsLoading] = useState(false);

  if (!src) return <PreviewNone />;

  useEffect(() => {
    if (typeof src === 'string') {
      const fullSizeImage = new Image();
      fullSizeImage.src = src;
      fullSizeImage.onload = () => {
        setIsLoading(true);
      };
    }
  }, []);

  if (!isLoading) return <PreviewSkeleton />;

  return <PreviewStyle src={src} />;
}

export const PreviewSkeleton = styled.div`
  width: 100%;
  padding-top: 56.25%; // 16:9
  transform: translate3d(0, 0, 0);

  ${SkeletonStyle};

  border-radius: 5px;
`;
