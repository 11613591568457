export function SvgComment() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 8.66671C2.5 6.79987 2.5 5.86645 2.86331 5.15341C3.18289 4.5262 3.69282 4.01627 4.32003 3.69668C5.03308 3.33337 5.96649 3.33337 7.83333 3.33337H12.1667C14.0335 3.33337 14.9669 3.33337 15.68 3.69668C16.3072 4.01627 16.8171 4.5262 17.1367 5.15341C17.5 5.86645 17.5 6.79987 17.5 8.66671V9.66671C17.5 11.5335 17.5 12.467 17.1367 13.18C16.8171 13.8072 16.3072 14.3171 15.68 14.6367C14.9669 15 14.0335 15 12.1667 15H6.17851C5.9575 15 5.74553 15.0879 5.58926 15.2441L3.92259 16.9108C3.39762 17.4358 2.5 17.064 2.5 16.3215V15V10.8334V8.66671ZM7.5 6.66671C7.03977 6.66671 6.66667 7.03981 6.66667 7.50004C6.66667 7.96027 7.03977 8.33337 7.5 8.33337H12.5C12.9602 8.33337 13.3333 7.96027 13.3333 7.50004C13.3333 7.03981 12.9602 6.66671 12.5 6.66671H7.5ZM7.5 10C7.03977 10 6.66667 10.3731 6.66667 10.8334C6.66667 11.2936 7.03977 11.6667 7.5 11.6667H10C10.4602 11.6667 10.8333 11.2936 10.8333 10.8334C10.8333 10.3731 10.4602 10 10 10H7.5Z"
        fill="#35434E"
      />
    </svg>
  );
}
