import { createContext, useContext } from 'react';
import { BusinessStatisticDto } from '@partnerka-front/types';

interface StatisticContextPayload {
  statistic: BusinessStatisticDto;
  setStatistic: (value: BusinessStatisticDto) => void;
}

const StatisticContext = createContext<Partial<StatisticContextPayload>>({});
export const StatisticProvider = StatisticContext.Provider;

export const useStatistic = () => {
  const { statistic, setStatistic } = useContext(StatisticContext) as StatisticContextPayload;

  return { statistic, setStatistic };
};
