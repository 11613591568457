import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { App, PageLoading } from '@partnerka-front/components';
import { AppThemeProvider } from '@partnerka-front/theme';
import './i18n/config';

const root = ReactDOM.createRoot(document.getElementById('wrapper') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Suspense fallback={<PageLoading />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </AppThemeProvider>
  </React.StrictMode>
);
