import { useNavigate } from 'react-router-dom';
import { Button, Line, PostbackTable, Title } from '@partnerka-front/components';
import { useTranslation } from 'react-i18next';

export function PostbacksPage() {
  const { t } = useTranslation(['postback']);
  const navigate = useNavigate();

  return (
    <>
      <Title>PostBack</Title>
      <Line>
        <Button variant="active">{t('postback.btn_my', { ns: 'postback' })}</Button>
        <Button variant="inactive" onClick={() => navigate('/postback/new')}>
          {t('postback.btn_new', { ns: 'postback' })}
        </Button>
        <Button variant="inactive" onClick={() => navigate('/postback/logs')}>
          {t('postback.btn_logs', { ns: 'postback' })}
        </Button>
      </Line>
      <PostbackTable />
    </>
  );
}
