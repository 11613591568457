import {
  Button,
  DateRangeSelector,
  Pagination,
  Price,
  Select,
  SkeletonTable,
  TableChildren,
  TableDescription,
  TableFilterButtons,
  TableItem,
  TableNoData,
  TableRow,
  TableSectionFilter,
  TableSorting,
  TableWrapper,
  Title,
  Container,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { useTranslation } from 'react-i18next';
import { usePaginated } from '@partnerka-front/utils';
import { SvgFolderPlus2 } from '@partnerka-front/icons';
import { GeneralStatisticsTableItem } from './item';
import { Currency, DateRange } from '@partnerka-front/types';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

const TitleDeals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 2px 10px;
  font-weight: 500;

  & > div {
    display: flex;
    gap: 5px;

    & > label {
      padding: 2px 7px;
      background-color: ${({ theme }) => theme.colors.darkBlue};
      font-size: 12px;
      line-height: 1.5;
      border-radius: 5px;

      &:last-child {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.headliner};
      }
    }
  }
`;

const SelectSkeleton = styled.div`
  width: 100%;
  height: 40px;

  // TODO: Skeleton - разобраться в чем прикол (ломается если стартовать с этой страницы)
  background: linear-gradient(110deg, #f2f4f8 8%, #fff 18%, #f2f4f8 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  border-radius: 15px;
`;

interface GeneralStatisticsTableProps {
  title: string;
  description?: string;
  isBusiness?: boolean;
}

export function GeneralStatisticsTable({ title, description }: GeneralStatisticsTableProps) {
  const { t } = useTranslation(['deals', 'errors', 'common']);

  const {
    firstLoading,
    pending,
    items: deals,
    fetchItems,
    itemsTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    pagesTotal,
    sumByStatus,
  } = usePaginated(
    (page, pageSize) =>
      Api.getGeneralStatisticsDeals(page, pageSize, {
        catalog: catalog === 'none' ? undefined : catalog,
        o: ordering,
        offer,
        modified_at_date_after: startDate,
        modified_at_date_before: endDate,
      }),
    `${t('errors.try_again_later', { ns: 'errors' })}`
  );

  const [ordering, setOrdering] = useState('-modified_at_date');

  const [catalog, setCatalog] = useState<string | undefined>(undefined);
  const [optionsCatalog, setOptionsCatalog] = useState<object | null>(null);
  const [offer, setOffer] = useState<string | undefined>(undefined);
  const [pendingOffers, setPendingOffers] = useState(false);
  const [optionsOffer, setOptionsOffer] = useState({
    none: `${t('common.select_offer', { ns: 'common' })}`,
  });

  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const dateFormat = 'yyyy-MM-dd';

  const getCatalogs = async () => {
    await Api.getCatalogUserWithDeals().then((res) => {
      const { data: catalogs } = res;
      const result = {
        none: `${t('common.select_catalog', { ns: 'common' })}`,
      };

      for (let i = 0; i < catalogs.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[catalogs[i].catalog] = catalogs[i].title;
      }

      setOptionsCatalog(result);
    });
  };

  const getOffers = async (idCatalog: string) => {
    setPendingOffers(true);

    await Api.getOfferUserWithDeals(idCatalog).then((res) => {
      const { data: offers } = res;
      const result = {
        none: `${t('common.select_offer', { ns: 'common' })}`,
      };

      for (let i = 0; i < offers.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[offers[i].offer] = offers[i].name;
      }

      setOptionsOffer(result);
    });

    setPendingOffers(false);
  };

  const clearFilter = () => {
    setDateRange(undefined);
    setCatalog('none');
  };

  const submitFilter = () => {
    page === 1 ? fetchItems() : setPage(1);
  };

  useEffect(() => {
    if (catalog && catalog !== 'none') {
      getOffers(catalog);
    } else {
      setOptionsOffer({ none: `${t('common.select_offer', { ns: 'common' })}` });
    }

    setOffer(undefined);
  }, [catalog]);

  useEffect(() => {
    if (dateRange) {
      setStartDate(format(dateRange[0], dateFormat));
      setEndDate(format(dateRange[1], dateFormat));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [dateRange]);

  useEffect(() => {
    if (firstLoading) {
      page === 1 ? fetchItems() : setPage(1);
    }
  }, [ordering]);

  useEffect(() => {
    getCatalogs();
  }, []);

  return (
    <>
      <Container>
        <Title>{title}</Title>

        {description && <TableDescription>{description}</TableDescription>}

        <TableSectionFilter>
          {optionsCatalog ? (
            <>
              <DateRangeSelector value={dateRange} setValue={setDateRange} />
              <Select
                options={optionsCatalog}
                value={catalog}
                onChange={(value) => {
                  let finalValue: string | undefined = value.toString();
                  if (value === 'none') finalValue = undefined;
                  setCatalog(finalValue);
                }}
              />
              <Select
                options={optionsOffer}
                value={offer}
                onChange={(value) => {
                  let finalValue: string | undefined = value.toString();
                  if (value === 'none') finalValue = undefined;
                  setOffer(finalValue);
                }}
                disabled={Object.keys(optionsOffer).length < 2 || pendingOffers}
              />
            </>
          ) : (
            <>
              <SelectSkeleton />
              <SelectSkeleton />
              <SelectSkeleton />
            </>
          )}
          <div />
          <div />
          <TableFilterButtons>
            {optionsCatalog ? (
              <>
                <Button
                  variant={'basic'}
                  maxWidth={'full'}
                  onClick={clearFilter}
                  disabled={
                    dateRange === undefined && (catalog === undefined || catalog === 'none')
                  }>
                  {t('common.clear_all', { ns: 'common' })}
                </Button>
                <Button variant={'accent'} maxWidth={'full'} onClick={submitFilter}>
                  {t('common.apply', { ns: 'common' })}
                </Button>
              </>
            ) : (
              <>
                <SelectSkeleton />
                <SelectSkeleton />
              </>
            )}
          </TableFilterButtons>
        </TableSectionFilter>
      </Container>

      <TableWrapper>
        <TableRow gridTemplateColumns={'100px 360px 360px 360px 360px 360px'}>
          <TableItem fontWeight={'500'}>
            <TableSorting
              onClick={() =>
                ordering === '-modified_at_date'
                  ? setOrdering('modified_at_date')
                  : setOrdering('-modified_at_date')
              }>
              {t('deals.date', { ns: 'deals' })}
            </TableSorting>
          </TableItem>
          <TableItem isChildren>
            <TitleDeals>
              {t('deals.all_deals', { ns: 'deals' })}:
              {sumByStatus && (
                <div>
                  <label>{sumByStatus.count_by_all_status}</label>
                  <label>
                    <Price amount={sumByStatus.sum_usd} currency={Currency.USD} />
                  </label>
                </div>
              )}
            </TitleDeals>
            <TableChildren gridTemplateColumns={'180px 180px'} noTopBorder>
              <TableItem height={22} backgroundColor={'dark-blue'} noRightBorder>
                USD
              </TableItem>
              <TableItem height={22} backgroundColor={'dark-blue'}>
                RUB
              </TableItem>
            </TableChildren>
            <TableChildren gridTemplateColumns={'80px 100px 80px 100px'} noTopBorder>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_usd' ? setOrdering('count_usd') : setOrdering('-count_usd')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_deals_usd'
                      ? setOrdering('sum_deals_usd')
                      : setOrdering('-sum_deals_usd')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_rub' ? setOrdering('count_rub') : setOrdering('-count_rub')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_deals_rub'
                      ? setOrdering('sum_deals_rub')
                      : setOrdering('-sum_deals_rub')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>
          <TableItem isChildren>
            <TitleDeals>
              {t('deals.approved_deals', { ns: 'deals' })}:
              {sumByStatus && (
                <div>
                  <label>{sumByStatus.count_by_approved}</label>
                  <label>
                    <Price amount={sumByStatus.sum_deal_approved} currency={Currency.USD} />
                  </label>
                </div>
              )}
            </TitleDeals>
            <TableChildren gridTemplateColumns={'180px 180px'} noTopBorder>
              <TableItem height={22} backgroundColor={'dark-blue'} noRightBorder>
                USD
              </TableItem>
              <TableItem height={22} backgroundColor={'dark-blue'}>
                RUB
              </TableItem>
            </TableChildren>
            <TableChildren gridTemplateColumns={'80px 100px 80px 100px'} noTopBorder>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_approved_usd'
                      ? setOrdering('count_by_approved_usd')
                      : setOrdering('-count_by_approved_usd')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_approved_usd'
                      ? setOrdering('sum_by_approved_usd')
                      : setOrdering('-sum_by_approved_usd')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_approved_rub'
                      ? setOrdering('count_by_approved_rub')
                      : setOrdering('-count_by_approved_rub')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_approved_rub'
                      ? setOrdering('sum_by_approved_rub')
                      : setOrdering('-sum_by_approved_rub')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>
          <TableItem isChildren>
            <TitleDeals>
              {t('deals.deals_hold_st', { ns: 'deals' })}
              {sumByStatus && (
                <div>
                  <label>{sumByStatus.count_by_paid}</label>
                  <label>
                    <Price amount={sumByStatus.sum_deal_paid} currency={Currency.USD} />
                  </label>
                </div>
              )}
            </TitleDeals>
            <TableChildren gridTemplateColumns={'180px 180px'} noTopBorder>
              <TableItem height={22} backgroundColor={'dark-blue'} noRightBorder>
                USD
              </TableItem>
              <TableItem height={22} backgroundColor={'dark-blue'}>
                RUB
              </TableItem>
            </TableChildren>
            <TableChildren gridTemplateColumns={'80px 100px 80px 100px'} noTopBorder>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_paid_usd'
                      ? setOrdering('count_by_paid_usd')
                      : setOrdering('-count_by_paid_usd')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_paid_usd'
                      ? setOrdering('sum_by_paid_usd')
                      : setOrdering('-sum_by_paid_usd')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_paid_rub'
                      ? setOrdering('count_by_paid_rub')
                      : setOrdering('-count_by_paid_rub')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_paid_rub'
                      ? setOrdering('sum_by_paid_rub')
                      : setOrdering('-sum_by_paid_rub')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>
          <TableItem isChildren>
            <TitleDeals>
              {t('deals.created_deals', { ns: 'deals' })}
              {sumByStatus && (
                <div>
                  <label>{sumByStatus.count_by_created}</label>
                  <label>
                    <Price amount={sumByStatus.sum_deal_created} currency={Currency.USD} />
                  </label>
                </div>
              )}
            </TitleDeals>
            <TableChildren gridTemplateColumns={'180px 180px'} noTopBorder>
              <TableItem height={22} backgroundColor={'dark-blue'} noRightBorder>
                USD
              </TableItem>
              <TableItem height={22} backgroundColor={'dark-blue'}>
                RUB
              </TableItem>
            </TableChildren>
            <TableChildren gridTemplateColumns={'80px 100px 80px 100px'} noTopBorder>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_created_usd'
                      ? setOrdering('count_by_created_usd')
                      : setOrdering('-count_by_created_usd')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_created_usd'
                      ? setOrdering('sum_by_created_usd')
                      : setOrdering('-sum_by_created_usd')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_created_rub'
                      ? setOrdering('count_by_created_rub')
                      : setOrdering('-count_by_created_rub')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_created_rub'
                      ? setOrdering('sum_by_created_rub')
                      : setOrdering('-sum_by_created_rub')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>
          <TableItem isChildren>
            <TitleDeals>
              {t('deals.closed_deals', { ns: 'deals' })}
              {sumByStatus && (
                <div>
                  <label>{sumByStatus.count_by_canceled}</label>
                  <label>
                    <Price amount={sumByStatus.sum_deal_canceled} currency={Currency.USD} />
                  </label>
                </div>
              )}
            </TitleDeals>
            <TableChildren gridTemplateColumns={'180px 180px'} noTopBorder>
              <TableItem height={22} backgroundColor={'dark-blue'} noRightBorder>
                USD
              </TableItem>
              <TableItem height={22} backgroundColor={'dark-blue'}>
                RUB
              </TableItem>
            </TableChildren>
            <TableChildren gridTemplateColumns={'80px 100px 80px 100px'} noTopBorder>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_canceled_usd'
                      ? setOrdering('count_by_canceled_usd')
                      : setOrdering('-count_by_canceled_usd')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_canceled_usd'
                      ? setOrdering('sum_by_canceled_usd')
                      : setOrdering('-sum_by_canceled_usd')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem noRightBorder>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_canceled_rub'
                      ? setOrdering('count_by_canceled_rub')
                      : setOrdering('-count_by_canceled_rub')
                  }>
                  {t('deals.deals', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_by_canceled_rub'
                      ? setOrdering('sum_by_canceled_rub')
                      : setOrdering('-sum_by_canceled_rub')
                  }>
                  {t('deals.amount', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>
        </TableRow>
        {pending ? (
          <SkeletonTable
            gridTemplateColumns={
              '100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px'
            }
          />
        ) : (
          <>
            {deals.length > 0 ? (
              deals.map((deal) => (
                <GeneralStatisticsTableItem key={deal.modified_at_date} deal={deal} />
              ))
            ) : (
              <TableNoData
                icon={<SvgFolderPlus2 />}
                title={`${t('deals.no_data', { ns: 'deals' })}`}
                // description={isBusiness ? 'Нажмите на кнопку и создайте свою первую сделку' : ''}
                // btn={
                //   <>
                //     {isBusiness && (
                //       <Button variant={'accent'} onClick={() => navigate('/deals/create')}>
                //         Создать сделку
                //       </Button>
                //     )}
                //   </>
                // }
              />
            )}
          </>
        )}
      </TableWrapper>
      {deals && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
