import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { Burger } from '../UI/burger';
import { NavFooter, NavItems, NavLang, NavLogin, NavLogout, NavUser } from './nav';
import {
  SvgBarChartNew,
  SvgHome,
  SvgManager,
  SvgOffer,
  SvgTools,
  SvgWalletNew,
} from '@partnerka-front/icons';
import { useTranslation } from 'react-i18next';
import { useUser } from '@partnerka-front/utils';

interface MenuStyleProps {
  isOpen: boolean;
}

const MenuNavWrapper = styled.div<MenuStyleProps>`
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 67, 78, 0.78);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: 0.3s;
`;

const MenuWrapper = styled.nav<MenuStyleProps>`
  position: relative;
  right: ${({ isOpen }) => (isOpen ? 0 : '-210px')};
  width: 210px;
  height: 100%;
  padding: 60px 20px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.3s;
`;

const MenuNavContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

interface MenuNavProps {
  isMenu: boolean;
  setMenu: (value: boolean) => void;
}

export function MenuNav({ setMenu, isMenu }: MenuNavProps) {
  const { t, i18n } = useTranslation(['common']);
  const { user, isAuth, logout: logoutUser } = useUser();

  const logout = async () => {
    await logoutUser();
    setMenu(false);
  };

  const handlerLink = () => {
    setMenu(false);
  };

  return (
    <MenuNavWrapper onClick={() => setMenu(false)} isOpen={isMenu}>
      <MenuWrapper onClick={(e) => e.stopPropagation()} isOpen={isMenu}>
        <Burger open={isMenu} setOpen={setMenu} />
        <MenuNavContent>
          <NavItems>
            <NavLink to="/" onClick={handlerLink}>
              <SvgHome />
              {t('common.nav.main', { ns: 'common' })}
            </NavLink>
            <NavLink to="/offers" onClick={handlerLink}>
              <SvgOffer />
              {t('common.nav.offers', { ns: 'common' })}
            </NavLink>
            <NavLink to="/statistics" onClick={handlerLink}>
              <SvgBarChartNew />
              {t('common.nav.statistics', { ns: 'common' })}
            </NavLink>
            <NavLink to="/postback" onClick={handlerLink}>
              <SvgTools />
              PostBack
            </NavLink>
            <NavLink to="/finance" onClick={handlerLink}>
              <SvgWalletNew />
              {t('common.nav.finance', { ns: 'common' })}
            </NavLink>
            <NavLink to="/support" onClick={handlerLink}>
              <SvgManager />
              {t('common.nav.support', { ns: 'common' })}
            </NavLink>
          </NavItems>

          <NavFooter>
            <NavLang>
              <span
                className={i18n.language === 'ru' ? 'active' : ''}
                onClick={() => i18n.changeLanguage('ru')}>
                RU
              </span>
              {' | '}
              <span
                className={i18n.language === 'en' ? 'active' : ''}
                onClick={() => i18n.changeLanguage('en')}>
                EN
              </span>
            </NavLang>
            {user && (
              <>
                <NavUser>ID: {user.id}</NavUser>
                <NavLogin>{user.email}</NavLogin>
              </>
            )}

            {isAuth && (
              <NavLogout onClick={() => logout()}>{t('common.logout', { ns: 'common' })}</NavLogout>
            )}
          </NavFooter>
        </MenuNavContent>
      </MenuWrapper>
    </MenuNavWrapper>
  );
}
