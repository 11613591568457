import React, { createContext, useContext } from 'react';
import { Api, API_URL } from '@partnerka-front/api';
import { LoginPayload, RefreshDto, ResponseError, UserDto } from '@partnerka-front/types';
import axios from 'axios';
import { toast } from 'react-toast';

interface UserContextPayload {
  isAuth: boolean;
  setIsAuth?: React.Dispatch<React.SetStateAction<boolean>>;
  user?: UserDto;
  setUser?: (value: UserDto) => void;
}

const UserContext = createContext<UserContextPayload>({ isAuth: false });
export const UserProvider = UserContext.Provider;

export const useUser = () => {
  const { isAuth, setIsAuth, user, setUser } = useContext(UserContext) as UserContextPayload;

  const fetchUser = async () => {
    if (setIsAuth && setUser) {
      await Api.getUser()
        .then((res) => {
          setIsAuth(true);
          setUser(res.data);
        })
        .catch((e) => toast.error(`Error: ${e.response.status}`));
    }
  };

  const login = async (payload: LoginPayload, setError: any) => {
    try {
      const response = await Api.login(payload);
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);

      await fetchUser();
    } catch (e) {
      setError(e);
    }
  };

  const logout = async (firstAttempt = true) => {
    const refresh = localStorage.getItem('refresh');

    if (refresh) {
      await axios
        .post(
          `${API_URL}/auth/jwt/logout/`,
          { refresh },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(() => {
          localStorage.removeItem('refresh');
          localStorage.removeItem('token');
          if (setIsAuth) {
            setIsAuth(false);
          }
          if (setUser) {
            setUser({} as UserDto);
          }
        })
        .catch(async (e: ResponseError) => {
          if (e.response.status === 401 && firstAttempt) {
            await axios
              .post<RefreshDto>(`${API_URL}/auth/jwt/refresh/`, {
                refresh,
              })
              .then((res) => {
                localStorage.setItem('token', res.data.access);
                logout((firstAttempt = false));
              })
              .catch((e: ResponseError) => {
                if (e.response.status === 401) {
                  localStorage.removeItem('refresh');
                  localStorage.removeItem('token');
                  if (setIsAuth) setIsAuth(false);
                  if (setUser) setUser({} as UserDto);
                } else {
                  toast.error(`Error: ${e.response.status}`);
                }
              });
            return;
          }

          if (e.response.status === 400) {
            localStorage.removeItem('refresh');
            localStorage.removeItem('token');
            if (setIsAuth) setIsAuth(false);
            if (setUser) setUser({} as UserDto);
            return;
          }

          toast.error(`Error: ${e.response.status}`);
        });
    } else {
      if (setIsAuth) setIsAuth(false);
      if (setUser) setUser({} as UserDto);
    }
  };

  return { isAuth, user, fetchUser, login, logout };
};
