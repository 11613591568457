import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSubmit,
  Card,
  Message,
  ModalLoading,
  Textarea,
  TitleH4,
} from '@partnerka-front/components';
import { ResponseError } from '@partnerka-front/types';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';

enum StatusSteps {
  start = 'start',
  success = 'success',
  error = 'error',
}

const PostbackSaveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PostbackSaveBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
`;

const CardContent = styled.div`
  h4 {
    margin-bottom: 20px;
  }

  textarea {
    margin-bottom: 20px;
  }
`;

const RequestResponse = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 16px;
  font-size: 12px;

  b {
    font-weight: 500;
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
  }

  & > div {
    display: flex;
    gap: 16px;
  }

  p {
    display: flex;
    gap: 4px;
  }
`;

const Success = styled.div`
  color: ${({ theme }) => theme.colors.positive};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.colors.negative};
`;

interface PostbackSaveProps {
  onSave: React.MouseEventHandler<HTMLButtonElement>;
  error: ResponseError | null;
  setError: (value: null) => void;
  urlParameters?: string;
  url?: string;
}

export function PostbackSave({ onSave, error, setError, url, urlParameters }: PostbackSaveProps) {
  const { t } = useTranslation(['postback', 'testing']);

  const [isPending, setIsPending] = useState(false);
  const [step, setStep] = useState(StatusSteps.start);
  const [status, setStatus] = useState('---');

  const testUrl = async (url: string) => {
    setIsPending(true);

    await Api.testPostback(url)
      .then((res) => {
        setStatus(`${res.status}`);
        setStep(StatusSteps.success);
      })
      .catch((err: ResponseError) => {
        if (err.response.status) {
          setStatus(`${err.response.status}`);
        } else {
          setStatus(`---`);
        }
        setStep(StatusSteps.error);
      });

    setIsPending(false);
  };

  return (
    <PostbackSaveWrapper>
      <ButtonSubmit maxWidth={'full'} onClick={onSave} disabled={!urlParameters}>
        {t('postback.btn_save', { ns: 'postback' })}
      </ButtonSubmit>

      {!urlParameters && <Message>{t('postback.error_url_params', { ns: 'postback' })}</Message>}

      {error?.response.data && (
        <>
          {error?.response.data.postback_field ? (
            <Message onClose={() => setError(null)}>
              {t('postback.error_fields', { ns: 'postback' })}
            </Message>
          ) : (
            <Message onClose={() => setError(null)}>
              {error.response.data.detail
                ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                : t('errors.try_again_later', { ns: 'errors' })}
            </Message>
          )}
        </>
      )}

      <Card>
        <CardContent>
          <TitleH4>{t('postback.test_title', { ns: 'postback' })}</TitleH4>
          <Textarea value={`${url}${url && urlParameters}`} height={160} />

          <RequestResponse>
            <p>
              <span>{t('postback.type', { ns: 'postback' })}:</span>
              get
            </p>
            <p>
              <span>{t('postback.reply', { ns: 'postback' })}:</span>
              {step === StatusSteps.start && <>---</>}
              {step === StatusSteps.success && (
                <Success>{t('testing.success', { ns: 'testing' })}</Success>
              )}
              {step === StatusSteps.error && <Error>{t('testing.error', { ns: 'testing' })}</Error>}
            </p>
            <p>
              <span>{t('postback.status', { ns: 'postback' })}:</span>
              {step === StatusSteps.start && status}
              {step === StatusSteps.success && <Success>{status}</Success>}
              {step === StatusSteps.error && <Error>{status}</Error>}
            </p>
          </RequestResponse>

          <PostbackSaveBtn>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                await testUrl(`${url}${url && urlParameters}`);
              }}
              variant={'active'}
              disabled={!url || !urlParameters}>
              {t('postback.test_btn', { ns: 'postback' })}
            </Button>
          </PostbackSaveBtn>

          {isPending && <ModalLoading />}
        </CardContent>
      </Card>
    </PostbackSaveWrapper>
  );
}
