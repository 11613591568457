import { Card } from '../card';
import { CatalogDto } from '@partnerka-front/types';
import styled from 'styled-components';
import { SvgVerified, SvgWw } from '@partnerka-front/icons';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '@partnerka-front/components';
import { languageLabel } from '@partnerka-front/utils';

const Header = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 25px;

  svg {
    min-width: 40px;
  }
`;

const HeaderInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 4px 10px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
`;

const Item = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const CountriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  span {
    width: 30px;
    height: 20px;
    border-radius: 4px;
    background-size: cover;
    border: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  }
`;

const LastUpdate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const WW = styled.span`
  svg {
    width: 30px;
    height: 20px;
    border-radius: 4px;
  }
`;

interface CatalogInfoProps {
  catalog: CatalogDto;
}

export function CatalogInfo({ catalog }: CatalogInfoProps) {
  const { t } = useTranslation(['catalog', 'common']);

  return (
    <Card>
      <Header>
        <HeaderInput>
          <label>AR:</label>
          <span>
            {typeof catalog.approval_rate === 'number'
              ? `${catalog.approval_rate.toFixed(2)}%`
              : `${catalog.approval_rate}`}
          </span>
        </HeaderInput>
        <HeaderInput>
          <label>CR:</label>
          <span>N/A</span>
        </HeaderInput>
        <SvgVerified />
      </Header>
      <Item>
        <label>{t('catalog.geotargeting', { ns: 'catalog' })}</label>
        <CountriesWrapper>
          {catalog.offer_geo ? (
            catalog.offer_geo.map((geo) =>
              geo === 'ww' ? (
                <WW key={geo}>
                  <SvgWw />
                </WW>
              ) : (
                <span key={geo} className={`fi fi-${geo}`} />
              )
            )
          ) : (
            <SvgWw />
          )}
        </CountriesWrapper>
      </Item>
      <Item>
        <label>{t('catalog.localization', { ns: 'catalog' })}</label>
        <span>
          {catalog.localizations ? (
            <>
              {catalog.localizations.map((local, i) => {
                // TODO: разобраться в чем прикол
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (catalog.localizations.length === i + 1)
                  return <span key={i}>{languageLabel(local)}</span>;
                return (
                  <span key={i}>
                    {languageLabel(local)}
                    {`, `}
                  </span>
                );
              })}
            </>
          ) : (
            '-'
          )}
        </span>
      </Item>
      <Item>
        <label>{t('catalog.restrictions_devices', { ns: 'catalog' })}</label>
        <span>
          {catalog.devices === 'All'
            ? `${t('common.no_restrictions', { ns: 'common' })}`
            : catalog.devices}
        </span>
      </Item>
      <Item>
        <label>{t('catalog.product_category', { ns: 'catalog' })}</label>
        <span>
          {catalog.category ? t(`catalog.category.${catalog.category}`, { ns: 'catalog' }) : '-'}
        </span>
      </Item>
      <LastUpdate>
        <label>{t('catalog.last_update', { ns: 'catalog' })}:</label>
        <span>
          <DateFormat date={catalog.modified_at} />
        </span>
      </LastUpdate>
    </Card>
  );
}
