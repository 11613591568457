import { Button, CardDropdownInCard, Checkbox } from '@partnerka-front/components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PostbackFieldDto, PostbackFieldState, PostbackTemplatesDto } from '@partnerka-front/types';
import {
  generateUrlWithFields,
  isMainKey,
  isSubIdKey,
  isSystemsKey,
  isTagsKey,
} from '@partnerka-front/utils';
import { SvgTrash } from '@partnerka-front/icons';
import { useTranslation } from 'react-i18next';

const PostbackField = styled.div`
  display: grid;
  grid-template-columns: 16px 140px 12px 140px 1fr;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionButton = styled.div`
  margin-top: 24px;
`;

const SectionDelete = styled.div`
  width: fit-content;
  font-size: 0;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.negative};
    }
  }
`;

const InputValue = styled.input`
  width: 100%;
  height: 32px;
  padding: 4px 10px;
  border: 1px solid #ced2d6;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  color: ${({ theme }) => theme.colors.headliner};

  &:disabled {
    background-color: transparent;
    border: 1px solid #f2f2f2;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const InputKey = styled(InputValue)`
  background-color: #f2f2f2;

  &:disabled {
    background-color: #f2f2f2;
  }
`;

interface PostbackFieldsProps {
  watch: any;
  templates: PostbackTemplatesDto[] | null;
  setFields: any;
  setUrlParameters: any;
  postback_field?: PostbackFieldDto[];
}

export function PostbackFields({
  watch,
  templates,
  setFields,
  setUrlParameters,
  postback_field,
}: PostbackFieldsProps) {
  const { t } = useTranslation();
  const defaultPostbackField = postback_field ?? [{ key: 'subid', value: 'click_id' }];
  const [isFirstTemplate, setIsFirstTemplate] = useState(false);

  const [mainUrl, setMainUrl] = useState('');
  const [mainFields, setMainFields] = useState<object[]>([]);
  const [main, setMain] = useState<PostbackFieldState[]>([]);

  const [systemsUrl, setSystemsUrl] = useState('');
  const [systemsFields, setSystemsFields] = useState<object[]>([]);
  const [systems, setSystems] = useState<PostbackFieldState[]>([]);

  const [subIdsUrl, setSubIdsUrl] = useState('');
  const [subIdsFields, setSubIdsFields] = useState<object[]>([]);
  const [subIds, setSubIds] = useState<PostbackFieldState[]>([]);

  const [tagsUrl, setTagsUrl] = useState('');
  const [tagsFields, setTagsFields] = useState<object[]>([]);
  const [tags, setTags] = useState<PostbackFieldState[]>([]);

  const [customUrl, setCustomUrl] = useState('');
  const [customFields, setCustomFields] = useState<object[]>([]);
  const [custom, setCustom] = useState<PostbackFieldState[]>([]);

  // ~~ Functions

  const forPostbackField = (postback_fields: PostbackFieldDto[]) => {
    const postbackFields = [...postback_fields];

    if (postbackFields.length === 0) return;

    const mainDefault: PostbackFieldState[] = [
      { key: 'subid', value: 'click_id', disabled: false, title: 'SubID' },
      {
        key: 'status',
        value: 'status',
        disabled: false,
        title: `${t('postback.field_status', { ns: 'postback' })}`,
      },
      {
        key: 'payment_sum',
        value: 'payment',
        disabled: false,
        title: `${t('postback.field_payment', { ns: 'postback' })}`,
      },
      {
        key: 'cost',
        value: 'cost',
        disabled: false,
        title: `${t('postback.field_cost', { ns: 'postback' })}`,
      },
      {
        key: 'currency',
        value: 'currency',
        disabled: false,
        title: `${t('postback.field_currency', { ns: 'postback' })}`,
      },
    ];
    const mainNew: PostbackFieldState[] = [];

    const systemsDefault: PostbackFieldState[] = [
      {
        key: 'uid',
        value: 'uid',
        disabled: false,
        title: `${t('postback.field_uid', { ns: 'postback' })}`,
      },
      {
        key: 'cid',
        value: 'cid',
        disabled: false,
        title: `${t('postback.field_cid', { ns: 'postback' })}`,
      },
      {
        key: 'lpid',
        value: 'lpid',
        disabled: false,
        title: `${t('postback.field_lpid', { ns: 'postback' })}`,
      },
      {
        key: 'action_id',
        value: 'action_id',
        disabled: false,
        title: `${t('postback.field_action_id', { ns: 'postback' })}`,
      },
      {
        key: 'deal_id',
        value: 'deal_id',
        disabled: false,
        title: `${t('postback.field_deal_id', { ns: 'postback' })}`,
      },
      {
        key: 'myshare',
        value: 'myshare',
        disabled: false,
        title: `${t('postback.field_myshare', { ns: 'postback' })}`,
      },
      {
        key: 'country',
        value: 'country',
        disabled: false,
        title: `${t('postback.field_country', { ns: 'postback' })}`,
      },
      {
        key: 'browser',
        value: 'browser',
        disabled: false,
        title: `${t('postback.field_browser', { ns: 'postback' })}`,
      },
      {
        key: 'os',
        value: 'os',
        disabled: false,
        title: `${t('postback.field_os', { ns: 'postback' })}`,
      },
    ];
    const systemsNew: PostbackFieldState[] = [];

    const subIdsDefault: PostbackFieldState[] = [
      { key: 'subid1', value: 'subid1', disabled: false },
      { key: 'subid2', value: 'subid2', disabled: false },
      { key: 'subid3', value: 'subid3', disabled: false },
      { key: 'subid4', value: 'subid4', disabled: false },
      { key: 'subid5', value: 'subid5', disabled: false },
      { key: 'subid6', value: 'subid6', disabled: false },
      { key: 'subid7', value: 'subid7', disabled: false },
      { key: 'subid8', value: 'subid8', disabled: false },
      { key: 'subid9', value: 'subid9', disabled: false },
      { key: 'subid10', value: 'subid10', disabled: false },
      { key: 'subid11', value: 'subid11', disabled: false },
      { key: 'subid12', value: 'subid12', disabled: false },
      { key: 'subid13', value: 'subid13', disabled: false },
      { key: 'subid14', value: 'subid14', disabled: false },
      { key: 'subid15', value: 'subid15', disabled: false },
      { key: 'subid16', value: 'subid16', disabled: false },
      { key: 'subid17', value: 'subid17', disabled: false },
      { key: 'subid18', value: 'subid18', disabled: false },
    ];
    const subIdNew: PostbackFieldState[] = [];

    const tagsDefault: PostbackFieldState[] = [
      { key: 'utm_source', value: 'utm_source', disabled: false },
      { key: 'utm_medium', value: 'utm_medium', disabled: false },
      { key: 'utm_campaign', value: 'utm_campaign', disabled: false },
      { key: 'utm_content', value: 'utm_content', disabled: false },
      { key: 'utm_term', value: 'utm_term', disabled: false },
    ];
    const tagsNew: PostbackFieldState[] = [];

    // ~~ проход по шаблону
    for (let i = 0; i < postbackFields.length; i++) {
      const value = postbackFields[i];

      if (isMainKey(value.key)) {
        mainNew.push({ key: value.key, value: value.value, disabled: true });
        postbackFields.splice(i, 1); // удаление элемента по индексу i
        i--; // корректировка переменной-счетчика i для правильной итерации массива
      }

      if (isSystemsKey(value.key)) {
        systemsNew.push({ key: value.key, value: value.value, disabled: true });
        postbackFields.splice(i, 1);
        i--;
      }

      if (isSubIdKey(value.key)) {
        subIdNew.push({ key: value.key, value: value.value, disabled: true });
        postbackFields.splice(i, 1);
        i--;
      }

      if (isTagsKey(value.key)) {
        tagsNew.push({ key: value.key, value: value.value, disabled: true });
        postbackFields.splice(i, 1);
        i--;
      }
    }

    // создаю массивы с изменениями
    // TODO: вынести в отдельную функцию
    const newMain = mainDefault.map((obj) => {
      const newObj = mainNew.find((newObj) => newObj.key === obj.key);
      if (newObj) {
        return { ...obj, ...newObj };
      } else {
        return obj;
      }
    });

    const newSystems = systemsDefault.map((obj) => {
      const newObj = systemsNew.find((newObj) => newObj.key === obj.key);
      if (newObj) {
        return { ...obj, ...newObj };
      } else {
        return obj;
      }
    });

    const newSubIds = subIdsDefault.map((obj) => {
      const newObj = subIdNew.find((newObj) => newObj.key === obj.key);
      if (newObj) {
        return { ...obj, ...newObj };
      } else {
        return obj;
      }
    });

    const newTags = tagsDefault.map((obj) => {
      const newObj = tagsNew.find((newObj) => newObj.key === obj.key);
      if (newObj) {
        return { ...obj, ...newObj };
      } else {
        return obj;
      }
    });

    // те что остались, в "Свои параметры и значения"
    const customField: PostbackFieldState[] = [];
    if (postbackFields.length > 0) {
      for (let i = 0; i < postbackFields.length; i++) {
        const field = postbackFields[i];
        customField.push({ disabled: true, key: field.key, value: field.value });
        postbackFields.splice(i, 1);
        i--;
      }
    }

    setMain(newMain);
    setSystems(newSystems);
    setSubIds(newSubIds);
    setTags(newTags);
    setCustom(customField);

    if (!isFirstTemplate) setIsFirstTemplate(true);
  };

  // ~~ UseEffects

  useEffect(() => {
    if (defaultPostbackField.length > 0) forPostbackField(defaultPostbackField);
  }, []);

  useEffect(() => {
    const { urlParameters, fieldsParameters } = generateUrlWithFields(main);

    setMainUrl(urlParameters);
    setMainFields(fieldsParameters);
  }, [main]);

  useEffect(() => {
    const { urlParameters, fieldsParameters } = generateUrlWithFields(systems);

    setSystemsUrl(urlParameters);
    setSystemsFields(fieldsParameters);
  }, [systems]);

  useEffect(() => {
    const { urlParameters, fieldsParameters } = generateUrlWithFields(subIds);

    setSubIdsUrl(urlParameters);
    setSubIdsFields(fieldsParameters);
  }, [subIds]);

  useEffect(() => {
    const { urlParameters, fieldsParameters } = generateUrlWithFields(tags);

    setTagsUrl(urlParameters);
    setTagsFields(fieldsParameters);
  }, [tags]);

  useEffect(() => {
    const { urlParameters, fieldsParameters } = generateUrlWithFields(custom);

    setCustomUrl(urlParameters);
    setCustomFields(fieldsParameters);
  }, [custom]);

  useEffect(() => {
    let url = '';
    const fields = [
      ...mainFields,
      ...systemsFields,
      ...subIdsFields,
      ...tagsFields,
      ...customFields,
    ];

    if (url) {
      if (mainUrl) url = url + `&${mainUrl}`;
    } else {
      if (mainUrl) url = `?${mainUrl}`;
    }

    if (url) {
      if (systemsUrl) url = url + `&${systemsUrl}`;
    } else {
      if (systemsUrl) url = `?${systemsUrl}`;
    }

    if (url) {
      if (subIdsUrl) url = url + `&${subIdsUrl}`;
    } else {
      if (subIdsUrl) url = `?${subIdsUrl}`;
    }

    if (url) {
      if (tagsUrl) url = url + `&${tagsUrl}`;
    } else {
      if (tagsUrl) url = `?${tagsUrl}`;
    }

    if (url) {
      if (customUrl) url = url + `&${customUrl}`;
    } else {
      if (customUrl) url = `?${customUrl}`;
    }

    if (isFirstTemplate) {
      setFields(fields);
      setUrlParameters(url);
    }
  }, [
    mainUrl,
    mainFields,
    systemsFields,
    systemsUrl,
    subIdsFields,
    subIdsUrl,
    tagsUrl,
    tagsFields,
    customUrl,
    customFields,
    isFirstTemplate,
  ]);

  // ~~ Шаблоны

  const [initTemplate, setInitTemplate] = useState<string | null>(null);
  const changeTemplate = (id: string) => {
    const template = templates?.find((item) => item.id === id);

    if (template) {
      const fields = template.template_fields;
      forPostbackField(fields);
    }
  };

  useEffect(() => {
    const value: string = watch('postback_template');

    if (!initTemplate) {
      setInitTemplate(value);
    } else {
      if (initTemplate !== value) {
        if (value !== 'none') {
          changeTemplate(value);
        }
        setInitTemplate(value);
      }
    }
  }, [watch('postback_template')]);

  return (
    <>
      <CardDropdownInCard title={t('postback.fields_main_title', { ns: 'postback' })}>
        {main.map((item, index) => (
          <PostbackField key={item.key}>
            <Checkbox
              value={item.disabled}
              onChange={(e) => {
                const newListFields = [...main];
                newListFields[index].disabled = e;
                setMain(newListFields);
              }}
            />
            <InputValue
              value={item.value}
              onChange={(e) => {
                const newListFields = [...main];
                newListFields[index].value = e.target.value;
                setMain(newListFields);
              }}
              disabled={!item.disabled}
            />
            =
            <InputKey value={`{${item.key}}`} disabled />
            {item.title}
          </PostbackField>
        ))}
      </CardDropdownInCard>

      <CardDropdownInCard title={t('postback.field_system_title', { ns: 'postback' })}>
        {systems.map((item, index) => (
          <PostbackField key={item.key}>
            <Checkbox
              value={item.disabled}
              onChange={(e) => {
                const newListFields = [...systems];
                newListFields[index].disabled = e;
                setSystems(newListFields);
              }}
            />
            <InputValue
              value={item.value}
              onChange={(e) => {
                const newListFields = [...systems];
                newListFields[index].value = e.target.value;
                setSystems(newListFields);
              }}
              disabled={!item.disabled}
            />
            =
            <InputKey value={`{${item.key}}`} disabled />
            {item.title}
          </PostbackField>
        ))}
      </CardDropdownInCard>

      <CardDropdownInCard title={t('postback.field_sub_id_title', { ns: 'postback' })}>
        {subIds.map((item, index) => (
          <PostbackField key={item.key}>
            <Checkbox
              value={item.disabled}
              onChange={(e) => {
                const newListFields = [...subIds];
                newListFields[index].disabled = e;
                setSubIds(newListFields);
              }}
            />
            <InputValue
              value={item.value}
              onChange={(e) => {
                const newListFields = [...subIds];
                newListFields[index].value = e.target.value;
                setSubIds(newListFields);
              }}
              disabled={!item.disabled}
            />
            =
            <InputKey value={`{${item.key}}`} disabled />
            {item.title}
          </PostbackField>
        ))}
      </CardDropdownInCard>

      <CardDropdownInCard title={t('postback.field_utm_title', { ns: 'postback' })}>
        {tags.map((item, index) => (
          <PostbackField key={item.key}>
            <Checkbox
              value={item.disabled}
              onChange={(e) => {
                const newListFields = [...tags];
                newListFields[index].disabled = e;
                setTags(newListFields);
              }}
            />
            <InputValue
              value={item.value}
              onChange={(e) => {
                const newListFields = [...tags];
                newListFields[index].value = e.target.value;
                setTags(newListFields);
              }}
              disabled={!item.disabled}
            />
            =
            <InputKey value={`{${item.key}}`} disabled />
          </PostbackField>
        ))}
      </CardDropdownInCard>

      <CardDropdownInCard title={t('postback.field_custom_title', { ns: 'postback' })}>
        {custom.map((item, index) => (
          <PostbackField key={index}>
            <Checkbox
              value={item.disabled}
              onChange={(e) => {
                const newListFields = [...custom];
                newListFields[index].disabled = e;
                setCustom(newListFields);
              }}
            />
            <InputValue
              value={item.value}
              onChange={(e) => {
                const newListFields = [...custom];
                newListFields[index].value = e.target.value;
                setCustom(newListFields);
              }}
              disabled={!item.disabled}
            />
            =
            <InputKey
              value={item.key}
              onChange={(e) => {
                const newListFields = [...custom];
                newListFields[index].key = e.target.value;
                setCustom(newListFields);
              }}
              disabled={!item.disabled}
            />
            <SectionDelete
              onClick={() => {
                setCustom((prevState) => prevState.filter((field) => field.key !== item.key));
              }}>
              <SvgTrash />
            </SectionDelete>
          </PostbackField>
        ))}
        {custom.length < 5 && (
          <SectionButton>
            <Button
              variant={'basic'}
              onClick={(e) => {
                e.preventDefault();
                setCustom((prevState) => [
                  ...prevState,
                  {
                    value: `custom${custom.length + 1}`,
                    key: `custom${custom.length + 1}`,
                    disabled: true,
                  },
                ]);
              }}>
              {t('postback.btn_add_custom', { ns: 'postback' })}
            </Button>
          </SectionButton>
        )}
      </CardDropdownInCard>
    </>
  );
}
