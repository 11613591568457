import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '@partnerka-front/theme/src/globalStyles';
import { lightTheme } from '@partnerka-front/theme';

interface AppThemeProviderProps {
  children: ReactNode;
}

export const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
