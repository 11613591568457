import languages from './jsons/iso639-1.json';

export const languagesList = languages;

export function languageLabel(code: string) {
  const CodeLowerCase = code.toLowerCase();
  const language = languages.find((item) => item.code === CodeLowerCase);

  if (language) return language.label;
  return code;
}
