import { useNavigate } from 'react-router-dom';
import { CatalogDto } from '@partnerka-front/types';
import styled from 'styled-components';
import { Button, Preview, PreviewPartner, RemunerationPercent } from '@partnerka-front/components';
import { down, only } from 'styled-breakpoints';
import 'flag-icons/css/flag-icons.min.css';
import { useTranslation } from 'react-i18next';
import { SvgWw } from '@partnerka-front/icons';
import { handleRewardType } from '@partnerka-front/utils';

const CatalogWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px 20px;
  border-radius: 16px;
`;

const CatalogContent = styled.div`
  margin-bottom: 10px;
`;

const CatalogTitle = styled.h3`
  position: absolute;
  left: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 100%;
  margin: 12px 0 0;
  padding: 0 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
`;

const CatalogDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  margin-top: 42px;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.8;
`;

const CatalogAwardWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.tableGrey};
  border-radius: 10px;

  ${only('md')} {
    flex-direction: column;
  }
`;

const CatalogAwardBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  ${only('md')} {
    padding-left: 0;
  }

  &:first-child {
    width: 80px;
    min-width: 80px;
    padding-right: 10px;
    padding-left: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.white};

    ${only('md')} {
      width: 100%;
      margin-bottom: 4px;
      padding-right: 0;
      padding-bottom: 4px;
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    }
  }

  label {
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
  }
`;

const CatalogCountriesWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-bottom: 10px;

  ${down('xl')} {
    flex-direction: column;
  }
`;

const CatalogCountries = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 160px;
  min-width: 160px;
  height: 32px;
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.colors.tableGrey};
  border-radius: 10px;

  ${down('xl')} {
    width: 100%;
    justify-content: center;
  }

  span.fi {
    width: 24px;
    height: 16px;
    border-radius: 4px;
    background-size: cover;
  }

  svg {
    width: 24px;
    height: 16px;
    border-radius: 4px;
  }
`;

const CatalogStill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.accent};
  font-size: 8px;
  color: ${({ theme }) => theme.colors.white};
`;

const CatalogAr = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 2px 10px;
  border: 1.5px solid ${({ theme }) => theme.colors.inactiveGrey};
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;

  ${down('xl')} {
    justify-content: center;
    gap: 8px;
  }
`;

interface CatalogProps {
  catalog: CatalogDto;
}

export function CatalogCard({ catalog }: CatalogProps) {
  const { t } = useTranslation(['catalog']);
  const navigate = useNavigate();

  return (
    <CatalogWrapper>
      <CatalogContent>
        <PreviewPartner logo={catalog.company_logo} name={catalog.company_name} />
        <Preview src={catalog.preview_image} />
        <CatalogTitle>{catalog.title}</CatalogTitle>
        <CatalogDescription>{catalog.description}</CatalogDescription>
      </CatalogContent>
      <div>
        <CatalogAwardWrapper>
          <CatalogAwardBlock>
            <label>{t('catalog.reward_type', { ns: 'catalog' })}:</label>
            <span>{catalog.reward_type ? handleRewardType(catalog.reward_type) : '-'}</span>
          </CatalogAwardBlock>
          <CatalogAwardBlock>
            <label>{t('catalog.award', { ns: 'catalog' })}:</label>
            <span>
              <RemunerationPercent reward={catalog.reward} />
            </span>
          </CatalogAwardBlock>
        </CatalogAwardWrapper>
        <CatalogCountriesWrapper>
          <CatalogCountries>
            {catalog.offer_geo ? (
              <>
                {catalog.offer_geo.map((geo, i) =>
                  i < 4 && geo === 'ww' ? (
                    <SvgWw key={i} />
                  ) : (
                    <span key={i} className={`fi fi-${geo}`} />
                  )
                )}
                {catalog.offer_geo.length > 4 && (
                  <CatalogStill>+{catalog.offer_geo.length - 4}</CatalogStill>
                )}
              </>
            ) : (
              <SvgWw />
            )}
          </CatalogCountries>
          <CatalogAr>
            <label>AR:</label>
            <span>
              {typeof catalog.approval_rate === 'number'
                ? `${catalog.approval_rate.toFixed(2)}%`
                : `${catalog.approval_rate}`}
            </span>
          </CatalogAr>
        </CatalogCountriesWrapper>
        <Button
          maxWidth={'full'}
          variant={'secondary'}
          onClick={() => navigate(`/offers/${catalog.id}`)}>
          {t('catalog.free_access', { ns: 'catalog' })}
        </Button>
      </div>
    </CatalogWrapper>
  );
}
