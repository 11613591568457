export function SvgFolderPlus2() {
  return (
    <svg
      width="94"
      height="106"
      viewBox="0 0 94 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.6463 36.7283C29.9662 32.4601 33.6857 29.2594 37.9539 29.5793L45.4802 30.1435C46.8466 30.2459 48.1164 30.8869 49.0103 31.9256L51.5801 34.912C52.474 35.9507 53.7437 36.5917 55.1101 36.6941L68.4327 37.6928C72.701 38.0127 75.9016 41.7322 75.5817 46.0004L73.6989 71.1174C73.379 75.3858 69.6597 78.5864 65.3913 78.2665L34.478 75.9492C30.2098 75.6293 27.0091 71.9099 27.329 67.6416L29.6463 36.7283Z"
        fill="#DBEAFE"
      />
      <path
        d="M26.6463 39.7283C26.9662 35.4601 30.6857 32.2594 34.9539 32.5793L42.4802 33.1435C43.8466 33.2459 45.1164 33.8869 46.0103 34.9256L48.5801 37.912C49.474 38.9507 50.7437 39.5917 52.1101 39.6941L65.4327 40.6928C69.701 41.0127 72.9016 44.7322 72.5817 49.0004L70.6989 74.1174C70.379 78.3858 66.6597 81.5864 62.3913 81.2665L31.478 78.9492C27.2098 78.6293 24.0091 74.9099 24.329 70.6416L26.6463 39.7283Z"
        fill="white"
      />
      <path
        d="M23.5 45.5C23.5 41.2198 26.9698 37.75 31.25 37.75H38.7974C40.1677 37.75 41.4818 38.2943 42.4508 39.2633L45.2367 42.0492C46.2057 43.0182 47.5198 43.5625 48.89 43.5625H62.25C66.5303 43.5625 70 47.0323 70 51.3125V76.5C70 80.7803 66.5303 84.25 62.25 84.25H31.25C26.9698 84.25 23.5 80.7803 23.5 76.5V45.5Z"
        fill="white"
        stroke="#2356F6"
        strokeWidth="3"
      />
      <rect x="32" y="99" width="33" height="5" rx="2.5" fill="#DBEAFE" />
      <path
        d="M45.5 73C49.64 73 53 69.64 53 65.5L53 63L57 63L51 56L45 63L49 63L49 65.5C49 67.43 47.43 69 45.5 69C43.57 69 42 67.43 42 65.5L42 58L38 58L38 65.5C38 69.64 41.36 73 45.5 73Z"
        fill="#DBEAFE"
      />
      <path
        d="M83 35C77.477 35 73 30.523 73 25C73 19.477 77.477 15 83 15C88.523 15 93 19.477 93 25C93 30.523 88.523 35 83 35ZM79.5 27V29H82V31H84V29H85C85.663 29 86.2989 28.7366 86.7678 28.2678C87.2366 27.7989 87.5 27.163 87.5 26.5C87.5 25.837 87.2366 25.2011 86.7678 24.7322C86.2989 24.2634 85.663 24 85 24H81C80.8674 24 80.7402 23.9473 80.6464 23.8536C80.5527 23.7598 80.5 23.6326 80.5 23.5C80.5 23.3674 80.5527 23.2402 80.6464 23.1464C80.7402 23.0527 80.8674 23 81 23H86.5V21H84V19H82V21H81C80.337 21 79.7011 21.2634 79.2322 21.7322C78.7634 22.2011 78.5 22.837 78.5 23.5C78.5 24.163 78.7634 24.7989 79.2322 25.2678C79.7011 25.7366 80.337 26 81 26H85C85.1326 26 85.2598 26.0527 85.3536 26.1464C85.4473 26.2402 85.5 26.3674 85.5 26.5C85.5 26.6326 85.4473 26.7598 85.3536 26.8536C85.2598 26.9473 85.1326 27 85 27H79.5Z"
        fill="#2356F6"
      />
      <path
        d="M57 20C51.477 20 47 15.523 47 10C47 4.477 51.477 0 57 0C62.523 0 67 4.477 67 10C67 15.523 62.523 20 57 20ZM55.05 9C55.1366 8.57467 55.3325 8.17923 55.6184 7.85258C55.9042 7.52593 56.2702 7.27933 56.6803 7.13706C57.0903 6.99478 57.5304 6.96172 57.9571 7.04114C58.3839 7.12056 58.7826 7.30972 59.114 7.59L60.815 6.457C60.2309 5.82076 59.476 5.36606 58.6405 5.14717C57.805 4.92829 56.9242 4.95447 56.1031 5.22259C55.2821 5.49072 54.5556 5.98945 54.0103 6.65926C53.465 7.32907 53.124 8.14165 53.028 9H52V11H53.027C53.1229 11.8586 53.4638 12.6714 54.0092 13.3414C54.5545 14.0114 55.2812 14.5103 56.1024 14.7784C56.9236 15.0466 57.8047 15.0727 58.6404 14.8536C59.476 14.6345 60.2309 14.1796 60.815 13.543L59.114 12.409C58.7826 12.6893 58.384 12.8785 57.9573 12.9579C57.5307 13.0374 57.0907 13.0044 56.6806 12.8623C56.2705 12.7201 55.9046 12.4736 55.6186 12.1471C55.3327 11.8206 55.1368 11.4252 55.05 11L60 11.001V9.001H55.05V9Z"
        fill="#2356F6"
      />
      <circle cx="19" cy="21" r="10" fill="#DBEAFE" />
    </svg>
  );
}
