import styled from 'styled-components';
import { SvgArrowRight } from '@partnerka-front/icons';
import { useNavigate } from 'react-router-dom';

const CardLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 24px 0 0 auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
`;

interface CardLinkProps {
  text: string;
  to: string;
}

export function CardLink({ text, to }: CardLinkProps) {
  const navigate = useNavigate();

  return (
    <CardLinkWrapper onClick={() => navigate(to)}>
      {text}
      <SvgArrowRight />
    </CardLinkWrapper>
  );
}
