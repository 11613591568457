export function SvgNewWindow() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 9.81151V13.6072C15 13.9766 14.8525 14.3309 14.5899 14.5921C14.3274 14.8533 13.9713 15.0001 13.6 15.0001H2.4C2.0287 15.0001 1.6726 14.8533 1.41005 14.5921C1.1475 14.3309 1 13.9766 1 13.6072V2.46395C1 2.09453 1.1475 1.74024 1.41005 1.47902C1.6726 1.2178 2.0287 1.07104 2.4 1.07104H6.271"
        stroke="#35434E"
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
      <path
        d="M14.573 1.40527L6.08203 9.49108"
        stroke="#35434E"
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
      <path
        d="M11.5 1.00132H13.663C13.9994 0.986089 14.3282 1.10324 14.5785 1.32742C14.8287 1.5516 14.9801 1.86479 15 2.19922V4.48358"
        stroke="#35434E"
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
    </svg>
  );
}
