import { TableItem, TableRow, DateFormat, Price } from '@partnerka-front/components';
import { BusinessFinanceDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CountrySection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin: 0 auto;

  label {
    box-shadow: 0 0 4px rgba(158, 150, 150, 0.25);
  }
`;

interface FinanceBusinessTableItemProps {
  deal: BusinessFinanceDto;
}

export function FinanceBusinessTableItem({ deal }: FinanceBusinessTableItemProps) {
  const { t } = useTranslation(['deals']);

  return (
    <TableRow
      gridTemplateColumns={'100px 115px 90px 90px 200px 200px 160px 120px 120px 110px 110px 110px'}>
      <TableItem noRightBorder>{deal.id}</TableItem>
      <TableItem noRightBorder>
        {deal.status === 'Approved' && t('deals.approved', { ns: 'deals' })}
        {deal.status === 'Paid' && t('deals.paid', { ns: 'deals' })}
        {deal.status === 'Created' && t('deals.created', { ns: 'deals' })}
        {deal.status === 'Canceled' && t('deals.canceled', { ns: 'deals' })}
        {deal.status === 'Rejected' && t('deals.rejected', { ns: 'deals' })}
      </TableItem>
      <TableItem noRightBorder>{deal.uid}</TableItem>
      <TableItem noRightBorder>
        <DateFormat date={deal.conversion_time} />
      </TableItem>
      <TableItem noRightBorder>{deal.offer.catalog.title}</TableItem>
      <TableItem noRightBorder>{deal.offer.name}</TableItem>
      <TableItem noRightBorder>{deal.promo}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.cost} currency={deal.currency} />
      </TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.payment_sum} currency={deal.currency} />
      </TableItem>
      <TableItem noRightBorder>
        {deal.country && (
          <CountrySection>
            <span className={`fi fi-${deal.country.toLowerCase()}`} />
            {deal.country}
          </CountrySection>
        )}
      </TableItem>
      <TableItem noRightBorder>{deal.os}</TableItem>
      <TableItem noRightBorder>{deal.browser}</TableItem>
    </TableRow>
  );
}
