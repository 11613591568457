import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@partnerka-front/components';

export function CreatePostbackModal() {
  const { t } = useTranslation(['postback', 'common']);
  const navigate = useNavigate();

  return (
    <Modal
      title={`${t('postback.postback_created', { ns: 'postback' })}`}
      onClose={() => navigate('/postback')}
      rightBtn={
        <Button variant={'primary-inactive'} onClick={() => navigate('/postback')}>
          {t('common.clearly', { ns: 'common' })}
        </Button>
      }>
      <p>{t('postback.postback_created_description', { ns: 'postback' })}</p>
    </Modal>
  );
}
