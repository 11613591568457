import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '@partnerka-front/api';
import {
  ModalLoading,
  SectionRelative,
  Title,
  CatalogCard,
  Message,
  Container,
} from '@partnerka-front/components';
import { CatalogDto, ResponseError } from '@partnerka-front/types';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const CatalogsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${down('xl')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${down('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${down('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export function OffersPage() {
  const { t } = useTranslation(['offers']);
  const [catalogs, setCatalogs] = useState<CatalogDto[]>([]);
  const [pending, setPending] = useState(true);
  const [error, setError] = useState<ResponseError | null>(null);

  const getCoursesAndPromo = async () => {
    await Api.getCatalogs()
      .then((res) => {
        setCatalogs(res.data);
      })
      .catch(setError);

    setPending(false);
  };

  useEffect(() => {
    getCoursesAndPromo();
  }, []);

  return (
    <Container>
      <Title>{t('offers.title', { ns: 'offers' })}</Title>
      <SectionRelative>
        {pending && <ModalLoading />}
        <CatalogsWrapper>
          {catalogs.length > 0 &&
            catalogs.map((catalog) => <CatalogCard key={catalog.id} catalog={catalog} />)}
        </CatalogsWrapper>

        {error?.response.data && (
          <Message>
            {error.response.status === 403 ? (
              t('errors.offers_moderation', { ns: 'errors' })
            ) : (
              <>
                {error.response.data.detail
                  ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                  : t('errors.try_again_later', { ns: 'errors' })}
              </>
            )}
          </Message>
        )}
      </SectionRelative>
    </Container>
  );
}
