import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SvgChevron } from '@partnerka-front/icons';
import { BreadCrumbsDto } from '@partnerka-front/types';

const BreadCrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > * {
    display: flex;
    align-items: center;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    margin: 0 4px;
  }
`;

interface BreadCrumbsProps {
  list: BreadCrumbsDto[];
  current: string;
}

export function BreadCrumbs({ list, current }: BreadCrumbsProps) {
  return (
    <BreadCrumbsWrapper>
      {list.length > 0 &&
        list.map((item, i) => (
          <div key={i}>
            <Link to={item.to}>{item.label}</Link>
            <SvgChevron />
          </div>
        ))}
      <label>{current}</label>
    </BreadCrumbsWrapper>
  );
}
