import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { ModalLoading, Paragraph, SectionRelative, TitleH2 } from '@partnerka-front/components';
import { StatisticsDealsDto } from '@partnerka-front/types';

const DealsStatusTableWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.inactiveGrey};
`;

const DealsStatusTableHeader = styled.div`
  display: flex;
  gap: 1px;
  width: 100%;

  & > *:first-child {
    max-width: 160px;

    ${down('lg')} {
      max-width: 100%;
    }
  }

  & > *:last-child {
    max-width: 110px;
  }
`;

const DealsStatusTableHeaderItem = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};

  ${down('lg')} {
    &:nth-child(2) {
      display: none;
    }
  }
`;

const DealsStatusTableRow = styled.div`
  display: flex;
  gap: 1px;
  width: 100%;

  &:nth-child(odd) > * {
    background-color: ${({ theme }) => theme.colors.tableGrey};
  }

  & > *:first-child {
    max-width: 160px;
    font-weight: 500;

    ${down('lg')} {
      max-width: 100%;
    }
  }

  & > *:last-child {
    max-width: 110px;
    font-weight: 500;
  }
`;

const DealsStatusTableRowItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  &:nth-child(2) {
    justify-content: flex-start;
  }

  ${down('lg')} {
    &:nth-child(2) {
      display: none;
    }

    &:first-child {
      justify-content: flex-start;
    }
  }
`;

export function DealsStatusTable() {
  const { t } = useTranslation(['deals', 'common']);

  const [pending, setPending] = useState(true);
  const [statistics, setStatistics] = useState<StatisticsDealsDto>({
    deal_canceled: 0,
    deal_approved: 0,
    deal_created: 0,
    deal_paid: 0,
  });

  const getStatistics = async () => {
    await Api.getStatistics().then((res) => setStatistics(res.data));
    setPending(false);
  };

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <>
      <TitleH2>{t('deals.status_transactions', { ns: 'deals' })}</TitleH2>
      <Paragraph>{t('deals.result_all_time', { ns: 'deals' })}</Paragraph>
      <DealsStatusTableWrapper>
        <DealsStatusTableHeader>
          <DealsStatusTableHeaderItem>
            {t('common.status', { ns: 'common' })}
          </DealsStatusTableHeaderItem>
          <DealsStatusTableHeaderItem>
            {t('common.description', { ns: 'common' })}
          </DealsStatusTableHeaderItem>
          <DealsStatusTableHeaderItem>
            {t('common.result', { ns: 'common' })}
          </DealsStatusTableHeaderItem>
        </DealsStatusTableHeader>
        <SectionRelative>
          {pending && <ModalLoading />}
          <DealsStatusTableRow>
            <DealsStatusTableRowItem>
              {t('deals.approved', { ns: 'deals' })}
            </DealsStatusTableRowItem>
            <DealsStatusTableRowItem>
              {t('deals.approved_description', { ns: 'deals' })}
            </DealsStatusTableRowItem>
            <DealsStatusTableRowItem>{statistics.deal_approved}</DealsStatusTableRowItem>
          </DealsStatusTableRow>
          <DealsStatusTableRow>
            <DealsStatusTableRowItem>{t('deals.paid', { ns: 'deals' })}</DealsStatusTableRowItem>
            <DealsStatusTableRowItem>
              {t('deals.paid_description', { ns: 'deals' })}
            </DealsStatusTableRowItem>
            <DealsStatusTableRowItem>{statistics.deal_paid}</DealsStatusTableRowItem>
          </DealsStatusTableRow>
          <DealsStatusTableRow>
            <DealsStatusTableRowItem>{t('deals.created', { ns: 'deals' })}</DealsStatusTableRowItem>
            <DealsStatusTableRowItem>
              {t('deals.created_description', { ns: 'deals' })}
            </DealsStatusTableRowItem>
            <DealsStatusTableRowItem>{statistics.deal_created}</DealsStatusTableRowItem>
          </DealsStatusTableRow>
          <DealsStatusTableRow>
            <DealsStatusTableRowItem>
              {t('deals.canceled', { ns: 'deals' })}
            </DealsStatusTableRowItem>
            <DealsStatusTableRowItem>
              {t('deals.canceled_description', { ns: 'deals' })}
            </DealsStatusTableRowItem>
            <DealsStatusTableRowItem>{statistics.deal_canceled}</DealsStatusTableRowItem>
          </DealsStatusTableRow>
        </SectionRelative>
      </DealsStatusTableWrapper>
    </>
  );
}
