import { GlobalEvents, PromoDto, ResponseError } from '@partnerka-front/types';
import styled from 'styled-components';
import { ControlledSimpleInput, SimpleInput } from '../../UI/input';
import { DateFormat } from '../../date-format';
import { SvgEditRectangle, SvgNewEdit } from '@partnerka-front/icons';
import { useForm, FormProvider } from 'react-hook-form';
import { Api } from '@partnerka-front/api';
import { useState } from 'react';
import { toast } from 'react-toast';
import { Spinner } from '../../spinner';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { down } from 'styled-breakpoints';
import { regExpPromo, useEventBus } from '@partnerka-front/utils';

export const CatalogPromoItemWrapper = styled.div`
  position: relative;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 60px 100px 42px;
  gap: 10px;

  ${down('sm')} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    & > *:nth-of-type(1) {
      width: 100%;
    }

    & > *:nth-of-type(2) {
      width: 60px;
      max-width: fit-content;
    }

    & > *:nth-of-type(3) {
      width: 1fr;
      max-width: fit-content;
    }

    & > *:nth-of-type(4) {
      position: relative;
      width: 42px;
    }
  }
`;

interface ItemProps {
  isActive?: boolean;
  isCursor?: boolean;
}

export const PromoItem = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.accent : 'transparent')};
  cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'default')};
`;

const Action = styled.div`
  font-size: 0;
  line-height: 1;
  cursor: pointer;
`;

const PromoLoading = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: brightness(1) blur(1px);
`;

interface CatalogPromoItemProps {
  idCatalog: string;
  percentDiscounts: number;
  promo: PromoDto;
}

export function CatalogPromoItem({ idCatalog, percentDiscounts, promo }: CatalogPromoItemProps) {
  const { t } = useTranslation(['promo', 'validation', 'errors']);
  const { emit } = useEventBus();

  const [isEdit, setIsEdit] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | undefined>(undefined);

  const promoCodeSchema = Yup.object().shape({
    code: Yup.string()
      .min(
        2,
        `${t('validation.promo_must_be_at_least_characters', {
          characters: '2',
          ns: 'validation',
        })}`
      )
      .max(
        200,
        `${t('validation.promo_must_be_at_most_characters', {
          characters: '200',
          ns: 'validation',
        })}`
      )
      .matches(regExpPromo, `${t('validation.promo', { ns: 'validation' })}`),
  });
  const formPromo = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(promoCodeSchema),
    defaultValues: {
      code: promo.code,
    },
  });
  const { handleSubmit, setFocus } = formPromo;

  const updatePromoCode = handleSubmit(async (data) => {
    setError(undefined);
    setPending(true);

    await Api.patchPromo(promo.id, {
      ...data,
      percent_discounts: percentDiscounts,
      catalog: [idCatalog],
    })
      .then(() => {
        setIsEdit(false);
        toast.success(`${t('promo.promo_changed', { ns: 'promo' })}`);

        emit(GlobalEvents.fetchCatalog);
      })
      .catch(setError);
    setPending(false);
  });

  const handlerEdit = async () => {
    if (isEdit) {
      await updatePromoCode();
    } else {
      setIsEdit(true);
      setFocus('code');
    }
  };

  return (
    <FormProvider {...formPromo}>
      <form onSubmit={updatePromoCode}>
        <CatalogPromoItemWrapper>
          {pending && (
            <PromoLoading>
              <Spinner />
            </PromoLoading>
          )}

          <ControlledSimpleInput
            name={'code'}
            mode={isEdit ? 'default' : 'copy'}
            error={
              error &&
              (error.response.data && error.response.data.detail
                ? `${t(`errors.${error.response.data.detail}`, { ns: 'errors' })}`
                : 'Error')
            }
          />
          <PromoItem className={'b'}>
            {isEdit ? percentDiscounts : promo.percent_discounts}%
          </PromoItem>
          <PromoItem className={'c'}>
            <DateFormat date={promo.created_at} />
          </PromoItem>
          <PromoItem className={'d'} onClick={handlerEdit} isActive={isEdit} isCursor>
            <Action>{isEdit ? <SvgEditRectangle /> : <SvgNewEdit />}</Action>
          </PromoItem>
        </CatalogPromoItemWrapper>
      </form>
    </FormProvider>
  );
}

export function CatalogPromoItemPreview() {
  return (
    <CatalogPromoItemWrapper>
      <SimpleInput mode={'copy'} currentValue={'PROMO_CODE'} />
      <PromoItem className={'b'}>5%</PromoItem>
      <PromoItem className={'c'}>01.01.2023</PromoItem>
      <PromoItem className={'d'} isCursor>
        <Action>
          <SvgNewEdit />
        </Action>
      </PromoItem>
    </CatalogPromoItemWrapper>
  );
}
