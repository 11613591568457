import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const PageAuth = styled.div`
  ${down('lg')} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 80px - 30px);
  }
`;

interface FormAuthProps {
  isBusiness?: boolean;
}

export const FormAuth = styled.form<FormAuthProps>`
  position: ${({ isBusiness }) => (isBusiness ? 'static' : 'absolute')};
  left: 0;
  right: 0;
  width: 100%;
  max-width: 360px;
  margin: 60px auto 0;

  h1 {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${down('lg')} {
    position: static;
    margin: ${({ isBusiness }) => (isBusiness ? '0' : '0 auto')};
  }
`;

export const FormAfter = styled.div`
  width: 100%;
  text-align: center;

  a {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.headliner};
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
      text-decoration: underline;
    }
  }
`;
