import { AxiosResponse } from 'axios';
import { $api } from '@partnerka-front/api';
import {
  BusinessCatalogDto,
  CatalogDto,
  CatalogUserWithDealsDto,
  CreateCatalogPayload,
  OfferUserWithDealsDto,
  SettingRuleTrafficPayload,
} from '@partnerka-front/types';

const basePath = '/catalog';

export async function getCatalogs(): Promise<AxiosResponse<CatalogDto[]>> {
  return $api.get(`${basePath}/`);
}

export async function getUserCatalogs(): Promise<AxiosResponse<CatalogDto[]>> {
  return $api.get(`${basePath}/get_catalog_by_user/`);
}

export async function getCatalog(id: string): Promise<AxiosResponse<CatalogDto>> {
  return $api.get(`${basePath}/${id}/`);
}

export async function patchCatalog(
  id: string,
  payload: Partial<BusinessCatalogDto>
): Promise<AxiosResponse<BusinessCatalogDto>> {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function addUserCatalog(id: string): Promise<AxiosResponse<CatalogDto>> {
  return $api.patch(`${basePath}/${id}/add_user_to_catalog/`);
}

export async function createCatalog(
  payload: CreateCatalogPayload
): Promise<AxiosResponse<CatalogDto>> {
  return $api.post(`${basePath}/`, payload);
}

export async function patchSettingRuleTraffic(
  id: string,
  payload: SettingRuleTrafficPayload
): Promise<AxiosResponse<any>> {
  return $api.patch(`${basePath}/${id}/setting_rule_traffic/`, payload);
}

export async function getCatalogUserWithDeals(): Promise<AxiosResponse<CatalogUserWithDealsDto[]>> {
  return $api.get(`${basePath}/get_catalog_with_deals/`);
}

export async function getOfferUserWithDeals(
  list_catalogs?: string
): Promise<AxiosResponse<OfferUserWithDealsDto[]>> {
  return $api.get(`${basePath}/get_offer_with_deals/`, { params: { list_catalogs } });
}
