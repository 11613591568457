import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
  }

  body {
    background-color: #F2F4F8;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.43;
  }

  img {
    max-width: 100%;
  }

  input,
  select,
  textarea {
    outline: none;
  }
  
  button {
    font-family: 'Roboto', sans-serif;
    transition: 0.1s;
    
    &:active {
      transform: scale(0.98);
      
      &:disabled {
        transform: scale(1);
      }
    }
  }
`;

export default GlobalStyle;
