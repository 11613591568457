import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { Api } from '@partnerka-front/api';
import {
  PostbackBasic,
  PostbackSections,
  PostbackForm,
  PostbackSave,
  PostbackRequestParameters,
  PageLoading,
  CreatePostbackModal,
  UpdatedPostbackModal,
} from '@partnerka-front/components';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { regExpUrl } from '@partnerka-front/utils';
import {
  CatalogDto,
  PostbackDto,
  PostbackTemplatesDto,
  ResponseError,
} from '@partnerka-front/types';

interface PostbackProps {
  catalogs: CatalogDto[];
  templates: PostbackTemplatesDto[];
  postback?: PostbackDto;
  defaultCatalog?: string;
}

export function Postback({ postback, defaultCatalog, catalogs, templates }: PostbackProps) {
  const { t } = useTranslation();

  const [fields, setFields] = useState([{ key: 'subid', value: 'click_id' }]);
  const [urlParameters, setUrlParameters] = useState('?click_id={subid}');

  const [modalCreatePostback, setModalCreatePostback] = useState(false);
  const [modalUpdatePostback, setModalUpdatePostback] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  // ~~ Valid Schema

  const postbackSchema = Yup.object()
    .shape({
      name: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
      url: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(regExpUrl, `${t('validation.invalid_url', { ns: 'validation' })}`),
      catalog: Yup.string().when('type_postback', {
        is: 'local',
        then: Yup.string().notOneOf(
          ['none'],
          `${t('validation.select_catalog', { ns: 'validation' })}`
        ),
      }),
      approved: Yup.boolean(),
      paid: Yup.boolean(),
      created: Yup.boolean(),
      canceled: Yup.boolean(),
    })
    .test('any-true', 'At least one value should be true', function (this: any, values) {
      const { approved, paid, created, canceled } = values;
      if (!(approved || paid || created || canceled)) {
        return false;
      }
      return true;
    });

  // ~~ Methods Form

  const postbackMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(postbackSchema),
    defaultValues: {
      type_postback: postback
        ? postback.catalog.length === 1
          ? 'local'
          : 'global'
        : defaultCatalog
        ? 'local'
        : 'global',
      name: postback ? postback.name : '',
      catalog: postback
        ? postback.catalog.length === 1
          ? postback.catalog[0]
          : 'none'
        : defaultCatalog
        ? defaultCatalog
        : 'none',
      approved: postback ? !!postback.status.find((status) => status === 'Approved') : true,
      paid: postback ? !!postback.status.find((status) => status === 'Paid') : true,
      created: postback ? !!postback.status.find((status) => status === 'Created') : true,
      canceled: postback ? !!postback.status.find((status) => status === 'Canceled') : true,
      url: postback ? postback.url : '',
      postback_template: postback?.postback_template ?? 'none',
    },
  });
  const { handleSubmit, watch } = postbackMethods;

  // ~~ Functions

  const createPostback = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    const status: string[] = [];
    if (data.approved) status.push('Approved');
    if (data.paid) status.push('Paid');
    if (data.created) status.push('Created');
    if (data.canceled) status.push('Canceled');

    if (postback) {
      await Api.patchPostback(postback.id, {
        name: data.name,
        url: data.url,
        user_url: data.url + urlParameters,
        catalog: data.type_postback === 'local' ? data.catalog : undefined,
        status,
        postback_template: data.postback_template !== 'none' ? data.postback_template : null,
        postback_field: fields,
      })
        .then(() => {
          setModalUpdatePostback(true);
        })
        .catch(setError);
    } else {
      await Api.createPostback({
        name: data.name,
        url: data.url,
        user_url: data.url + urlParameters,
        catalog: data.type_postback === 'local' ? data.catalog : undefined,
        status,
        postback_template: data.postback_template !== 'none' ? data.postback_template : null,
        postback_field: fields,
      })
        .then(() => {
          setModalCreatePostback(true);
        })
        .catch(setError);
    }

    setPending(false);
  });

  return (
    <FormProvider {...postbackMethods}>
      <PostbackForm onSubmit={createPostback}>
        <PostbackSections>
          <PostbackBasic catalogs={catalogs} typePostback={watch('type_postback')} watch={watch} />

          <PostbackRequestParameters
            templates={templates}
            postback_field={postback?.postback_field}
            watch={watch}
            setFields={setFields}
            setUrlParameters={setUrlParameters}
          />
        </PostbackSections>

        <PostbackSave
          onSave={createPostback}
          error={error}
          setError={setError}
          url={watch('url')}
          urlParameters={urlParameters}
        />
      </PostbackForm>

      {pending && <PageLoading />}

      {modalUpdatePostback && <UpdatedPostbackModal />}
      {modalCreatePostback && <CreatePostbackModal />}
    </FormProvider>
  );
}
