import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countryLabel, usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import {
  SkeletonTable,
  TableChildren,
  TableItem,
  TableNoData,
  TableParentTitle,
  TableRow,
  TableSectionFilter,
  TableSorting,
  TableWrapper,
} from '../../UI/table';
import {
  Button,
  DateRangeSelector,
  Pagination,
  Select,
  Title,
  Container,
} from '@partnerka-front/components';
import {
  SvgCheckRectangleWhite,
  SvgCloseRectangleWhite,
  SvgCreateWhite,
  SvgInterfaceLoading,
  SvgNoDeals,
  SvgSumSignWhite,
} from '@partnerka-front/icons';
import { AccountStatisticsTableItem } from './item';
import styled from 'styled-components';
import { DateRange } from '@partnerka-front/types';
import { format } from 'date-fns';

const TableItemIcon = styled(TableItem)`
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 0;

  svg {
    height: 24px;
    margin: 0 auto;
  }
`;

const SelectSkeleton = styled.div`
  width: 100%;
  height: 40px;

  // TODO: Skeleton - разобраться в чем прикол (ломается если стартовать с этой страницы)
  background: linear-gradient(110deg, #f2f4f8 8%, #fff 18%, #f2f4f8 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  border-radius: 15px;
`;

const Groups = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

interface GroupProps {
  isActive?: boolean;
}

const Group = styled.button<GroupProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 2px 10px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.accent : theme.colors.basicLight};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.headliner)};
  cursor: pointer;
`;

export function AccountStatisticsTable() {
  const { t } = useTranslation(['deals', 'errors', 'common']);

  const {
    firstLoading,
    pending,
    items: deals,
    fetchItems,
    itemsTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    pagesTotal,
  } = usePaginated(
    (page, pageSize) =>
      Api.getGeneralStatisticsUser(page, pageSize, groupBy, {
        o: ordering,
        catalog: catalog === 'none' ? undefined : catalog,
        offer: offer === 'none' ? undefined : offer,
        modified_at_date_after: startDate,
        modified_at_date_before: endDate,
        country: country === 'none' ? undefined : country,
        os: os === 'none' ? undefined : os,
        browser: browser === 'none' ? undefined : browser,
        subid1: subid1 === 'none' ? undefined : subid1,
        subid2: subid2 === 'none' ? undefined : subid2,
        subid3: subid3 === 'none' ? undefined : subid3,
        subid4: subid4 === 'none' ? undefined : subid4,
        subid5: subid5 === 'none' ? undefined : subid5,
        subid6: subid6 === 'none' ? undefined : subid6,
        subid7: subid7 === 'none' ? undefined : subid7,
        subid8: subid8 === 'none' ? undefined : subid8,
        subid9: subid9 === 'none' ? undefined : subid9,
        subid10: subid10 === 'none' ? undefined : subid10,
        subid11: subid11 === 'none' ? undefined : subid11,
        subid12: subid12 === 'none' ? undefined : subid12,
        subid13: subid13 === 'none' ? undefined : subid13,
        subid14: subid14 === 'none' ? undefined : subid14,
        subid15: subid15 === 'none' ? undefined : subid15,
        subid16: subid16 === 'none' ? undefined : subid16,
        subid17: subid17 === 'none' ? undefined : subid17,
        subid18: subid18 === 'none' ? undefined : subid18,
        utm_source: utmSource === 'none' ? undefined : utmSource,
        utm_medium: utmMedium === 'none' ? undefined : utmMedium,
        utm_campaign: utmCampaign === 'none' ? undefined : utmCampaign,
        utm_content: utmContent === 'none' ? undefined : utmContent,
        utm_term: utmTerm === 'none' ? undefined : utmTerm,
      }),
    `${t('errors.try_again_later', { ns: 'errors' })}`
  );

  const [isAllFilters, setIsAllFilters] = useState(false);
  const [ordering, setOrdering] = useState('-modified_at_date');

  const [catalog, setCatalog] = useState('none');
  const [optionsCatalog, setOptionsCatalog] = useState<object | null>(null);

  const [offer, setOffer] = useState('none');
  const [pendingOffers, setPendingOffers] = useState(false);
  const [optionsOffer, setOptionsOffer] = useState({
    none: `${t('common.select_offer', { ns: 'common' })}`,
  });

  const [country, setCountry] = useState('none');
  const [optionsCountry, setOptionsCountry] = useState({
    none: `${t('deals.country', { ns: 'deals' })}`,
  });

  const [os, setOs] = useState('none');
  const [optionsOs, setOptionsOs] = useState({
    none: 'OS',
  });

  const [browser, setBrowser] = useState('none');
  const [optionsBrowser, setOptionsBrowser] = useState({
    none: `${t('deals.browser', { ns: 'deals' })}`,
  });

  const [subid1, setSubid1] = useState('none');
  const [optionsSubid1, setOptionsSubid1] = useState({
    none: `SubID1`,
  });

  const [subid2, setSubid2] = useState('none');
  const [optionsSubid2, setOptionsSubid2] = useState({
    none: `SubID2`,
  });

  const [subid3, setSubid3] = useState('none');
  const [optionsSubid3, setOptionsSubid3] = useState({
    none: `SubID3`,
  });

  const [subid4, setSubid4] = useState('none');
  const [optionsSubid4, setOptionsSubid4] = useState({
    none: `SubID4`,
  });

  const [subid5, setSubid5] = useState('none');
  const [optionsSubid5, setOptionsSubid5] = useState({
    none: `SubID5`,
  });

  const [subid6, setSubid6] = useState('none');
  const [optionsSubid6, setOptionsSubid6] = useState({
    none: `SubID6`,
  });

  const [subid7, setSubid7] = useState('none');
  const [optionsSubid7, setOptionsSubid7] = useState({
    none: `SubID7`,
  });

  const [subid8, setSubid8] = useState('none');
  const [optionsSubid8, setOptionsSubid8] = useState({
    none: `SubID8`,
  });

  const [subid9, setSubid9] = useState('none');
  const [optionsSubid9, setOptionsSubid9] = useState({
    none: `SubID9`,
  });

  const [subid10, setSubid10] = useState('none');
  const [optionsSubid10, setOptionsSubid10] = useState({
    none: `SubID10`,
  });

  const [subid11, setSubid11] = useState('none');
  const [optionsSubid11, setOptionsSubid11] = useState({
    none: `SubID11`,
  });

  const [subid12, setSubid12] = useState('none');
  const [optionsSubid12, setOptionsSubid12] = useState({
    none: `SubID12`,
  });

  const [subid13, setSubid13] = useState('none');
  const [optionsSubid13, setOptionsSubid13] = useState({
    none: `SubID13`,
  });

  const [subid14, setSubid14] = useState('none');
  const [optionsSubid14, setOptionsSubid14] = useState({
    none: `SubID14`,
  });

  const [subid15, setSubid15] = useState('none');
  const [optionsSubid15, setOptionsSubid15] = useState({
    none: `SubID15`,
  });

  const [subid16, setSubid16] = useState('none');
  const [optionsSubid16, setOptionsSubid16] = useState({
    none: `SubID16`,
  });

  const [subid17, setSubid17] = useState('none');
  const [optionsSubid17, setOptionsSubid17] = useState({
    none: `SubID17`,
  });

  const [subid18, setSubid18] = useState('none');
  const [optionsSubid18, setOptionsSubid18] = useState({
    none: `SubID18`,
  });

  const [utmSource, setUtmSource] = useState('none');
  const [optionsUtmSource, setOptionsUtmSource] = useState({
    none: `utm_source`,
  });

  const [utmMedium, setUtmMedium] = useState('none');
  const [optionsUtmMedium, setOptionsUtmMedium] = useState({
    none: `utm_medium`,
  });

  const [utmCampaign, setUtmCampaign] = useState('none');
  const [optionsUtmCampaign, setOptionsUtmCampaign] = useState({
    none: `utm_campaign`,
  });

  const [utmContent, setUtmContent] = useState('none');
  const [optionsUtmContent, setOptionsUtmContent] = useState({
    none: `utm_content`,
  });

  const [utmTerm, setUtmTerm] = useState('none');
  const [optionsUtmTerm, setOptionsUtmTerm] = useState({
    none: `utm_term`,
  });

  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const dateFormat = 'yyyy-MM-dd';

  const [groupBy, setGroupBy] = useState('modified_at_date');

  const getFilters = async () => {
    const promises: Record<string, Promise<unknown>> = {};
    const results: any = {};

    promises.catalogs = Api.getCatalogUserWithDeals().then((res) => {
      const { data: catalogs } = res;
      const result = {
        none: `${t('common.select_catalog', { ns: 'common' })}`,
      };

      for (let i = 0; i < catalogs.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[catalogs[i].catalog] = catalogs[i].title;
      }

      results.catalogs = result;
    });

    promises.filters = Api.getParamsWithDeals().then((res) => {
      const {
        country,
        os,
        browser,
        subid1,
        subid2,
        subid3,
        subid4,
        subid5,
        subid6,
        subid7,
        subid8,
        subid9,
        subid10,
        subid11,
        subid12,
        subid13,
        subid14,
        subid15,
        subid16,
        subid17,
        subid18,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      } = res.data;

      const resultCountry = {
        none: `${t('deals.country', { ns: 'deals' })}`,
      };
      for (let i = 0; i < country.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultCountry[country[i]] = countryLabel(country[i]);
      }
      results.country = resultCountry;

      const resultOs = {
        none: `OS`,
      };
      for (let i = 0; i < os.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultOs[os[i]] = os[i];
      }
      results.os = resultOs;

      const resultBrowser = {
        none: `${t('deals.browser', { ns: 'deals' })}`,
      };
      for (let i = 0; i < browser.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultBrowser[browser[i]] = browser[i];
      }
      results.browser = resultBrowser;

      const resultSubid1 = {
        none: `SubID1`,
      };
      for (let i = 0; i < subid1.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid1[subid1[i]] = subid1[i];
      }
      results.subid1 = resultSubid1;

      const resultSubid2 = {
        none: `SubID2`,
      };
      for (let i = 0; i < subid2.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid2[subid2[i]] = subid2[i];
      }
      results.subid2 = resultSubid2;

      const resultSubid3 = {
        none: `SubID3`,
      };
      for (let i = 0; i < subid3.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid3[subid3[i]] = subid3[i];
      }
      results.subid3 = resultSubid3;

      const resultSubid4 = {
        none: `SubID4`,
      };
      for (let i = 0; i < subid4.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid4[subid4[i]] = subid4[i];
      }
      results.subid4 = resultSubid4;

      const resultSubid5 = {
        none: `SubID5`,
      };
      for (let i = 0; i < subid5.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid5[subid5[i]] = subid5[i];
      }
      results.subid5 = resultSubid5;

      const resultSubid6 = {
        none: `SubID6`,
      };
      for (let i = 0; i < subid6.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid6[subid6[i]] = subid6[i];
      }
      results.subid6 = resultSubid6;

      const resultSubid7 = {
        none: `SubID7`,
      };
      for (let i = 0; i < subid7.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid7[subid7[i]] = subid7[i];
      }
      results.subid7 = resultSubid7;

      const resultSubid8 = {
        none: `SubID8`,
      };
      for (let i = 0; i < subid8.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid8[subid8[i]] = subid8[i];
      }
      results.subid8 = resultSubid8;

      const resultSubid9 = {
        none: `SubID9`,
      };
      for (let i = 0; i < subid9.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid9[subid9[i]] = subid9[i];
      }
      results.subid9 = resultSubid9;

      const resultSubid10 = {
        none: `SubID10`,
      };
      for (let i = 0; i < subid10.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid10[subid10[i]] = subid10[i];
      }
      results.subid10 = resultSubid10;

      const resultSubid11 = {
        none: `SubID11`,
      };
      for (let i = 0; i < subid11.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid11[subid11[i]] = subid11[i];
      }
      results.subid11 = resultSubid11;

      const resultSubid12 = {
        none: `SubID12`,
      };
      for (let i = 0; i < subid12.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid12[subid12[i]] = subid12[i];
      }
      results.subid12 = resultSubid12;

      const resultSubid13 = {
        none: `SubID13`,
      };
      for (let i = 0; i < subid13.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid13[subid13[i]] = subid13[i];
      }
      results.subid13 = resultSubid13;

      const resultSubid14 = {
        none: `SubID14`,
      };
      for (let i = 0; i < subid14.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid14[subid14[i]] = subid14[i];
      }
      results.subid14 = resultSubid14;

      const resultSubid15 = {
        none: `SubID15`,
      };
      for (let i = 0; i < subid15.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid15[subid15[i]] = subid15[i];
      }
      results.subid15 = resultSubid15;

      const resultSubid16 = {
        none: `SubID16`,
      };
      for (let i = 0; i < subid16.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid16[subid16[i]] = subid16[i];
      }
      results.subid16 = resultSubid16;

      const resultSubid17 = {
        none: `SubID17`,
      };
      for (let i = 0; i < subid17.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid17[subid17[i]] = subid17[i];
      }
      results.subid17 = resultSubid17;

      const resultSubid18 = {
        none: `SubID18`,
      };
      for (let i = 0; i < subid18.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultSubid18[subid18[i]] = subid18[i];
      }
      results.subid18 = resultSubid18;

      const resultUtmSource = {
        none: `utm_source`,
      };
      for (let i = 0; i < utm_source.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultUtmSource[utm_source[i]] = utm_source[i];
      }
      results.utm_source = resultUtmSource;

      const resultUtmMedium = {
        none: `utm_medium`,
      };
      for (let i = 0; i < utm_medium.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultUtmMedium[utm_medium[i]] = utm_medium[i];
      }
      results.utm_medium = resultUtmMedium;

      const resultUtmCampaign = {
        none: `utm_campaign`,
      };
      for (let i = 0; i < utm_campaign.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultUtmCampaign[utm_campaign[i]] = utm_campaign[i];
      }
      results.utm_campaign = resultUtmCampaign;

      const resultUtmContent = {
        none: `utm_content`,
      };
      for (let i = 0; i < utm_content.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultUtmContent[utm_content[i]] = utm_content[i];
      }
      results.utm_content = resultUtmContent;

      const resultUtmTerm = {
        none: `utm_term`,
      };
      for (let i = 0; i < utm_term.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultUtmTerm[utm_term[i]] = utm_term[i];
      }
      results.utm_term = resultUtmTerm;
    });

    await Promise.all(Object.values(promises)).then(() => {
      setOptionsCatalog(results.catalogs);
      setOptionsCountry(results.country);
      setOptionsOs(results.os);
      setOptionsBrowser(results.browser);
      setOptionsSubid1(results.subid1);
      setOptionsSubid2(results.subid2);
      setOptionsSubid3(results.subid3);
      setOptionsSubid4(results.subid4);
      setOptionsSubid5(results.subid5);
      setOptionsSubid6(results.subid6);
      setOptionsSubid7(results.subid7);
      setOptionsSubid8(results.subid8);
      setOptionsSubid9(results.subid9);
      setOptionsSubid10(results.subid10);
      setOptionsSubid11(results.subid11);
      setOptionsSubid12(results.subid12);
      setOptionsSubid13(results.subid13);
      setOptionsSubid14(results.subid14);
      setOptionsSubid15(results.subid15);
      setOptionsSubid16(results.subid16);
      setOptionsSubid17(results.subid17);
      setOptionsSubid18(results.subid18);
      setOptionsUtmSource(results.utm_source);
      setOptionsUtmMedium(results.utm_medium);
      setOptionsUtmCampaign(results.utm_campaign);
      setOptionsUtmContent(results.utm_content);
      setOptionsUtmTerm(results.utm_term);
    });
  };

  const getOffers = async (idCatalog: string) => {
    setPendingOffers(true);

    await Api.getOfferUserWithDeals(idCatalog).then((res) => {
      const { data: offers } = res;
      const result = {
        none: `${t('common.select_offer', { ns: 'common' })}`,
      };

      for (let i = 0; i < offers.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[offers[i].offer] = offers[i].name;
      }

      setOptionsOffer(result);
    });

    setPendingOffers(false);
  };

  const submitFilter = () => {
    page === 1 ? fetchItems() : setPage(1);
  };

  const clearFilter = () => {
    setDateRange(undefined);
    setCatalog('none');
    setCountry('none');
    setOs('none');
    setBrowser('none');
    setSubid1('none');
    setSubid2('none');
    setSubid3('none');
    setSubid4('none');
    setSubid5('none');
    setSubid6('none');
    setSubid7('none');
    setSubid8('none');
    setSubid9('none');
    setSubid10('none');
    setSubid11('none');
    setSubid12('none');
    setSubid13('none');
    setSubid14('none');
    setSubid15('none');
    setSubid16('none');
    setSubid17('none');
    setSubid18('none');
    setUtmSource('none');
    setUtmMedium('none');
    setUtmCampaign('none');
    setUtmContent('none');
    setUtmTerm('none');
  };

  useEffect(() => {
    if (catalog && catalog !== 'none') {
      getOffers(catalog);
    } else {
      setOptionsOffer({ none: `${t('common.select_offer', { ns: 'common' })}` });
    }

    setOffer('none');
  }, [catalog]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (dateRange) {
      setStartDate(format(dateRange[0], dateFormat));
      setEndDate(format(dateRange[1], dateFormat));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [dateRange]);

  useEffect(() => {
    if (firstLoading) {
      page === 1 ? fetchItems() : setPage(1);
    }
  }, [ordering]);

  return (
    <>
      <Container>
        <Title>{t('deals.statistics', { ns: 'deals' })}</Title>
        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                              GroupBy                              //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <Groups>
          <Group
            isActive={groupBy === 'modified_at_date'}
            onClick={() => {
              setGroupBy('modified_at_date');
              setOrdering('-modified_at_date');
            }}>
            {t('deals.date', { ns: 'deals' })}
          </Group>
          <Group
            isActive={groupBy === 'cid'}
            onClick={() => {
              setGroupBy('cid');
              setOrdering('cid');
            }}>
            {t('deals.catalog', { ns: 'deals' })}
          </Group>
          <Group
            isActive={groupBy === 'country'}
            onClick={() => {
              setGroupBy('country');
              setOrdering('country');
            }}>
            {t('deals.country', { ns: 'deals' })}
          </Group>
          <Group
            isActive={groupBy === 'os'}
            onClick={() => {
              setGroupBy('os');
              setOrdering('os');
            }}>
            OS
          </Group>
          <Group
            isActive={groupBy === 'browser'}
            onClick={() => {
              setGroupBy('browser');
              setOrdering('browser');
            }}>
            {t('deals.browser', { ns: 'deals' })}
          </Group>
          <Group
            isActive={groupBy === 'subid1'}
            onClick={() => {
              setGroupBy('subid1');
              setOrdering('subid1');
            }}>
            SubID1
          </Group>
          <Group
            isActive={groupBy === 'subid2'}
            onClick={() => {
              setGroupBy('subid2');
              setOrdering('subid2');
            }}>
            SubID2
          </Group>
          <Group
            isActive={groupBy === 'subid3'}
            onClick={() => {
              setGroupBy('subid3');
              setOrdering('subid3');
            }}>
            SubID3
          </Group>
          <Group
            isActive={groupBy === 'subid4'}
            onClick={() => {
              setGroupBy('subid4');
              setOrdering('subid4');
            }}>
            SubID4
          </Group>
          <Group
            isActive={groupBy === 'subid5'}
            onClick={() => {
              setGroupBy('subid5');
              setOrdering('subid5');
            }}>
            SubID5
          </Group>
          <Group
            isActive={groupBy === 'subid6'}
            onClick={() => {
              setGroupBy('subid6');
              setOrdering('subid6');
            }}>
            SubID6
          </Group>
          <Group
            isActive={groupBy === 'subid7'}
            onClick={() => {
              setGroupBy('subid7');
              setOrdering('subid7');
            }}>
            SubID7
          </Group>
          <Group
            isActive={groupBy === 'subid8'}
            onClick={() => {
              setGroupBy('subid8');
              setOrdering('subid8');
            }}>
            SubID8
          </Group>
          <Group
            isActive={groupBy === 'subid9'}
            onClick={() => {
              setGroupBy('subid9');
              setOrdering('subid9');
            }}>
            SubID9
          </Group>
          <Group
            isActive={groupBy === 'subid10'}
            onClick={() => {
              setGroupBy('subid10');
              setOrdering('subid10');
            }}>
            SubID10
          </Group>
          <Group
            isActive={groupBy === 'subid11'}
            onClick={() => {
              setGroupBy('subid11');
              setOrdering('subid11');
            }}>
            SubID11
          </Group>
          <Group
            isActive={groupBy === 'subid12'}
            onClick={() => {
              setGroupBy('subid12');
              setOrdering('subid12');
            }}>
            SubID12
          </Group>
          <Group
            isActive={groupBy === 'subid13'}
            onClick={() => {
              setGroupBy('subid13');
              setOrdering('subid13');
            }}>
            SubID13
          </Group>
          <Group
            isActive={groupBy === 'subid14'}
            onClick={() => {
              setGroupBy('subid14');
              setOrdering('subid14');
            }}>
            SubID14
          </Group>
          <Group
            isActive={groupBy === 'subid15'}
            onClick={() => {
              setGroupBy('subid15');
              setOrdering('subid15');
            }}>
            SubID15
          </Group>
          <Group
            isActive={groupBy === 'subid16'}
            onClick={() => {
              setGroupBy('subid16');
              setOrdering('subid16');
            }}>
            SubID16
          </Group>
          <Group
            isActive={groupBy === 'subid17'}
            onClick={() => {
              setGroupBy('subid17');
              setOrdering('subid17');
            }}>
            SubID17
          </Group>
          <Group
            isActive={groupBy === 'subid18'}
            onClick={() => {
              setGroupBy('subid18');
              setOrdering('subid18');
            }}>
            SubID18
          </Group>
          <Group
            isActive={groupBy === 'utm_source'}
            onClick={() => {
              setGroupBy('utm_source');
              setOrdering('utm_source');
            }}>
            utm_source
          </Group>
          <Group
            isActive={groupBy === 'utm_medium'}
            onClick={() => {
              setGroupBy('utm_medium');
              setOrdering('utm_medium');
            }}>
            utm_medium
          </Group>
          <Group
            isActive={groupBy === 'utm_campaign'}
            onClick={() => {
              setGroupBy('utm_campaign');
              setOrdering('utm_campaign');
            }}>
            utm_campaign
          </Group>
          <Group
            isActive={groupBy === 'utm_content'}
            onClick={() => {
              setGroupBy('utm_content');
              setOrdering('utm_content');
            }}>
            utm_content
          </Group>
          <Group
            isActive={groupBy === 'utm_term'}
            onClick={() => {
              setGroupBy('utm_term');
              setOrdering('utm_term');
            }}>
            utm_term
          </Group>
        </Groups>
        <Title>{t('deals.filter.title', { ns: 'deals' })}</Title>

        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                              Filters                              //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <TableSectionFilter>
          {optionsCatalog ? (
            <>
              <DateRangeSelector value={dateRange} setValue={setDateRange} />
              <Select
                options={optionsCatalog}
                value={catalog}
                onChange={(value) => setCatalog(value.toString())}
              />
              <Select
                options={optionsOffer}
                value={offer}
                onChange={(value) => setOffer(value.toString())}
                disabled={Object.keys(optionsOffer).length < 2 || pendingOffers}
              />
              <Select
                options={optionsCountry}
                value={country}
                onChange={(value) => setCountry(value.toString())}
                disabled={Object.keys(optionsCountry).length < 2}
              />
              <Select
                options={optionsOs}
                value={os}
                onChange={(value) => setOs(value.toString())}
                disabled={Object.keys(optionsOs).length < 2}
              />
              <Select
                options={optionsBrowser}
                value={browser}
                onChange={(value) => setBrowser(value.toString())}
                disabled={Object.keys(optionsBrowser).length < 2}
              />
              {isAllFilters && (
                <>
                  <Select
                    options={optionsSubid1}
                    value={subid1}
                    onChange={(value) => setSubid1(value.toString())}
                    disabled={Object.keys(optionsSubid1).length < 2}
                  />
                  <Select
                    options={optionsSubid2}
                    value={subid2}
                    onChange={(value) => setSubid2(value.toString())}
                    disabled={Object.keys(optionsSubid2).length < 2}
                  />
                  <Select
                    options={optionsSubid3}
                    value={subid3}
                    onChange={(value) => setSubid3(value.toString())}
                    disabled={Object.keys(optionsSubid3).length < 2}
                  />
                  <Select
                    options={optionsSubid4}
                    value={subid4}
                    onChange={(value) => setSubid4(value.toString())}
                    disabled={Object.keys(optionsSubid4).length < 2}
                  />
                  <Select
                    options={optionsSubid5}
                    value={subid5}
                    onChange={(value) => setSubid5(value.toString())}
                    disabled={Object.keys(optionsSubid5).length < 2}
                  />
                  <Select
                    options={optionsSubid6}
                    value={subid6}
                    onChange={(value) => setSubid6(value.toString())}
                    disabled={Object.keys(optionsSubid6).length < 2}
                  />
                  <Select
                    options={optionsSubid7}
                    value={subid7}
                    onChange={(value) => setSubid7(value.toString())}
                    disabled={Object.keys(optionsSubid7).length < 2}
                  />
                  <Select
                    options={optionsSubid8}
                    value={subid8}
                    onChange={(value) => setSubid8(value.toString())}
                    disabled={Object.keys(optionsSubid8).length < 2}
                  />
                  <Select
                    options={optionsSubid9}
                    value={subid9}
                    onChange={(value) => setSubid9(value.toString())}
                    disabled={Object.keys(optionsSubid9).length < 2}
                  />
                  <Select
                    options={optionsSubid10}
                    value={subid10}
                    onChange={(value) => setSubid10(value.toString())}
                    disabled={Object.keys(optionsSubid10).length < 2}
                  />
                  <Select
                    options={optionsSubid11}
                    value={subid11}
                    onChange={(value) => setSubid11(value.toString())}
                    disabled={Object.keys(optionsSubid11).length < 2}
                  />
                  <Select
                    options={optionsSubid12}
                    value={subid12}
                    onChange={(value) => setSubid12(value.toString())}
                    disabled={Object.keys(optionsSubid12).length < 2}
                  />
                  <Select
                    options={optionsSubid13}
                    value={subid13}
                    onChange={(value) => setSubid13(value.toString())}
                    disabled={Object.keys(optionsSubid13).length < 2}
                  />
                  <Select
                    options={optionsSubid14}
                    value={subid14}
                    onChange={(value) => setSubid14(value.toString())}
                    disabled={Object.keys(optionsSubid14).length < 2}
                  />
                  <Select
                    options={optionsSubid15}
                    value={subid15}
                    onChange={(value) => setSubid15(value.toString())}
                    disabled={Object.keys(optionsSubid15).length < 2}
                  />
                  <Select
                    options={optionsSubid16}
                    value={subid16}
                    onChange={(value) => setSubid16(value.toString())}
                    disabled={Object.keys(optionsSubid16).length < 2}
                  />
                  <Select
                    options={optionsSubid17}
                    value={subid17}
                    onChange={(value) => setSubid17(value.toString())}
                    disabled={Object.keys(optionsSubid17).length < 2}
                  />
                  <Select
                    options={optionsSubid18}
                    value={subid18}
                    onChange={(value) => setSubid18(value.toString())}
                    disabled={Object.keys(optionsSubid18).length < 2}
                  />

                  <Select
                    options={optionsUtmSource}
                    value={utmSource}
                    onChange={(value) => setUtmSource(value.toString())}
                    disabled={Object.keys(optionsUtmSource).length < 2}
                  />
                  <Select
                    options={optionsUtmMedium}
                    value={utmMedium}
                    onChange={(value) => setUtmMedium(value.toString())}
                    disabled={Object.keys(optionsUtmMedium).length < 2}
                  />
                  <Select
                    options={optionsUtmCampaign}
                    value={utmCampaign}
                    onChange={(value) => setUtmCampaign(value.toString())}
                    disabled={Object.keys(optionsUtmCampaign).length < 2}
                  />
                  <Select
                    options={optionsUtmContent}
                    value={utmContent}
                    onChange={(value) => setUtmContent(value.toString())}
                    disabled={Object.keys(optionsUtmContent).length < 2}
                  />
                  <Select
                    options={optionsUtmTerm}
                    value={utmTerm}
                    onChange={(value) => setUtmTerm(value.toString())}
                    disabled={Object.keys(optionsUtmTerm).length < 2}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <SelectSkeleton />
              <SelectSkeleton />
              <SelectSkeleton />
              <SelectSkeleton />
              <SelectSkeleton />
              <SelectSkeleton />
            </>
          )}
        </TableSectionFilter>
        <TableSectionFilter>
          {optionsCatalog ? (
            <>
              {isAllFilters ? (
                <Button onClick={() => setIsAllFilters(false)} maxWidth={'full'}>
                  {t('common.leave_main_filters', { ns: 'common' })}
                </Button>
              ) : (
                <Button onClick={() => setIsAllFilters(true)} maxWidth={'full'}>
                  {t('common.show_all_filters', { ns: 'common' })}
                </Button>
              )}
              <Button
                variant={'basic'}
                maxWidth={'full'}
                onClick={clearFilter}
                disabled={
                  dateRange === undefined &&
                  catalog === 'none' &&
                  country === 'none' &&
                  os === 'none' &&
                  browser === 'none' &&
                  subid1 === 'none' &&
                  subid2 === 'none' &&
                  subid3 === 'none' &&
                  subid4 === 'none' &&
                  subid5 === 'none' &&
                  subid6 === 'none' &&
                  subid7 === 'none' &&
                  subid8 === 'none' &&
                  subid9 === 'none' &&
                  subid10 === 'none' &&
                  subid11 === 'none' &&
                  subid12 === 'none' &&
                  subid13 === 'none' &&
                  subid14 === 'none' &&
                  subid15 === 'none' &&
                  subid16 === 'none' &&
                  subid17 === 'none' &&
                  subid18 === 'none' &&
                  utmSource === 'none' &&
                  utmMedium === 'none' &&
                  utmCampaign === 'none' &&
                  utmContent === 'none' &&
                  utmTerm === 'none'
                }>
                {t('common.clear_all', { ns: 'common' })}
              </Button>
              <Button variant={'accent'} maxWidth={'full'} onClick={submitFilter}>
                {t('common.apply', { ns: 'common' })}
              </Button>
            </>
          ) : (
            <>
              <SelectSkeleton />
              <SelectSkeleton />
              <SelectSkeleton />
            </>
          )}
        </TableSectionFilter>
      </Container>
      {
        //-------------------------------------------------------------------//
        //                                                                   //
        //                            Table Head                             //
        //                                                                   //
        //-------------------------------------------------------------------//
      }
      <TableWrapper>
        <TableRow gridTemplateColumns={'130px 140px 140px 300px 480px'}>
          <TableItem isChildren>
            <TableParentTitle>{t('deals.sort', { ns: 'deals' })}</TableParentTitle>
            <TableChildren gridTemplateColumns={'130'}>
              <TableItem backgroundColor={'dark-blue'} noRightBorder height={40}>
                {groupBy === 'modified_at_date' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-modified_at_date'
                        ? setOrdering('modified_at_date')
                        : setOrdering('-modified_at_date')
                    }>
                    {t('deals.date', { ns: 'deals' })}
                  </TableSorting>
                )}
                {groupBy === 'cid' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-cid' ? setOrdering('cid') : setOrdering('-cid')
                    }>
                    {t('deals.catalog', { ns: 'deals' })}
                  </TableSorting>
                )}
                {groupBy === 'country' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-country' ? setOrdering('country') : setOrdering('-country')
                    }>
                    {t('deals.country', { ns: 'deals' })}
                  </TableSorting>
                )}
                {groupBy === 'os' && (
                  <TableSorting
                    onClick={() => (ordering === '-os' ? setOrdering('os') : setOrdering('-os'))}>
                    OS
                  </TableSorting>
                )}
                {groupBy === 'browser' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-browser' ? setOrdering('browser') : setOrdering('-browser')
                    }>
                    {t('deals.browser', { ns: 'deals' })}
                  </TableSorting>
                )}
                {groupBy === 'subid1' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid1' ? setOrdering('subid1') : setOrdering('-subid1')
                    }>
                    SubID1
                  </TableSorting>
                )}
                {groupBy === 'subid2' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid2' ? setOrdering('subid2') : setOrdering('-subid2')
                    }>
                    SubID2
                  </TableSorting>
                )}
                {groupBy === 'subid3' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid3' ? setOrdering('subid3') : setOrdering('-subid3')
                    }>
                    SubID3
                  </TableSorting>
                )}
                {groupBy === 'subid4' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid4' ? setOrdering('subid4') : setOrdering('-subid4')
                    }>
                    SubID4
                  </TableSorting>
                )}
                {groupBy === 'subid5' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid5' ? setOrdering('subid5') : setOrdering('-subid5')
                    }>
                    SubID5
                  </TableSorting>
                )}
                {groupBy === 'subid6' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid6' ? setOrdering('subid6') : setOrdering('-subid6')
                    }>
                    SubID6
                  </TableSorting>
                )}
                {groupBy === 'subid7' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid7' ? setOrdering('subid7') : setOrdering('-subid7')
                    }>
                    SubID7
                  </TableSorting>
                )}
                {groupBy === 'subid8' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid8' ? setOrdering('subid8') : setOrdering('-subid8')
                    }>
                    SubID8
                  </TableSorting>
                )}
                {groupBy === 'subid9' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid9' ? setOrdering('subid9') : setOrdering('-subid9')
                    }>
                    SubID9
                  </TableSorting>
                )}
                {groupBy === 'subid10' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid10' ? setOrdering('subid10') : setOrdering('-subid10')
                    }>
                    SubID10
                  </TableSorting>
                )}
                {groupBy === 'subid11' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid11' ? setOrdering('subid11') : setOrdering('-subid11')
                    }>
                    SubID11
                  </TableSorting>
                )}
                {groupBy === 'subid12' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid12' ? setOrdering('subid12') : setOrdering('-subid12')
                    }>
                    SubID12
                  </TableSorting>
                )}
                {groupBy === 'subid13' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid13' ? setOrdering('subid13') : setOrdering('-subid13')
                    }>
                    SubID13
                  </TableSorting>
                )}
                {groupBy === 'subid14' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid14' ? setOrdering('subid14') : setOrdering('-subid14')
                    }>
                    SubID14
                  </TableSorting>
                )}
                {groupBy === 'subid15' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid15' ? setOrdering('subid15') : setOrdering('-subid15')
                    }>
                    SubID15
                  </TableSorting>
                )}
                {groupBy === 'subid16' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid16' ? setOrdering('subid16') : setOrdering('-subid16')
                    }>
                    SubID16
                  </TableSorting>
                )}
                {groupBy === 'subid17' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid17' ? setOrdering('subid17') : setOrdering('-subid17')
                    }>
                    SubID17
                  </TableSorting>
                )}
                {groupBy === 'subid18' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-subid18' ? setOrdering('subid18') : setOrdering('-subid18')
                    }>
                    SubID18
                  </TableSorting>
                )}
                {groupBy === 'utm_source' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-utm_source'
                        ? setOrdering('utm_source')
                        : setOrdering('-utm_source')
                    }>
                    utm_source
                  </TableSorting>
                )}
                {groupBy === 'utm_medium' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-utm_medium'
                        ? setOrdering('utm_medium')
                        : setOrdering('-utm_medium')
                    }>
                    utm_medium
                  </TableSorting>
                )}
                {groupBy === 'utm_campaign' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-utm_campaign'
                        ? setOrdering('utm_campaign')
                        : setOrdering('-utm_campaign')
                    }>
                    utm_campaign
                  </TableSorting>
                )}
                {groupBy === 'utm_content' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-utm_content'
                        ? setOrdering('utm_content')
                        : setOrdering('-utm_content')
                    }>
                    utm_campaign
                  </TableSorting>
                )}
                {groupBy === 'utm_term' && (
                  <TableSorting
                    onClick={() =>
                      ordering === '-utm_term' ? setOrdering('utm_term') : setOrdering('-utm_term')
                    }>
                    utm_term
                  </TableSorting>
                )}
              </TableItem>
            </TableChildren>
          </TableItem>

          <TableItem isChildren>
            <TableParentTitle>{t('deals.redirect', { ns: 'deals' })}</TableParentTitle>
            <TableChildren gridTemplateColumns={'70px 70px'}>
              <TableItem backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-clicks' ? setOrdering('clicks') : setOrdering('-clicks')
                  }>
                  {t('deals.clicks', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem backgroundColor={'dark-blue'} height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-unique_clicks'
                      ? setOrdering('unique_clicks')
                      : setOrdering('-unique_clicks')
                  }>
                  {t('deals.unique', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>

          <TableItem isChildren>
            <TableParentTitle>{t('deals.conversions', { ns: 'deals' })}</TableParentTitle>
            <TableChildren gridTemplateColumns={'70px 70px'}>
              <TableItem backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-created_rate'
                      ? setOrdering('created_rate')
                      : setOrdering('-created_rate')
                  }>
                  CR%
                </TableSorting>
              </TableItem>
              <TableItem backgroundColor={'dark-blue'} height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-approval_rate'
                      ? setOrdering('approval_rate')
                      : setOrdering('-approval_rate')
                  }>
                  AR%
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>

          <TableItem isChildren>
            <TableParentTitle>{t('deals.deals', { ns: 'deals' })}</TableParentTitle>
            <TableChildren gridTemplateColumns={'60px 60px 60px 60px 60px'}>
              <TableItemIcon backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_all_status'
                      ? setOrdering('count_by_all_status')
                      : setOrdering('-count_by_all_status')
                  }>
                  <SvgSumSignWhite />
                </TableSorting>
              </TableItemIcon>
              <TableItemIcon backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_created'
                      ? setOrdering('count_by_created')
                      : setOrdering('-count_by_created')
                  }>
                  <SvgCreateWhite />
                </TableSorting>
              </TableItemIcon>
              <TableItemIcon backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_paid'
                      ? setOrdering('count_by_paid')
                      : setOrdering('-count_by_paid')
                  }>
                  <SvgInterfaceLoading />
                </TableSorting>
              </TableItemIcon>
              <TableItemIcon backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_approved'
                      ? setOrdering('count_by_approved')
                      : setOrdering('-count_by_approved')
                  }>
                  <SvgCheckRectangleWhite />
                </TableSorting>
              </TableItemIcon>
              <TableItemIcon backgroundColor={'dark-blue'} height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-count_by_canceled'
                      ? setOrdering('count_by_canceled')
                      : setOrdering('-count_by_canceled')
                  }>
                  <SvgCloseRectangleWhite />
                </TableSorting>
              </TableItemIcon>
            </TableChildren>
          </TableItem>

          <TableItem isChildren>
            <TableParentTitle>{t('deals.finance', { ns: 'deals' })}</TableParentTitle>
            <TableChildren gridTemplateColumns={'120px 120px 120px 120px'}>
              <TableItem backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_paid_usd'
                      ? setOrdering('sum_paid_usd')
                      : setOrdering('-sum_paid_usd')
                  }>
                  ($) {t('deals.hold', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_approved_usd'
                      ? setOrdering('sum_approved_usd')
                      : setOrdering('-sum_approved_usd')
                  }>
                  ($) {t('deals.approved_short', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem backgroundColor={'dark-blue'} noRightBorder height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_paid_rub'
                      ? setOrdering('sum_paid_rub')
                      : setOrdering('-sum_paid_rub')
                  }>
                  (₽) {t('deals.hold', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
              <TableItem backgroundColor={'dark-blue'} height={40}>
                <TableSorting
                  onClick={() =>
                    ordering === '-sum_approved_rub'
                      ? setOrdering('sum_approved_rub')
                      : setOrdering('-sum_approved_rub')
                  }>
                  (₽) {t('deals.approved_short', { ns: 'deals' })}
                </TableSorting>
              </TableItem>
            </TableChildren>
          </TableItem>
        </TableRow>

        {pending ? (
          <SkeletonTable
            gridTemplateColumns={
              '130px 70px 70px 70px 70px 60px 60px 60px 60px 60px 120px 120px 120px 120px'
            }
          />
        ) : (
          <>
            {deals.length > 0 ? (
              deals.map((day) => (
                <AccountStatisticsTableItem key={day.modified_at_date} day={day} />
              ))
            ) : (
              <TableNoData icon={<SvgNoDeals />} title={t('deals.no_data', { ns: 'deals' })} />
            )}
          </>
        )}
      </TableWrapper>
      {deals && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
