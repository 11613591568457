import axios from 'axios';
import { RefreshDto } from '@partnerka-front/types';
import { toast } from 'react-toast';
import * as adsTemplate from './ads-template';
import * as auth from './auth';
import * as businessPartner from './business-partner';
import * as catalog from './catalog';
import * as deal from './deal';
import * as info from './info';
import * as landing from './landing';
import * as linkConstructor from './link-constructor';
import * as offer from './offer';
import * as postback from './postback';
import * as postbackFieldEnum from './postback-field-enum';
import * as postbackLog from './postback-log';
import * as promo from './promo';
import * as promoMaterial from './promo-material';
import * as textRule from './text-rule';

export const API_URL = 'https://' + process.env.REACT_APP_API_URL;

let lang = 'ru';
let badConnection = 'Слишком слабое подключение. Повторите попытку позже.';

export function changeLang(new_lang: string, bad_connection: string) {
  lang = new_lang;
  badConnection = bad_connection;
}

export const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers = {
      Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
      'Content-Language': lang,
      'Content-Type': 'application/json; charset=utf-8',
    };
  }
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const refresh_token = localStorage.getItem('refresh');

        if (refresh_token) {
          const response = await axios.post<RefreshDto>(`${API_URL}/auth/jwt/refresh/`, {
            refresh: refresh_token,
          });
          localStorage.setItem('token', response.data.access);
          return $api.request(originalRequest);
        }
      } catch (e) {
        localStorage.removeItem('refresh');
        localStorage.removeItem('token');
        window.location.reload();
      }
    }

    if (error.response.data === undefined) {
      toast.error(badConnection);
    }

    throw error;
  }
);

export const Api = {
  // ~~ Ads template

  getAdsTemplate: adsTemplate.getAdsTemplate,

  // ~~ Auth

  getUser: auth.getUser,
  login: auth.login,
  registration: auth.registration,
  resendActivation: auth.resendActivation,
  activation: auth.activation,
  resetPassword: auth.resetPassword,
  resetPasswordConfirm: auth.resetPasswordConfirm,

  // ~~ Business partner

  getCompany: businessPartner.getCompany,
  patchCompany: businessPartner.patchCompany,
  getPartners: businessPartner.getPartners,
  getBusinessStatistic: businessPartner.getBusinessStatistic,
  getBusinessCatalog: businessPartner.getBusinessCatalog,
  getBusinessCatalogs: businessPartner.getBusinessCatalogs,
  getBusinessFinances: businessPartner.getBusinessFinances,
  getBusinessDeals: businessPartner.getBusinessDeals,
  refreshBusinessToken: businessPartner.refreshBusinessToken,

  // ~~ Catalog

  getCatalogs: catalog.getCatalogs,
  getUserCatalogs: catalog.getUserCatalogs,
  getCatalog: catalog.getCatalog,
  patchCatalog: catalog.patchCatalog,
  addUserCatalog: catalog.addUserCatalog,
  createCatalog: catalog.createCatalog,
  patchSettingRuleTraffic: catalog.patchSettingRuleTraffic,
  getCatalogUserWithDeals: catalog.getCatalogUserWithDeals,
  getOfferUserWithDeals: catalog.getOfferUserWithDeals,
  // # один и тот же поинт

  // ~~ Deal

  getStatistics: deal.getStatistics,
  getDeals: deal.getDeals,
  getOldDeals: deal.getOldDeals,
  getGeneralStatisticsDeals: deal.getGeneralStatisticsDeals,
  getGeneralStatisticsUser: deal.getGeneralStatisticsUser,
  getParamsWithDeals: deal.getParamsWithDeals,

  // ~~ Info

  getInfo: info.getInfo,

  // ~~ Landing

  createLanding: landing.createLanding,
  patchLanding: landing.patchLanding,
  deleteLanding: landing.deleteLanding,

  // ~~ Link constructor

  createLink: linkConstructor.createLink,
  patchLink: linkConstructor.patchLink,
  deleteLink: linkConstructor.deleteLink,

  // ~~ Offer

  createOffer: offer.createOffer,
  patchOffer: offer.patchOffer,

  // ~~ Postback

  getPostbackList: postback.getPostbackList,
  getPostback: postback.getPostback,
  createPostback: postback.createPostback,
  patchPostback: postback.patchPostback,
  deletePostback: postback.deletePostback,
  testPostback: postback.testPostback,
  getPostbackTemplates: postback.getPostbackTemplates,

  // ~~ Postback Field Enum

  getPostbackFieldEnum: postbackFieldEnum.getPostbackFieldEnum,

  // ~~ Postback Logs

  getPostbackLogs: postbackLog.getPostbackLogs,

  // ~~ Promo

  createPromo: promo.createPromo,
  createCoupon: promo.createCoupon,
  patchPromo: promo.patchPromo,
  patchCoupon: promo.patchCoupon,

  // ~~ Promo material

  createPromoMaterial: promoMaterial.createPromoMaterial,
  patchPromoMaterial: promoMaterial.patchPromoMaterial,
  deletePromoMaterial: promoMaterial.deletePromoMaterial,

  // ~~ Text Rule

  createTextRule: textRule.createTextRule,
  patchTextRule: textRule.patchTextRule,
  deleteTextRule: textRule.deleteTextRule,
};
