import styled from 'styled-components';
import { Row } from '@partnerka-front/components';
import { down } from 'styled-breakpoints';

export const PostbackForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 24px;

  ${down('xl')} {
    grid-template-columns: 1fr;
  }
`;

export const PostbackSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const PostbackItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.inactiveGrey};

  &:last-child {
    padding-bottom: 0;
  }
`;

const PostbackSkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 24px;
`;

const PostbackSkeletonFields = styled.div`
  width: 100%;
  height: 510px;

  background: linear-gradient(110deg, #f2f4f8 8%, #fff 18%, #f2f4f8 33%);
  background-size: 200% 100%;
  border-radius: 6px;
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);
  animation: 1.5s shine linear infinite;
`;

const BtnSkeleton = styled.div`
  width: 100%;
  height: 50px;

  background: linear-gradient(110deg, #f2f4f8 8%, #fff 18%, #f2f4f8 33%);
  background-size: 200% 100%;
  border-radius: 5px;
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);
  animation: 1.5s shine linear infinite;
`;

const LinkSkeleton = styled.div`
  width: 100%;
  height: 313px;

  background: linear-gradient(110deg, #f2f4f8 8%, #fff 18%, #f2f4f8 33%);
  background-size: 200% 100%;
  border-radius: 6px;
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);
  animation: 1.5s shine linear infinite;
`;

export function PostbackSkeleton() {
  return (
    <PostbackSkeletonWrapper>
      <Row gap={24}>
        <PostbackSkeletonFields />
        <PostbackSkeletonFields />
      </Row>
      <Row gap={24}>
        <BtnSkeleton />
        <LinkSkeleton />
      </Row>
    </PostbackSkeletonWrapper>
  );
}
