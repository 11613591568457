import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Api } from '@partnerka-front/api';
import {
  FormAuth,
  TitleH2,
  Paragraph,
  ButtonSubmit,
  Message,
  PageLoading,
  PageAuth,
  FooterAuth,
  ControlledInput,
} from '@partnerka-front/components';
import { ResponseError } from '@partnerka-front/types';
import { SvgPassword } from '@partnerka-front/icons';
import { regExpPasswordCharacter } from '@partnerka-front/utils';

interface PasswordResetConfirmPage {
  isBusiness?: boolean;
}

export function PasswordResetConfirmPage({ isBusiness }: PasswordResetConfirmPage) {
  const { t } = useTranslation(['auth', 'validation', 'errors', 'common']);
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(
        regExpPasswordCharacter,
        `${t('validation.invalid_character', { ns: 'validation' })}`
      )
      .min(
        8,
        `${t('validation.password_must_be_at_least_characters', {
          characters: '8',
          ns: 'validation',
        })}`
      )
      .max(
        64,
        `${t('validation.password_must_be_at_most_characters', {
          characters: '64',
          ns: 'validation',
        })}`
      ),
  });
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      password: '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const submit = handleSubmit(async (data) => {
    if (uid && token) {
      setPending(true);
      await Api.resetPasswordConfirm({
        uid,
        token,
        new_password: data.password,
      })
        .then(() => navigate('/password-reset/successfully'))
        .catch(setError);
    }
    setPending(false);
  });

  return (
    <PageAuth>
      <FormProvider {...formMethods}>
        <FormAuth onSubmit={submit} isBusiness={isBusiness}>
          {pending && <PageLoading />}
          <SvgPassword />
          <TitleH2>{t('auth.new_password_title', { ns: 'auth' })}</TitleH2>
          <Paragraph>{t('auth.new_password_description', { ns: 'auth' })}</Paragraph>
          <ControlledInput name={'password'} label={'Password'} type={'password'} />
          {error?.response.data && (
            <Message onClose={() => setError(null)}>
              {error.response.data.detail
                ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                : t('errors.try_again_later', { ns: 'errors' })}
            </Message>
          )}
          <ButtonSubmit disabled={!isValid}>{t('common.save', { ns: 'common' })}</ButtonSubmit>
        </FormAuth>
      </FormProvider>
      {!isBusiness && <FooterAuth />}
    </PageAuth>
  );
}
