import { useTranslation } from 'react-i18next';
import {
  ButtonHref,
  Line,
  Container,
  Paragraph,
  Title,
  TitleH2,
  TitleH4,
} from '@partnerka-front/components';

export function SupportPage() {
  const { t } = useTranslation(['support']);

  return (
    <Container>
      <Title>{t('support.title', { ns: 'support' })}</Title>
      <Paragraph>{t('support.description', { ns: 'support' })}</Paragraph>
      <Line>
        <ButtonHref href={'https://t.me/cyberhackGL'} variant={'active'}>
          {t('support.btn_telegram', { ns: 'support' })}
        </ButtonHref>
      </Line>
      <Paragraph>
        <TitleH2>{t('support.withdrawal_methods', { ns: 'support' })}</TitleH2>
      </Paragraph>
      <Paragraph>
        <TitleH4>{t('support.crypto', { ns: 'support' })}</TitleH4>
        <p>{t('support.cryptos_etc', { ns: 'support' })}</p>
      </Paragraph>
    </Container>
  );
}
