export function SvgEyeOpen() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 7.50377C11.841 7.50377 13.3333 8.99616 13.3333 10.8371C13.3333 12.6781 11.841 14.1704 10 14.1704C8.15907 14.1704 6.66668 12.6781 6.66668 10.8371C6.66668 8.99616 8.15907 7.50377 10 7.50377ZM10 8.75377C8.84942 8.75377 7.91668 9.68651 7.91668 10.8371C7.91668 11.9877 8.84942 12.9204 10 12.9204C11.1506 12.9204 12.0833 11.9877 12.0833 10.8371C12.0833 9.68651 11.1506 8.75377 10 8.75377ZM10 4.58325C13.8446 4.58325 17.1634 7.20826 18.0843 10.8869C18.1681 11.2218 17.9646 11.5612 17.6298 11.645C17.2949 11.7288 16.9555 11.5253 16.8717 11.1904C16.0893 8.06488 13.2678 5.83325 10 5.83325C6.73079 5.83325 3.90843 8.06681 3.1274 11.1942C3.04376 11.5291 2.70448 11.7328 2.36959 11.6492C2.03469 11.5655 1.83101 11.2262 1.91465 10.8913C2.83389 7.21052 6.15375 4.58325 10 4.58325Z"
        fill="#2356F6"
      />
    </svg>
  );
}
