export function SvgTools() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.25 4.89V19.11C10.25 19.6919 10.1185 19.9062 10.0102 20.0037C9.88155 20.1194 9.59019 20.25 8.90118 20.25H5.09882C4.40981 20.25 4.11845 20.1194 3.98984 20.0037C3.88147 19.9062 3.75 19.6919 3.75 19.11V4.89C3.75 4.30812 3.88147 4.09382 3.98984 3.99633C4.11845 3.88063 4.40981 3.75 5.09882 3.75H8.90118C9.59019 3.75 9.88155 3.88063 10.0102 3.99633C10.1185 4.09382 10.25 4.30812 10.25 4.89Z"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9059 10.2499V10.2499H18.9012H15.0988C14.4066 10.2499 14.114 10.1212 13.9856 10.0074C13.8806 9.91441 13.75 9.70845 13.75 9.13418V4.8657C13.75 4.29769 13.8799 4.09002 13.9867 3.995C14.1159 3.88007 14.4093 3.75 15.0988 3.75H18.9012C19.5907 3.75 19.8841 3.88007 20.0133 3.995C20.1201 4.09002 20.25 4.29769 20.25 4.8657V9.14361C20.25 9.71217 20.1199 9.91869 20.0144 10.0122C19.8868 10.1252 19.595 10.2542 18.9059 10.2499Z"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 15.0988V18.9012C20.25 19.5743 20.1101 19.8528 19.9814 19.9814C19.8528 20.1101 19.5743 20.25 18.9012 20.25H15.0988C14.4257 20.25 14.1472 20.1101 14.0186 19.9814C13.8899 19.8528 13.75 19.5743 13.75 18.9012V15.0988C13.75 14.4257 13.8899 14.1472 14.0186 14.0186C14.1472 13.8899 14.4257 13.75 15.0988 13.75H18.9012C19.5743 13.75 19.8528 13.8899 19.9814 14.0186C20.1101 14.1472 20.25 14.4257 20.25 15.0988Z"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
