import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { TitleH4 } from '@partnerka-front/components';
import { SvgArrows } from '@partnerka-front/icons';

interface CardProps {
  size?: 'default' | 'mini';
  alignSelf?: 'flex-start';
  childrenBottom?: number;
}

// TODO оставить для экспорта только этот
export const Card = styled.div<CardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ alignSelf }) => {
    if (alignSelf === 'flex-start') return 'align-self: flex-start';
  }};
  width: 100%;
  padding: ${({ size }) => {
    if (size === 'mini') return '10px';
    return '24px';
  }};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);

  & > * {
    ${({ childrenBottom }) => {
      if (childrenBottom) return `margin-bottom: ${childrenBottom}px`;
    }};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CardTitle = styled.h4`
  display: block;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 16px;
`;

export const CardItem = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface CardItemTitleProps {
  paddingTop?: number;
}

export const CardItemTitle = styled.label<CardItemTitleProps>`
  display: flex;
  gap: 4px;
  padding-top: ${({ paddingTop }) => {
    if (paddingTop === 0) return '0';
    if (paddingTop) return paddingTop + 'px';
    return '10px';
  }};
  padding-right: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const CardHelp = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 8px 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.basic};
  font-size: 12px;
  line-height: 120%;

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;
  }
`;

const CardDropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 40px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CardDropdownHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const CardDropdownTitle = styled(CardTitle)`
  margin-bottom: 0;
`;

interface DropdownProps {
  isShow: boolean;
}

const Dropdown = styled.div<DropdownProps>`
  font-size: 0;
  transform: ${({ isShow }) => (isShow ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: 0.3s;
  cursor: pointer;
`;

interface CardDropdownProps {
  title: string;
  children: ReactNode;
  titleRightSection?: ReactNode;
}

export function CardDropdown({ title, children, titleRightSection }: CardDropdownProps) {
  const [isShow, setIsShow] = useState(true);

  return (
    <Card>
      <CardDropdownHeader>
        <CardDropdownTitle>{title}</CardDropdownTitle>
        <CardDropdownHeaderRight>
          {titleRightSection}
          <Dropdown isShow={isShow} onClick={() => setIsShow(!isShow)}>
            <SvgArrows />
          </Dropdown>
        </CardDropdownHeaderRight>
      </CardDropdownHeader>
      {isShow && <div>{children}</div>}
    </Card>
  );
}

const CardDropdownInCardWrapper = styled.div`
  padding: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.inactiveGrey};

  &:last-child {
    padding-bottom: 0;
  }
`;

export function CardDropdownInCard({ title, children, titleRightSection }: CardDropdownProps) {
  const [isShow, setIsShow] = useState(true);

  return (
    <CardDropdownInCardWrapper>
      <CardDropdownHeader>
        <TitleH4>{title}</TitleH4>
        <CardDropdownHeaderRight>
          {titleRightSection}
          <Dropdown isShow={isShow} onClick={() => setIsShow(!isShow)}>
            <SvgArrows />
          </Dropdown>
        </CardDropdownHeaderRight>
      </CardDropdownHeader>
      {isShow && <div>{children}</div>}
    </CardDropdownInCardWrapper>
  );
}

const CardDropdownsCard = styled(Card)`
  padding: 0;
`;

const CardDropdownsHeader = styled(CardDropdownHeader)`
  min-height: 88px;
  margin-bottom: 0;
  padding: 24px;
`;

export function CardDropdowns({ title, titleRightSection, children }: CardDropdownProps) {
  const [isShow, setIsShow] = useState(true);

  return (
    <CardDropdownsCard>
      <CardDropdownsHeader>
        <CardDropdownTitle>{title}</CardDropdownTitle>
        <CardDropdownHeaderRight>
          {titleRightSection}
          <Dropdown isShow={isShow} onClick={() => setIsShow(!isShow)}>
            <SvgArrows />
          </Dropdown>
        </CardDropdownHeaderRight>
      </CardDropdownsHeader>
      {isShow && <div>{children}</div>}
    </CardDropdownsCard>
  );
}

const CardContentDropdownHeader = styled(CardDropdownHeader)<DropdownProps>`
  margin-bottom: 12px;
  padding: 10px 24px;
  border-radius: ${({ isShow }) => (isShow ? '0' : '0 0 6px 6px')};
  background-color: ${({ theme }) => theme.colors.basicLight};

  svg path {
    stroke: ${({ theme }) => theme.colors.secondary};
  }
`;

const CardContentDropdownTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
`;

const CardContentDropdownContent = styled.div`
  padding: 24px;
`;

export function CardContentDropdown({ title, children }: CardDropdownProps) {
  const [isShow, setIsShow] = useState(true);

  return (
    <div>
      <CardContentDropdownHeader isShow={isShow}>
        <CardContentDropdownTitle>{title}</CardContentDropdownTitle>
        <Dropdown isShow={isShow} onClick={() => setIsShow(!isShow)}>
          <SvgArrows />
        </Dropdown>
      </CardContentDropdownHeader>
      {isShow && <CardContentDropdownContent>{children}</CardContentDropdownContent>}
    </div>
  );
}
