export function SvgNoDeals() {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3963 14.7283C18.7162 10.4601 22.4357 7.25937 26.7039 7.57931L34.2302 8.14347C35.5966 8.2459 36.8664 8.88695 37.7603 9.92562L40.3301 12.912C41.224 13.9507 42.4937 14.5917 43.8601 14.6941L57.1827 15.6928C61.451 16.0127 64.6516 19.7322 64.3317 24.0004L62.4489 49.1174C62.129 53.3858 58.4097 56.5864 54.1413 56.2665L23.228 53.9492C18.9598 53.6293 15.7591 49.9099 16.079 45.6416L18.3963 14.7283Z"
        fill="#DBEAFE"
      />
      <path
        d="M13.25 20.5C13.25 16.2198 16.7198 12.75 21 12.75H28.5474C29.9177 12.75 31.2318 13.2943 32.2008 14.2633L34.9867 17.0492C35.9557 18.0182 37.2698 18.5625 38.64 18.5625H52C56.2803 18.5625 59.75 22.0323 59.75 26.3125V51.5C59.75 55.7803 56.2803 59.25 52 59.25H21C16.7198 59.25 13.25 55.7803 13.25 51.5V20.5Z"
        fill="white"
        stroke="#2356F6"
        strokeWidth="3"
      />
      <path
        d="M44.5 51C44.5 49.2292 43.8757 47.5084 42.7251 46.1074C41.5744 44.7063 39.9623 43.7042 38.1415 43.2581C36.3207 42.8119 34.3941 42.947 32.6636 43.6421C30.9331 44.3371 29.4965 45.553 28.5791 47.099L30.3217 48.015C31.0237 46.8321 32.1229 45.9017 33.447 45.3699C34.7712 44.838 36.2454 44.7347 37.6386 45.0761C39.0318 45.4174 40.2654 46.1842 41.1458 47.2563C42.0263 48.3283 42.504 49.645 42.504 51H44.5Z"
        fill="#2356F6"
      />
      <circle cx="45" cy="34.5" r="1.5" fill="#2356F6" />
      <circle cx="30" cy="32.5" r="3.5" fill="#DBEAFE" />
    </svg>
  );
}
