import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TableItem, TableRow } from '@partnerka-front/components';
import { PostbackDto, PostbackEvents } from '@partnerka-front/types';
import { SvgCancel, SvgEdit, SvgMonitor } from '@partnerka-front/icons';
import { useEventBus } from '@partnerka-front/utils';

const Actions = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 auto;
  font-size: 0;

  & > * {
    cursor: pointer;
  }
`;

interface PostbackTableItemProps {
  postback: PostbackDto;
}

export function PostbackTableItem({ postback }: PostbackTableItemProps) {
  const { t } = useTranslation(['postback', 'common', 'deals']);
  const { emit } = useEventBus();
  const navigate = useNavigate();

  return (
    <TableRow gridTemplateColumns={'80px 140px 180px 110px 1fr 120px'}>
      <TableItem>{postback.id}</TableItem>
      <TableItem>{postback.name}</TableItem>
      <TableItem>
        {postback.catalog.length > 1
          ? t('postback.select_offer_all_title', { ns: 'postback' })
          : postback.catalog[0]}
      </TableItem>
      <TableItem>
        {postback.status.map((status) => (
          <>
            {t(`deals.${status}`, { ns: 'deals' })}
            <br />
          </>
        ))}
      </TableItem>
      <TableItem minWidth={200}>
        {postback.postback_field.map((field, index) => (
          <>
            {field.key}
            {postback.postback_field.length === index + 1 ? '' : ', '}
          </>
        ))}
      </TableItem>
      <TableItem>
        <Actions>
          <div onClick={() => emit(PostbackEvents.openModalDeletePostback, postback.id)}>
            <SvgCancel />
          </div>
          <div onClick={() => navigate('/postback/' + postback.id)}>
            <SvgEdit />
          </div>
          <div
            onClick={() =>
              emit(PostbackEvents.openModalTestingPostback, {
                url: postback.url,
                postback_field: postback.postback_field,
              })
            }>
            <SvgMonitor />
          </div>
        </Actions>
      </TableItem>
    </TableRow>
  );
}
