import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Line = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 38px;

  &:last-child {
    margin-bottom: 0;
  }

  ${down('lg')} {
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
