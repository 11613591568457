export function SvgEditRectangle() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 5.33333C3 3.49238 4.49238 2 6.33333 2H14.6667C16.5076 2 18 3.49238 18 5.33333V13.6667C18 15.5076 16.5076 17 14.6667 17H6.33333C4.49238 17 3 15.5076 3 13.6667V5.33333Z"
        stroke="white"
      />
      <path
        d="M13.8332 7L9.6665 12L7.1665 9.72725"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
