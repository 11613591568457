import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  ControlledSelect,
  ControlledSimpleInput,
  Message,
  Select,
  SimpleInput,
} from '@partnerka-front/components';
import styled from 'styled-components';
import {
  AdsTemplateDto,
  Domain,
  LinkConstructorDto,
  ResponseError,
  UnknownFunction,
} from '@partnerka-front/types';
import { SvgPlusRectangle, SvgSetting } from '@partnerka-front/icons';
import { Api } from '@partnerka-front/api';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { regExpNoSpaces } from '@partnerka-front/utils';
import { down } from 'styled-breakpoints';

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 10px;

  ${down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const Item = styled.div`
  label {
    display: block;
    height: 20px;
    line-height: 20px;
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-bottom: 10px;

  ${down('sm')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const LinkItem = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  & > *:first-child {
    width: 100%;
  }
`;

const LinkPanel = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
  font-size: 0;
`;

const LinkPanelItem = styled.div`
  cursor: pointer;

  svg path {
    stroke: ${({ theme }) => theme.colors.accent};
  }
`;

const BtnSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 10px;

  ${down('sm')} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

interface CatalogLinkConstructorProps {
  id: string;
  link: LinkConstructorDto;
  domains: Domain[];
  landingId: string;
  updateCatalog: UnknownFunction;
  setCurrentEdit: (value: string | null) => void;
  onEdit?: (value: string | null) => void;
}

export function CatalogLinkConstructor({
  id,
  link,
  domains,
  landingId,
  updateCatalog,
  setCurrentEdit,
  onEdit,
}: CatalogLinkConstructorProps) {
  const { t } = useTranslation(['landing', 'common', 'validation']);

  const [count, setCount] = useState<number>(5);
  const [urlValue, setUrlValue] = useState('');
  const [isCheck, setIsCheck] = useState(false);
  const [templates, setTemplates] = useState<AdsTemplateDto[] | null>(null);
  const [templatesOptions, setTemplatesOptions] = useState<object>({
    '0': `${t('landing.own', { ns: 'landing' })}`,
  });
  const [valueTemplate, setValueTemplate] = useState(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link.ads_template?.id ?? templatesOptions['0']
  );
  const [error, setError] = useState<ResponseError | null>(null);
  const domainsOptions = domains.reduce(
    (obj, item) => Object.assign(obj, { [item.id]: item.domain }),
    {}
  );

  const formSchema = Yup.object().shape({
    subid: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid1: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid2: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid3: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid4: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid5: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid6: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid7: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid8: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid9: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid10: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid11: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid12: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid13: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid14: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid15: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid16: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid17: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
    subid18: Yup.string().matches(
      regExpNoSpaces,
      `${t('validation.no_spaces', { ns: 'validation' })}`
    ),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      domain: link.domain ? link.domain : domains[0].id,
      subid: link.subid ? link.subid : undefined,
      subid1: link.subid1 ? link.subid1 : undefined,
      subid2: link.subid2 ? link.subid2 : undefined,
      subid3: link.subid3 ? link.subid3 : undefined,
      subid4: link.subid4 ? link.subid4 : undefined,
      subid5: link.subid5 ? link.subid5 : undefined,
      subid6: link.subid6 ? link.subid6 : undefined,
      subid7: link.subid7 ? link.subid7 : undefined,
      subid8: link.subid8 ? link.subid8 : undefined,
      subid9: link.subid9 ? link.subid9 : undefined,
      subid10: link.subid10 ? link.subid10 : undefined,
      subid11: link.subid11 ? link.subid11 : undefined,
      subid12: link.subid12 ? link.subid12 : undefined,
      subid13: link.subid13 ? link.subid13 : undefined,
      subid14: link.subid14 ? link.subid14 : undefined,
      subid15: link.subid15 ? link.subid15 : undefined,
      subid16: link.subid16 ? link.subid16 : undefined,
      subid17: link.subid17 ? link.subid17 : undefined,
      subid18: link.subid18 ? link.subid18 : undefined,
    },
  });
  const { handleSubmit, watch, setValue, trigger, reset } = formMethods;

  const submitForm = handleSubmit(async (data) => {
    await Api.patchLink(link.id, {
      ...data,
      landing: landingId,
      ads_template:
        valueTemplate === '0' || valueTemplate === 'Own' || valueTemplate === 'Свой'
          ? null
          : valueTemplate,
    })
      .then(() => {
        updateCatalog();
        setCurrentEdit(null);
      })
      .catch(setError);
  });

  const determineCountSelects = () => {
    let maxCount = 5;
    let key: keyof typeof link;

    for (key in link) {
      if (key.includes('subid')) {
        let num = 0;
        const value = link[key];

        if (key === 'subid') {
          num = 19;
        } else {
          num = Number(key.replace('subid', ''));
        }

        if (value && maxCount < num) maxCount = num;
      }
    }

    setCount(maxCount);
  };

  const getAdsTemplate = async () => {
    await Api.getAdsTemplate()
      .then((res) => setTemplates(res.data))
      .catch(setError);
  };

  const handleUrl = () => {
    if (watch('domain')) {
      const idDomain = watch('domain');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const domain: string = domainsOptions[idDomain];

      let params = '';
      for (let i = 0; i < 18; i++) {
        const subid = `subid${i + 1}`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = watch(subid);
        if (value && typeof value === 'string') {
          if (params) {
            params = params + '&' + `${subid}=${value}`;
          } else {
            params = params + '?' + `${subid}=${value}`;
          }
        }
      }

      setUrlValue(domain + '/' + id + params);
    }
  };

  const handleTemplatesOptions = () => {
    const newOptions: object = {};
    if (templates) {
      for (let i = 0; i < templates.length; ++i) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newOptions[templates[i].id] = templates[i].name;
      }
      setTemplatesOptions({ ...templatesOptions, ...newOptions });
    }
  };

  const clearSubId = async () => {
    for (let i = 0; i < 18; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(`subid${i + 1}`, '');
    }

    setValue(`subid`, '');
    setCount(5);
    setValueTemplate('0');
    await trigger();
  };

  const onChangeTemplate = () => {
    setIsCheck(false);

    if (valueTemplate !== '0') {
      let maxCount = 5;

      templates?.forEach((objTemplate) => {
        if (objTemplate.id === valueTemplate) {
          const newData = {};
          let key: keyof typeof objTemplate;
          let i = 1;

          for (key in objTemplate) {
            if (key.startsWith('subid')) {
              if (key === 'subid' && objTemplate[key]) maxCount = 19;

              if (objTemplate[key]) {
                if (i > maxCount) maxCount = i;
                i++;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newData[key] = objTemplate[key];
              } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newData[key] = '';
              }
            }
          }

          reset(newData);
        }
      });
      setCount(maxCount);
      setIsCheck(true);
    }
  };

  const checkTemplate = async () => {
    if (isCheck) {
      await trigger();
      templates?.forEach((template) => {
        if (valueTemplate === template.id) {
          let result = true;
          let key: keyof typeof template;

          console.log(template);

          for (key in template) {
            if (key.includes('subid')) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const currentValue = `${watch(key)}`;

              if (
                !(
                  currentValue === template[key] ||
                  (template[key] === null && currentValue === '') ||
                  (template[key] === null && currentValue === 'undefined')
                )
              ) {
                result = false;
              }
            }
          }

          if (!result) setValueTemplate('0');
        }
      });
    }
  };

  useEffect(() => {
    handleUrl();
    checkTemplate();
  }, [watch()]);

  useEffect(() => {
    handleTemplatesOptions();
  }, [templates]);

  useEffect(() => {
    onChangeTemplate();
  }, [valueTemplate]);

  useEffect(() => {
    determineCountSelects();
    getAdsTemplate();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <LinkItem>
        <SimpleInput mode={'copy'} currentValue={urlValue} />
        {onEdit && (
          <LinkPanel>
            <LinkPanelItem onClick={() => onEdit(null)}>
              <SvgSetting />
            </LinkPanelItem>
          </LinkPanel>
        )}
      </LinkItem>
      <Header>
        <Item>
          <label>{t('landing.select_domain', { ns: 'landing' })}:</label>
          <ControlledSelect
            name={'domain'}
            options={domainsOptions}
            label={`${t('landing.select_domain', { ns: 'landing' })}:`}
          />
        </Item>
        {templates && (
          <Item>
            <label>{t('landing.template_advertising', { ns: 'landing' })}:</label>
            <Select
              value={valueTemplate}
              onChange={setValueTemplate}
              options={templatesOptions}
              label={`${t('landing.template_advertising', { ns: 'landing' })}:`}
            />
          </Item>
        )}
      </Header>
      <Body>
        {Array.from(Array(count).keys()).map((_, i) => {
          if (i === 18)
            return (
              <ControlledSimpleInput key={i} name={`subid`} label={`SubId`} placeholder={'—'} />
            );
          return (
            <ControlledSimpleInput
              key={i}
              name={`subid${i + 1}`}
              label={`SubId${i + 1}`}
              placeholder={'—'}
            />
          );
        })}
        {count < 19 && (
          <Item>
            <label />
            <Button variant={'inactive'} maxWidth={'full'} onClick={() => setCount(19)}>
              <SvgPlusRectangle />
              {t('landing.add_subid', { ns: 'landing' })}
            </Button>
          </Item>
        )}
      </Body>
      {error?.response.data && (
        <Message onClose={() => setError(null)}>
          {error.response.data.detail
            ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
            : t('errors.try_again_later', { ns: 'errors' })}
        </Message>
      )}
      <BtnSection>
        <Button variant={'accent'} onClick={submitForm} maxWidth={'full'}>
          <SvgPlusRectangle />
          {t('common.save_link', { ns: 'common' })}
        </Button>
        <Button variant={'inactive'} onClick={clearSubId} maxWidth={'full'}>
          {t('landing.clear_subid', { ns: 'landing' })}
        </Button>
      </BtnSection>
    </FormProvider>
  );
}
