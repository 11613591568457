import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { down } from 'styled-breakpoints';
import {
  Header,
  FullScreenSpinner,
  Modals,
  ModalRoot,
  PopperRoot,
  Nav,
} from '@partnerka-front/components';
import { ToastContainer } from 'react-toast';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.headliner};

  .toastContainer {
    z-index: 1;
  }

  .toast.success {
    background-color: ${({ theme }) => theme.colors.positive};
  }

  .toast.error {
    background-color: ${({ theme }) => theme.colors.negative};
  }
`;

const ContainerApp = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  ${down('lg')} {
    padding: 0 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 240px);
  padding: 0 60px;

  ${down('lg')} {
    width: 100%;
    padding: 0 10px;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 50px;

  ${down('lg')} {
    max-width: 100%;
    padding-top: 80px;
  }
`;

export const SectionRelative = styled.div`
  position: relative;
`;

interface LayoutProps {
  loading: boolean;
}

export function Layout({ loading }: LayoutProps) {
  if (loading) return <FullScreenSpinner />;

  return (
    <Wrapper>
      <ContainerApp>
        <Nav />
        <Content>
          <Header />
          <Main>
            <Outlet />
          </Main>
        </Content>
      </ContainerApp>
      <ToastContainer delay={10000} />
      <ModalRoot />
      <PopperRoot />
      <Modals />
    </Wrapper>
  );
}
