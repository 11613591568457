import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Modal, ModalLoading, ModalProps } from '@partnerka-front/components';
import { Api } from '@partnerka-front/api';
import { ResponseError, UnknownFunction } from '@partnerka-front/types';

interface DeleteLinkModalProps extends ModalProps {
  id: string;
  updateCatalog: UnknownFunction;
}

export function DeleteLinkModal({ id, updateCatalog, onClose }: DeleteLinkModalProps) {
  const { t } = useTranslation(['landing', 'common', 'errors']);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const deleteLink = async () => {
    setPending(true);
    await Api.deleteLink(id)
      .then(() => {
        updateCatalog();
        if (onClose) onClose();
      })
      .catch(setError);
    setPending(false);
  };

  return (
    <Modal
      title={`${t('landing.delete_link', { ns: 'landing' })}`}
      onClose={onClose}
      leftBtn={
        <Button variant={'negative'} onClick={() => deleteLink()}>
          {t('common.delete', { ns: 'common' })}
        </Button>
      }
      rightBtn={
        <Button variant={'primary-inactive'} onClick={onClose}>
          {t('common.cancel', { ns: 'common' })}
        </Button>
      }>
      {pending && <ModalLoading />}
      <p>{t('landing.delete_link_description', { ns: 'landing' })}</p>
      {error && (
        <Message onClose={() => setError(null)}>
          {t('errors.try_again_later', { ns: 'errors' })}
        </Message>
      )}
    </Modal>
  );
}
