export function SvgFile() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16663 5C4.16663 3.61929 5.28592 2.5 6.66663 2.5H10.9763C11.4183 2.5 11.8422 2.67559 12.1548 2.98816L15.3451 6.17851C15.6577 6.49107 15.8333 6.91499 15.8333 7.35703V15C15.8333 16.3807 14.714 17.5 13.3333 17.5H6.66663C5.28592 17.5 4.16663 16.3807 4.16663 15V5Z"
        stroke="#35434E"
      />
      <path
        d="M10.8334 2.5V4.16667C10.8334 6.00762 12.3258 7.5 14.1667 7.5H15.8334"
        stroke="#35434E"
      />
    </svg>
  );
}
