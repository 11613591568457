import { LightThemeProps } from '@partnerka-front/theme';

export const lightTheme: LightThemeProps = {
  colors: {
    headliner: '#35434E',
    secondary: '#868E95',
    accent: '#2356F6',
    lightBlue: '#557EFF',
    darkBlue: '#1F50E9',
    basic: '#DBEAFE',
    positive: '#89C541',
    notification: '#F7D221',
    negative: '#EE3D4A',
    white: '#FFFFFF',
    support: '#CED2D6',
    basicLight: '#F9FBFC',
    inactiveGrey: '#F2F2F2',
    moreBlack: '#0E2334',
    tableGrey: '#F2F4F8',
  },
};
