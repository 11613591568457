import { useTranslation } from 'react-i18next';
import {
  Button,
  Pagination,
  SkeletonTable,
  TableItem,
  TableNoData,
  TableRow,
  TableWrapper,
} from '@partnerka-front/components';
import { useEventBus, usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { PostbackTableItem } from './item';
import { SvgStartPostback } from '@partnerka-front/icons';
import { useNavigate } from 'react-router-dom';
import { PostbackEvents } from '@partnerka-front/types';

export function PostbackTable() {
  const { t } = useTranslation(['postback', 'common', 'deals']);
  const { subscribe } = useEventBus();
  const navigate = useNavigate();

  const {
    pending,
    items,
    fetchItems,
    page,
    setPage,
    pageSize,
    setPageSize,
    itemsTotal,
    pagesTotal,
  } = usePaginated((page, pageSize) => Api.getPostbackList(page, pageSize));

  subscribe(PostbackEvents.fetchPostbackTable, fetchItems);

  return (
    <>
      <TableWrapper width={'100%'}>
        <TableRow gridTemplateColumns={'80px 140px 180px 110px 1fr 120px'}>
          <TableItem>ID</TableItem>
          <TableItem>{t('postback.name', { ns: 'postback' })}</TableItem>
          <TableItem>{t('postback.product_offer', { ns: 'postback' })}</TableItem>
          <TableItem>{t('common.status', { ns: 'common' })}</TableItem>
          <TableItem minWidth={200}>{t('postback.parameters', { ns: 'postback' })}</TableItem>
          <TableItem>{t('postback.actions', { ns: 'postback' })}</TableItem>
        </TableRow>

        {pending ? (
          <SkeletonTable gridTemplateColumns={'80px 140px 180px 110px 1fr 120px'} width={'full'} />
        ) : (
          <>
            {items.length > 0 ? (
              items.map((postback) => <PostbackTableItem key={postback.id} postback={postback} />)
            ) : (
              <TableNoData
                icon={<SvgStartPostback />}
                title={'POSTBACK'}
                description={
                  <>
                    {t('postback.start_description', { ns: 'postback' })}
                    <br />
                    <br />
                    {t('postback.text_new_postback', { ns: 'postback' })}
                  </>
                }
                descriptionWidth={'full'}
                btn={
                  <Button variant={'accent'} onClick={() => navigate('/postback/new')}>
                    {t('postback.create_postback', { ns: 'postback' })}
                  </Button>
                }
              />
            )}
          </>
        )}
      </TableWrapper>

      {items && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
