import { AxiosResponse } from 'axios';
import { $api } from '@partnerka-front/api';
import {
  DealDto,
  DealsParams,
  GeneralStatisticDto,
  GeneralStatisticsUserDto,
  GetDealsOptions,
  GetGeneralStatisticsDealsOptions,
  GetGeneralStatisticsUserOptions,
  Paginated,
  ParamsWithDealsDto,
  StatisticsDealsDto,
} from '@partnerka-front/types';

const basePath = '/deal';

export async function getStatistics(): Promise<AxiosResponse<StatisticsDealsDto>> {
  return $api.get(`${basePath}/get_statistics_deals/`);
}

export async function getOldDeals(
  page: number,
  params: DealsParams
): Promise<AxiosResponse<Paginated<DealDto>>> {
  const paramsRequest = new URLSearchParams();

  paramsRequest.append('page', page.toString());
  // Сортировка
  if (params.ordering) paramsRequest.append('o', params.ordering);
  // Статусы
  if (params.statusCreated) paramsRequest.append('status', 'Created');
  if (params.statusPaid) paramsRequest.append('status', 'Paid');
  if (params.statusApproved) paramsRequest.append('status', 'Approved');
  if (params.statusCanceled) paramsRequest.append('status', 'Canceled');
  // Валюта
  if (params.currencyRUB) paramsRequest.append('currency', 'RUB');
  if (params.currencyUSD) paramsRequest.append('currency', 'USD');

  const request = {
    params: paramsRequest,
  };

  return $api.get(`${basePath}/`, request);
}

export async function getDeals(
  page = 1,
  pageSize = 10,
  options: GetDealsOptions
): Promise<AxiosResponse<Paginated<DealDto>>> {
  return $api.get(`${basePath}/`, {
    params: {
      page,
      page_size: pageSize,
      o: options.o,
      conversion_time_after: options.conversion_time_after,
      conversion_time_before: options.conversion_time_before,
    },
  });
}

export async function getGeneralStatisticsDeals(
  page = 1,
  pageSize = 10,
  options: GetGeneralStatisticsDealsOptions = {}
): Promise<AxiosResponse<Paginated<GeneralStatisticDto>>> {
  return $api.get(`${basePath}/get_general_statistics_deals/`, {
    params: {
      page,
      page_size: pageSize,
      o: options.o,
      catalog: options.catalog,
      offer: options.offer,
      modified_at_date_before: options.modified_at_date_before,
      modified_at_date_after: options.modified_at_date_after,
    },
  });
}

export async function getGeneralStatisticsUser(
  page = 1,
  pageSize = 10,
  group_by = 'modified_at_date',
  options: GetGeneralStatisticsUserOptions = {}
): Promise<AxiosResponse<Paginated<GeneralStatisticsUserDto>>> {
  return $api.get(`${basePath}/get_general_statistics_user_deals/`, {
    params: {
      page,
      page_size: pageSize,
      group_by: group_by,
      o: options.o,
      modified_at_date_before: options.modified_at_date_before,
      modified_at_date_after: options.modified_at_date_after,
      catalog: options.catalog,
      offer: options.offer,
      country: options.country,
      os: options.os,
      browser: options.browser,
      subid1: options.subid1,
      subid2: options.subid2,
      subid3: options.subid3,
      subid4: options.subid4,
      subid5: options.subid5,
      subid6: options.subid6,
      subid7: options.subid7,
      subid8: options.subid8,
      subid9: options.subid9,
      subid10: options.subid10,
      subid11: options.subid11,
      subid12: options.subid12,
      subid13: options.subid13,
      subid14: options.subid14,
      subid15: options.subid15,
      subid16: options.subid16,
      subid17: options.subid17,
      subid18: options.subid18,
      utm_source: options.utm_source,
      utm_medium: options.utm_medium,
      utm_campaign: options.utm_campaign,
      utm_content: options.utm_content,
      utm_term: options.utm_term,
    },
  });
}

export function getParamsWithDeals(): Promise<AxiosResponse<ParamsWithDealsDto>> {
  return $api.get(`${basePath}/get_params_with_deals/`);
}
