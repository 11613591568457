import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MenuItem } from './menu-item';
import { SvgDown } from '@partnerka-front/icons';

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.tableGrey};
  z-index: 1;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.headliner};
  cursor: pointer;

  svg {
    margin-left: 10px;
  }
`;

const Menu = styled.menu`
  position: absolute;
  top: 0;
  z-index: 2;
  overflow-y: auto;
  width: 100%;
  max-height: 200px;
  border: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
`;

interface DropdownItem {
  label: string;
  text: string;
  value: string;
}

interface DropdownProps {
  name: string;
  label: string;
  register: any;
  items: DropdownItem[];
}

export function Dropdown({ name, label, items, register }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const refLabel = useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (e.target !== refLabel.current) setIsOpen(false);
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <DropdownWrapper>
      <DropdownLabel ref={refLabel} onClick={handleOpen}>
        {label}
        <SvgDown />
      </DropdownLabel>
      {isOpen && (
        <Menu>
          {items.map((item, i) => (
            <MenuItem
              key={i}
              name={name}
              register={register}
              label={item.label}
              text={item.text}
              value={item.value}
              onClose={() => setIsOpen(false)}
            />
          ))}
        </Menu>
      )}
    </DropdownWrapper>
  );
}
