import { SvgArrowRight } from '@partnerka-front/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WrapperBack = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`;

interface BackProps {
  to?: string;
}

export function Back({ to }: BackProps) {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  return (
    <WrapperBack
      onClick={() => {
        to ? navigate(to) : navigate(-1);
      }}>
      <SvgArrowRight />
      {t('common.back', { ns: 'common' })}
    </WrapperBack>
  );
}
