export type UnknownFunction<T = unknown> = (...args: Array<unknown>) => T;

export type Status = 'Paid' | 'Canceled' | 'Approved' | 'Created' | 'Rejected';

export type TypeEvent = 'ALL' | 'CPC' | 'REF_SHARE';

export enum Categories {
  Education = 'Education',
  Betting = 'Betting',
  Software = 'Software',
  Gambling = 'Gambling',
}

export interface LooseObject {
  [key: string]: any;
}

export interface Paginated<T> {
  results: Array<T>;
  page: number;
  count: number;
  sum_by_status?: SumByStatusDto;
}

export interface SumByStatusDto {
  count_by_all_status: number;
  count_by_approved: number;
  count_by_canceled: number;
  count_by_created: number;
  count_by_paid: number;
  sum_usd: number;
  sum_deal_approved: number;
  sum_deal_canceled: number;
  sum_deal_created: number;
  sum_deal_paid: number;
}

export interface BreadCrumbsDto {
  to: string;
  label: string;
}

export interface DateRange extends Array<Date> {
  0: Date;
  1: Date;
}
