import { useEffect, useState } from 'react';
import { Card } from '../../card';
import { GlobalEvents, PromoDto, ResponseError } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { Button, ControlledSimpleInput } from '@partnerka-front/components';
import { Api } from '@partnerka-front/api';
import { format } from 'date-fns';
import { useForm, FormProvider } from 'react-hook-form';
import { SvgEditRectangle, SvgPlusRectangle } from '@partnerka-front/icons';
import { toast } from 'react-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { regExpPromo, useEventBus } from '@partnerka-front/utils';
import {
  CatalogPromoItem,
  CatalogPromoItemPreview,
  CatalogPromoItemWrapper,
  PromoItem,
} from './item';
import { PromoForm, PromoHeader } from './style';

interface CatalogPromoProps {
  idCatalog: string;
  percentDiscounts: number;
  promo_codes: PromoDto[] | null | undefined;
}

export function CatalogPromo({ idCatalog, percentDiscounts, promo_codes }: CatalogPromoProps) {
  const { t } = useTranslation(['promo', 'validation', 'errors']);
  const { emit } = useEventBus();

  const [error, setError] = useState<ResponseError | null>(null);
  const [isNewPromoCode, setIsNewPromoCode] = useState(false);

  const promoCodeSchema = Yup.object().shape({
    code: Yup.string()
      .min(
        2,
        `${t('validation.promo_must_be_at_least_characters', {
          characters: '2',
          ns: 'validation',
        })}`
      )
      .max(
        200,
        `${t('validation.promo_must_be_at_most_characters', {
          characters: '200',
          ns: 'validation',
        })}`
      )
      .matches(regExpPromo, `${t('validation.promo', { ns: 'validation' })}`),
  });
  const formCreatePromoCode = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(promoCodeSchema),
    defaultValues: {
      code: '',
    },
  });
  const { handleSubmit, setFocus, reset, watch } = formCreatePromoCode;

  const createPromo = handleSubmit(async (data) => {
    setError(null);

    await Api.createPromo({
      code: data.code,
      percent_discounts: percentDiscounts,
      catalog: [idCatalog],
    })
      .then(() => {
        toast.success(`${t('promo.promo_created', { ns: 'promo' })}`);
        reset();
        setIsNewPromoCode(false);

        emit(GlobalEvents.fetchCatalog);
      })
      .catch((err) => setError(err));
  });

  const openSelect = () => {
    setIsNewPromoCode(true);
    setTimeout(() => setFocus('code'));
  };

  useEffect(() => {
    setError(null);
  }, [watch('code')]);

  return (
    <Card childrenBottom={10}>
      <PromoHeader>
        <label>{t('promo.title', { ns: 'promo' })}</label>
        <label>{t('promo.size', { ns: 'promo' })}</label>
        <label>{t('promo.date_create', { ns: 'promo' })}</label>
        <label></label>
      </PromoHeader>

      {promo_codes &&
        promo_codes?.length > 0 &&
        promo_codes.map((promo) => (
          <CatalogPromoItem
            key={promo.code}
            idCatalog={idCatalog}
            percentDiscounts={percentDiscounts}
            promo={promo}
          />
        ))}

      {isNewPromoCode && (
        <FormProvider {...formCreatePromoCode}>
          <PromoForm onSubmit={createPromo}>
            <CatalogPromoItemWrapper>
              <ControlledSimpleInput
                name={'code'}
                error={
                  error &&
                  (error.response.data && error.response.data.detail
                    ? `${t(`errors.${error.response.data.detail}`, { ns: 'errors' })}`
                    : 'Error')
                }
              />
              <PromoItem>{percentDiscounts}%</PromoItem>
              <PromoItem>{format(new Date(), 'dd.MM.yyyy')}</PromoItem>
              <PromoItem isActive isCursor onClick={createPromo}>
                <SvgEditRectangle />
              </PromoItem>
            </CatalogPromoItemWrapper>
          </PromoForm>
        </FormProvider>
      )}

      {promo_codes && promo_codes.length > 9 ? (
        <div>{t('errors.MAX_COUNTS_PROMO_CODE_IS_10', { ns: 'errors' })}</div>
      ) : (
        <Button onClick={openSelect} variant={'basic'} disabled={isNewPromoCode}>
          <SvgPlusRectangle />
          {t('promo.new_promo', { ns: 'promo' })}
        </Button>
      )}
    </Card>
  );
}

export function CatalogPromoPreview() {
  const { t } = useTranslation(['promo', 'validation', 'errors']);

  return (
    <Card childrenBottom={10}>
      <PromoHeader>
        <label>{t('promo.title', { ns: 'promo' })}</label>
        <label>{t('promo.size', { ns: 'promo' })}</label>
        <label>{t('promo.date_create', { ns: 'promo' })}</label>
        <label></label>
      </PromoHeader>
      <CatalogPromoItemPreview />
      <Button variant={'basic'}>
        <SvgPlusRectangle />
        {t('promo.new_promo', { ns: 'promo' })}
      </Button>
    </Card>
  );
}
