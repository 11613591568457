import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link, NavLink } from 'react-router-dom';
import {
  SvgBarChartNew,
  SvgHome,
  SvgLogo,
  SvgManager,
  SvgOffer,
  SvgTools,
  SvgWalletNew,
} from '@partnerka-front/icons';
import { useUser } from '@partnerka-front/utils';
import { LinkTheme } from '../UI/link-theme';

const NavWrapper = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  min-width: 240px;
  height: 100vh;
  padding: 20px 10px 16px;
  background-color: #fff;

  ${down('lg')} {
    display: none;
  }
`;

const NavLogo = styled.div`
  margin-bottom: 44px;
  padding: 0 10px;
  line-height: 1;
  font-size: 0;
`;

export const NavItems = styled.ul`
  list-style: none;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px 10px;
    border-radius: 5px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
    transition: 0.3s;

    svg path {
      stroke: ${({ theme }) => theme.colors.secondary};
    }

    &.active,
    &:hover {
      background-color: ${({ theme }) => theme.colors.basicLight};
      color: ${({ theme }) => theme.colors.accent};
      transition: 0.3s;

      svg path {
        stroke: ${({ theme }) => theme.colors.accent};
        transition: 0.3s;
      }
    }

    svg {
      width: 24px;
      min-width: 24px;
      margin-right: 12px;
      font-size: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const NavFooter = styled.div`
  padding: 0 10px;
  font-size: 12px;
`;

export const NavLang = styled.div`
  color: ${({ theme }) => theme.colors.secondary};

  span {
    cursor: pointer;

    &.active {
      color: ${({ theme }) => theme.colors.accent};
      cursor: default;
    }
  }
`;

export const NavLogin = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
`;

export const NavUser = styled.div`
  display: flex;
  align-items: center;

  ${down('lg')} {
    margin-top: 10px;
  }

  svg {
    margin-right: 10px;
  }
`;

export const NavLogout = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.colors.headliner};
  }
`;

export const NavSupport = styled.div`
  margin-bottom: 26px;
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    color: #121312;
    opacity: 0.3;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

const NavInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
`;

export function Nav() {
  const { t, i18n } = useTranslation(['common']);
  const { isAuth, user, logout: logoutUser } = useUser();

  const logout = async () => {
    await logoutUser();
  };

  return (
    <NavWrapper>
      <div>
        <NavLogo>
          <Link to="/">
            <SvgLogo />
          </Link>
        </NavLogo>
        {isAuth && (
          <NavItems>
            <NavLink to="/">
              <SvgHome />
              {t('common.nav.main', { ns: 'common' })}
            </NavLink>
            <NavLink to="/offers">
              <SvgOffer />
              {t('common.nav.offers', { ns: 'common' })}
            </NavLink>
            <NavLink to="/statistics">
              <SvgBarChartNew />
              {t('common.nav.statistics', { ns: 'common' })}
            </NavLink>
            <NavLink to="/postback">
              <SvgTools />
              PostBack
            </NavLink>
            <NavLink to="/finance">
              <SvgWalletNew />
              {t('common.nav.finance', { ns: 'common' })}
            </NavLink>
            <NavLink to="/support">
              <SvgManager />
              {t('common.nav.support', { ns: 'common' })}
            </NavLink>
          </NavItems>
        )}
      </div>
      <NavFooter>
        {!isAuth && (
          <NavSupport>
            <LinkTheme href={'https://t.me/aff_good_boy'}>
              {t('common.support', { ns: 'common' })}
            </LinkTheme>
          </NavSupport>
        )}
        {isAuth && (
          <NavLogout onClick={() => logout()}>{t('common.logout', { ns: 'common' })}</NavLogout>
        )}
        <NavInfo>
          {user && isAuth && <NavUser>ID: {user.id}</NavUser>}
          <NavLang>
            <span
              className={i18n.language === 'ru' ? 'active' : ''}
              onClick={() => i18n.changeLanguage('ru')}>
              RU
            </span>
            {' | '}
            <span
              className={i18n.language === 'en' ? 'active' : ''}
              onClick={() => i18n.changeLanguage('en')}>
              EN
            </span>
          </NavLang>
        </NavInfo>
        {!isAuth && (
          <NavLinks>
            <a>{t('common.privacy_cookie', { ns: 'common' })}</a>
            <a>{t('common.legal_information', { ns: 'common' })}</a>
          </NavLinks>
        )}
      </NavFooter>
    </NavWrapper>
  );
}
