export function SvgOffer() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z"
        stroke="#6E84A3"
        strokeWidth="1.5"
      />
      <path
        d="M8 12H12M12 12H16M12 12V8M12 12V16"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
