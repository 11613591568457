import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Svg404 } from '@partnerka-front/icons';
import { Button } from '@partnerka-front/components';
import { useTranslation } from 'react-i18next';

const NotFoundPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const NotFoundPageContent = styled.div`
  padding: 20px 0;

  button {
    margin: 0 auto;
  }
`;

const NotFoundPageImg = styled.div`
  position: relative;

  svg {
    max-width: 100%;
  }
`;

const NotFoundPageImgContent = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.headliner};

  span {
    font-weight: 800;
    font-size: 42px;
    line-height: 60px;
  }
`;

export function NotFoundPage() {
  const { t } = useTranslation(['404']);
  const navigate = useNavigate();

  return (
    <NotFoundPageWrapper>
      <NotFoundPageContent>
        <NotFoundPageImg>
          <Svg404 />
          <NotFoundPageImgContent>
            <span>{t('404.title', { ns: '404' })}</span>
            <p>{t('404.description', { ns: '404' })}</p>
          </NotFoundPageImgContent>
        </NotFoundPageImg>
        <Button variant={'active'} onClick={() => navigate('/')}>
          {t('404.btn', { ns: '404' })}
        </Button>
      </NotFoundPageContent>
    </NotFoundPageWrapper>
  );
}
