import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, CatalogLink, SimpleInput } from '@partnerka-front/components';
import { Api } from '@partnerka-front/api';
import { Domain, LinkConstructorDto, UnknownFunction } from '@partnerka-front/types';
import { useState } from 'react';
import { SvgPaperclip } from '@partnerka-front/icons';

const CatalogLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LinkWrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    top: 5px;
    left: 6px;
    height: 30px;
  }
`;

interface CatalogLinksProps {
  landingId: string;
  links: LinkConstructorDto[];
  domains: Domain[];
  updateCatalog: UnknownFunction;
}

export function CatalogLinks({ landingId, domains, links, updateCatalog }: CatalogLinksProps) {
  const { t } = useTranslation(['catalog']);
  // TODO: Переписать шиной
  const [currentEdit, setCurrentEdit] = useState<string | null>(null);

  const createLink = async () => {
    await Api.createLink({ landing: landingId, domain: domains[0].id }).then((res) => {
      setCurrentEdit(res.data.id);
      updateCatalog();
    });
  };

  return (
    <CatalogLinksWrapper>
      {links.length > 0 ? (
        links.map((link, i) => (
          <CatalogLink
            key={link.id}
            link={link}
            domains={domains}
            createLink={createLink}
            lastLink={i + 1 === links.length}
            currentEdit={currentEdit}
            setCurrentEdit={setCurrentEdit}
            landingId={landingId}
            updateCatalog={updateCatalog}
          />
        ))
      ) : (
        <LinkWrapper>
          <SimpleInput defaultValue={''} mode={'copy'} />
          <Button onClick={createLink} variant={'basic'}>
            <SvgPaperclip />
            {t('catalog.get_link', { ns: 'catalog' })}
          </Button>
        </LinkWrapper>
      )}
    </CatalogLinksWrapper>
  );
}
