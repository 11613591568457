import styled from 'styled-components';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

interface TextareaWrapperProps {
  height?: number;
  error?: FieldError | string;
}

const TextareaWrapper = styled.div<TextareaWrapperProps>`
  width: 100%;

  textarea {
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : '60px')};
    padding: 6px 10px;
    border: 1px solid
      ${({ theme, error }) => (error ? theme.colors.negative : theme.colors.support)};
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.headliner};
    resize: none;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.support};
    }

    &:focus {
      border: 1px solid
        ${({ theme, error }) => (error ? theme.colors.negative : theme.colors.accent)};

      &::placeholder {
        font-size: 0;
      }
    }

    &[readonly]:focus {
      border: 1px solid ${({ theme }) => theme.colors.support};
    }
  }
`;

const TextareaMax = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  line-height: 1.2;
`;

const TextareaError = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.negative};
`;

interface TextareaProps {
  value?: string;
  onChange?: (value: string, originalEvent?: React.FormEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  height?: number;
  max?: number;
  error?: FieldError | string;
  dataTestId?: string;
}

export function Textarea({
  value,
  onChange,
  placeholder,
  height,
  max,
  error,
  dataTestId,
}: TextareaProps) {
  const handleTextarea: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.currentTarget.value;
    if (onChange) onChange(value, e);
  };

  return (
    <TextareaWrapper height={height} error={error}>
      <textarea
        value={value}
        onChange={handleTextarea}
        placeholder={placeholder}
        maxLength={max}
        data-testid={dataTestId}
      />
      {error && <TextareaError>{typeof error === 'string' ? error : error?.message}</TextareaError>}
      {max && (
        <TextareaMax>
          {value ? value.length : '0'} / {max}
        </TextareaMax>
      )}
    </TextareaWrapper>
  );
}

interface ControlledTextareaProps extends TextareaProps {
  name: string;
}

export function ControlledTextarea({
  name,
  placeholder,
  max,
  height,
  dataTestId,
}: ControlledTextareaProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          max={max}
          height={height}
          error={error}
          dataTestId={dataTestId}
        />
      )}
    />
  );
}
