export function SvgNewEdit() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 9.16667V14.1667C17.5 16.0076 16.0076 17.5 14.1667 17.5H5.83333C3.99238 17.5 2.5 16.0076 2.5 14.1667V5.83333C2.5 3.99238 3.99238 2.5 5.83333 2.5H10.8333"
        stroke="#868E95"
        strokeLinecap="round"
      />
      <path
        d="M14.9356 2.93998C15.2173 2.65827 15.5994 2.5 15.9977 2.5C16.3962 2.5 16.7783 2.65827 17.06 2.93998C17.3417 3.22171 17.5 3.6038 17.5 4.00221C17.5 4.40062 17.3417 4.78272 17.06 5.06443L10.3326 11.7918L7.5 12.5L8.20815 9.66742L14.9356 2.93998Z"
        stroke="#868E95"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 5L15 6.66667"
        stroke="#868E95"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
