import { default as currencyjs } from 'currency.js';
import { Currency } from '@partnerka-front/types';

const defaultOptions: currencyjs.Options = {
  decimal: '.<span>',
  pattern: `!#</span>`,
  separator: ',',
};

const currencySettings: Record<string, currencyjs.Options> = {
  [Currency.USD]: {
    ...defaultOptions,
    symbol: '<abbr>$</abbr>',
    precision: 2,
  },
  [Currency.RUB]: {
    ...defaultOptions,
    symbol: '<abbr>₽</abbr>',
    precision: 2,
  },
};

export function money(value: number | string, currency: Currency = Currency.USD) {
  const safeCurrency = currency.replace('-', '_');
  const options = Object.assign({}, currencySettings[safeCurrency]);

  return currencyjs(value, options);
}
export function price(amount: number | string, currency: Currency): string {
  return money(amount, currency).format();
}
