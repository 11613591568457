import styled from 'styled-components';
import { DateFormat, Price, TableItem } from '@partnerka-front/components';
import { TableRow } from '../../UI/table';
import { Currency, GeneralStatisticDto } from '@partnerka-front/types';

const GeneralStatisticsTableItemWrapper = styled(TableRow)``;

interface GeneralStatisticsTableItemProps {
  deal: GeneralStatisticDto;
}

export function GeneralStatisticsTableItem({ deal }: GeneralStatisticsTableItemProps) {
  return (
    <GeneralStatisticsTableItemWrapper
      gridTemplateColumns={
        '100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px 80px 100px'
      }>
      <TableItem>
        <DateFormat date={deal.modified_at_date} dateFormat={'MMM dd, yyyy'} />
      </TableItem>

      <TableItem noRightBorder>{deal.count_usd}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.sum_deals_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>{deal.count_rub}</TableItem>
      <TableItem>
        <Price amount={deal.sum_deals_rub} currency={Currency.RUB} />
      </TableItem>

      <TableItem noRightBorder>{deal.count_by_approved_usd}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.sum_by_approved_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>{deal.count_by_approved_rub}</TableItem>
      <TableItem>
        <Price amount={deal.sum_by_approved_rub} currency={Currency.RUB} />
      </TableItem>

      <TableItem noRightBorder>{deal.count_by_paid_usd}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.sum_by_paid_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>{deal.count_by_paid_rub}</TableItem>
      <TableItem>
        <Price amount={deal.sum_by_paid_rub} currency={Currency.RUB} />
      </TableItem>

      <TableItem noRightBorder>{deal.count_by_created_usd}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.sum_by_created_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>{deal.count_by_created_rub}</TableItem>
      <TableItem>
        <Price amount={deal.sum_by_created_rub} currency={Currency.RUB} />
      </TableItem>

      <TableItem noRightBorder>{deal.count_by_canceled_usd}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.sum_by_canceled_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>{deal.count_by_canceled_rub}</TableItem>
      <TableItem>
        <Price amount={deal.sum_by_canceled_rub} currency={Currency.RUB} />
      </TableItem>
    </GeneralStatisticsTableItemWrapper>
  );
}
