import React from 'react';
import styled from 'styled-components';
import { SvgUp } from '@partnerka-front/icons';
import { UnknownFunction } from '@partnerka-front/types';

const SortWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  & > *:last-child {
    margin-top: 2.5px;
    transform: rotate(180deg);
  }
`;

interface SortProps {
  onClick: UnknownFunction;
  children: React.ReactNode;
}

export function Sort({ onClick, children }: SortProps) {
  return (
    <SortWrapper onClick={onClick}>
      {children}
      <SortIcons>
        <SvgUp />
        <SvgUp />
      </SortIcons>
    </SortWrapper>
  );
}
