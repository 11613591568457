import { PostbackFieldState } from '@partnerka-front/types';

export function isMainKey(key: string) {
  return ['subid', 'status', 'payment_sum', 'cost', 'currency'].includes(key);
}

export function isSystemsKey(key: string) {
  return [
    'uid',
    'cid',
    'lpid',
    'action_id',
    'deal_id',
    'myshare',
    'country',
    'browser',
    'deal_id',
    'os',
  ].includes(key);
}

export function isSubIdKey(key: string) {
  return [
    'subid1',
    'subid2',
    'subid3',
    'subid4',
    'subid5',
    'subid6',
    'subid7',
    'subid8',
    'subid9',
    'subid10',
    'subid11',
    'subid12',
    'subid13',
    'subid14',
    'subid15',
    'subid16',
    'subid17',
    'subid18',
  ].includes(key);
}

export function isTagsKey(key: string) {
  return ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'].includes(key);
}

export function generateUrlWithFields(items: PostbackFieldState[]) {
  let urlParameters = '';
  const fieldsParameters = [];

  for (const value of items) {
    if (value.disabled === true) {
      if (urlParameters) {
        urlParameters = urlParameters + `&${value.value}={${value.key}}`;
      } else {
        urlParameters = urlParameters + `${value.value}={${value.key}}`;
      }

      fieldsParameters.push({ value: value.value, key: value.key });
    }
  }

  return { urlParameters, fieldsParameters };
}
