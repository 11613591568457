import styled from 'styled-components';
import { Button, Card, Price, Title3 } from '@partnerka-front/components';
import { Currency } from '@partnerka-front/types';
import { useStatistic } from '@partnerka-front/utils';

const CardBalanceContent = styled.div`
  margin-bottom: 24px;
`;

const CardBalanceTotal = styled.div`
  margin-top: 24px;
  font-weight: 700;
  font-size: 36px;

  abbr {
    padding-right: 4px;
    color: ${({ theme }) => theme.colors.support};
  }

  span {
    font-size: 24px;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.tableGrey};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  label {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const LinkTMe = styled.a`
  text-decoration: none;
`;

export function CardBalance() {
  const { statistic } = useStatistic();

  return (
    <Card>
      <CardBalanceContent>
        <Title3>Текущий баланс</Title3>
        <CardBalanceTotal>
          <Price amount={statistic.balance_usd} currency={Currency.USD} />
        </CardBalanceTotal>
        <Item>
          <label>Прогноз расхода</label>
          <span>≈ {statistic.spending_by.consumption_forecast} дней</span>
        </Item>
      </CardBalanceContent>
      <LinkTMe href={'https://t.me/cyberhackGL'} target={'_blank'} rel="noreferrer">
        <Button maxWidth={'full'} variant={'accent'}>
          Пополнить счёт
        </Button>
      </LinkTMe>
    </Card>
  );
}
