import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { UnknownFunction } from '@partnerka-front/types';
import { PageLoading, Portal } from '@partnerka-front/components';

const ModalWrapper = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 30%);
`;

const ModalCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);
  background-color: ${({ theme }) => theme.colors.white};

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ModalTitle = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
`;

const ModalContent = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};

  p:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ModalButton = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
`;

const ModalButtonPlug = styled.div`
  width: 100%;
`;

const MODAL_ROOT_ID = 'modal-root';

export function ModalRoot() {
  return <div id={MODAL_ROOT_ID} />;
}

export interface ModalProps {
  title?: string;
  children?: ReactNode;
  onClose?: UnknownFunction;
  leftBtn?: ReactNode;
  rightBtn?: ReactNode;
  fullBtn?: ReactNode;
  pending?: boolean;
}

export function Modal({
  title,
  children,
  onClose,
  leftBtn,
  rightBtn,
  fullBtn,
  pending,
}: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Portal targetId={MODAL_ROOT_ID}>
      <ModalWrapper onClick={onClose}>
        <ModalCard onClick={(e) => e.stopPropagation()}>
          {pending && <PageLoading />}

          {title && <ModalTitle>{title}</ModalTitle>}
          {children && <ModalContent>{children}</ModalContent>}

          {fullBtn && <ModalButton>{fullBtn}</ModalButton>}
          {(leftBtn || rightBtn) && (
            <ModalButtons>
              {leftBtn ? leftBtn : <ModalButtonPlug />}
              {rightBtn ? rightBtn : <ModalButtonPlug />}
            </ModalButtons>
          )}
        </ModalCard>
      </ModalWrapper>
    </Portal>
  );
}
