import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Api } from '@partnerka-front/api';
import { Modal, ModalProps, ModalLoading, ControlledTextarea } from '@partnerka-front/components';
import { PostbackFieldDto } from '@partnerka-front/types';
import { regExpUrl } from '@partnerka-front/utils';
import { useTranslation } from 'react-i18next';

enum StatusSteps {
  waiting = 'waiting',
  success = 'success',
  error = 'error',
}

const FormModal = styled.form`
  display: block;

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StatusTest = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

const Waiting = styled.span`
  color: #f9a119;
`;

const Success = styled.span`
  color: ${({ theme }) => theme.colors.positive};
`;

const Error = styled.span`
  color: ${({ theme }) => theme.colors.negative};
`;

const ButtonTest = styled.button`
  width: 100%;
  height: 50px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: 5px;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.headliner};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    cursor: default;
  }
`;

const TextTest = styled.p`
  font-size: 10px;
  line-height: 20px;
`;

interface TestingRequestModalProps extends Partial<ModalProps> {
  url: string;
  postback_field: PostbackFieldDto[];
}

export function TestingRequestModal({ url, postback_field, onClose }: TestingRequestModalProps) {
  const { t } = useTranslation(['testing', 'validation']);

  const [step, setStep] = useState(StatusSteps.waiting);
  const [sec, setSec] = useState(0);
  const [pending, setPending] = useState(false);

  const formSchema = Yup.object().shape({
    test_url: Yup.string()
      .matches(regExpUrl, `${t('validation.invalid_url', { ns: 'validation' })}`)
      .required(),
  });
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      test_url: '',
    },
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = formMethods;

  const tick = () => {
    setSec((prev) => prev - 1);
  };

  const testingRequest = handleSubmit(async (data) => {
    setPending(true);
    await Api.testPostback(data.test_url)
      .then(() => setStep(StatusSteps.success))
      .catch(() => setStep(StatusSteps.error));
    setSec(5);
    setPending(false);
  });

  const testUrlParams = () => {
    let params = `${url}?`;

    postback_field.forEach((field, index) => {
      params = params + `${field.value}={test}`;
      if (index !== postback_field.length - 1) {
        params = params + '&';
      }
    });

    setValue('test_url', params);
  };

  useEffect(() => {
    testUrlParams();

    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  }, []);

  return (
    <Modal title={`${t('testing.title', { ns: 'testing' })}`} onClose={onClose}>
      {pending && <ModalLoading />}
      <FormModal onSubmit={testingRequest}>
        <FormProvider {...formMethods}>
          <div>
            <ControlledTextarea name={'test_url'} height={100} />
            <StatusTest>
              {t('testing.request_status', { ns: 'testing' })}:
              {step === StatusSteps.waiting && (
                <Waiting> {t('testing.awaiting_shipment', { ns: 'testing' })}</Waiting>
              )}
              {step === StatusSteps.success && (
                <Success> {t('testing.success', { ns: 'testing' })}</Success>
              )}
              {step === StatusSteps.error && (
                <Error> {t('testing.error', { ns: 'testing' })}</Error>
              )}
            </StatusTest>
          </div>
          <ButtonTest onClick={testingRequest} disabled={sec > 0 || Boolean(errors.test_url)}>
            {t('testing.btn_testing', { ns: 'testing' })}
          </ButtonTest>
          <TextTest>{t('testing.description', { ns: 'testing' })}</TextTest>
        </FormProvider>
      </FormModal>
    </Modal>
  );
}
