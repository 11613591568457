import styled from 'styled-components';

export const PromoHeader = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 60px 100px 42px;
  gap: 10px;

  label {
    display: block;
    height: 20px;
    color: ${({ theme }) => theme.colors.secondary};
    white-space: nowrap;
  }
`;

export const PromoForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
