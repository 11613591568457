export function SvgImage() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 5.83333C2.5 3.99238 3.99238 2.5 5.83333 2.5H14.1667C16.0076 2.5 17.5 3.99238 17.5 5.83333V14.1667C17.5 16.0076 16.0076 17.5 14.1667 17.5H5.83333C3.99238 17.5 2.5 16.0076 2.5 14.1667V5.83333Z"
        stroke="#35434E"
      />
      <path
        d="M7.50004 9.16659C8.42054 9.16659 9.16671 8.42042 9.16671 7.49992C9.16671 6.57944 8.42054 5.83325 7.50004 5.83325C6.57957 5.83325 5.83337 6.57944 5.83337 7.49992C5.83337 8.42042 6.57957 9.16659 7.50004 9.16659Z"
        stroke="#35434E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33337 16.6666L5.72822 13.6731C6.25919 13.0094 7.20949 12.8618 7.91671 13.3333C8.62396 13.8048 9.57421 13.6572 10.1052 12.9935L11.4379 11.3277C12.021 10.5987 13.0891 10.4909 13.8063 11.0885L17.5 14.1666"
        stroke="#35434E"
      />
    </svg>
  );
}
