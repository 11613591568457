import { Route, Routes } from 'react-router-dom';
import { Layout } from './layout/layout';
import {
  CatalogPage,
  FinancePage,
  LoginPage,
  MainPage,
  NotFoundPage,
  OffersPage,
  PasswordResetConfirmPage,
  PasswordResetPage,
  PostbackLogsPage,
  PostbackPage,
  PostbacksPage,
  RegistrationPage,
  StatisticsPage,
  SupportPage,
} from '@partnerka-front/pages';
import { NoRequireAuth, RequireAuth } from './require-auth';
import { useEffect, useState } from 'react';
import { useUser } from '@partnerka-front/utils';

export function PartnerRoutes() {
  const { isAuth, fetchUser } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    if (localStorage.getItem('refresh')) await fetchUser();

    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Routes>
      <Route path={'/'} element={<Layout loading={isLoading} />}>
        <Route index element={isAuth ? <MainPage /> : <LoginPage />} />
        <Route
          path={'login'}
          element={
            <NoRequireAuth>
              <LoginPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'registration'}
          element={
            <NoRequireAuth>
              <RegistrationPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'/ref/:uid'}
          element={
            <NoRequireAuth>
              <RegistrationPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'activate/:uid/:token'}
          element={
            <NoRequireAuth>
              <LoginPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'password-reset'}
          element={
            <NoRequireAuth>
              <PasswordResetPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'password-reset/confirm/:uid/:token'}
          element={
            <NoRequireAuth>
              <PasswordResetConfirmPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'password-reset/:status'}
          element={
            <NoRequireAuth>
              <LoginPage />
            </NoRequireAuth>
          }
        />
        <Route
          path={'offers'}
          element={
            <RequireAuth>
              <OffersPage />
            </RequireAuth>
          }
        />
        <Route
          path={'offers/:id'}
          element={
            <RequireAuth>
              <CatalogPage />
            </RequireAuth>
          }
        />
        <Route
          path={'statistics'}
          element={
            <RequireAuth>
              <StatisticsPage />
            </RequireAuth>
          }
        />
        <Route
          path={'postback'}
          element={
            <RequireAuth>
              <PostbacksPage />
            </RequireAuth>
          }
        />
        <Route
          path={'postback/new'}
          element={
            <RequireAuth>
              <PostbackPage />
            </RequireAuth>
          }
        />
        <Route
          path={'postback/new/:catalogId'}
          element={
            <RequireAuth>
              <PostbackPage />
            </RequireAuth>
          }
        />
        <Route
          path={'postback/:id'}
          element={
            <RequireAuth>
              <PostbackPage />
            </RequireAuth>
          }
        />
        <Route
          path={'postback/logs'}
          element={
            <RequireAuth>
              <PostbackLogsPage />
            </RequireAuth>
          }
        />
        <Route
          path={'finance'}
          element={
            <RequireAuth>
              <FinancePage />
            </RequireAuth>
          }
        />
        <Route
          path={'support'}
          element={
            <RequireAuth>
              <SupportPage />
            </RequireAuth>
          }
        />
      </Route>
      <Route path={'*'} element={<NotFoundPage />} />
    </Routes>
  );
}
