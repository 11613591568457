import styled from 'styled-components';

interface RowProps {
  gap?: number;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? `${gap}px` : '8px')};
`;
