import { TableItem, TableRow } from '../../UI/table';
import { Currency, GeneralStatisticsUserDto } from '@partnerka-front/types';
import { DateFormat } from '../../date-format';
import { Price } from '../../price';
import styled from 'styled-components';

const Country = styled.div`
  span {
    margin-right: 8px;
  }
`;

interface AccountStatisticsTableItemProps {
  day: GeneralStatisticsUserDto;
}

export function AccountStatisticsTableItem({ day }: AccountStatisticsTableItemProps) {
  return (
    <TableRow
      gridTemplateColumns={
        '130px 70px 70px 70px 70px 60px 60px 60px 60px 60px 120px 120px 120px 120px'
      }>
      <TableItem>
        {day.modified_at_date && (
          <DateFormat date={day.modified_at_date} dateFormat={'MMM dd, yyyy'} />
        )}
        {day.cid}
        {day.country && (
          <Country>
            <span className={`fi fi-${day.country.toLowerCase()}`} />
            {day.country}
          </Country>
        )}
        {day.os}
        {day.browser}
        {day.subid1}
        {day.subid2}
        {day.subid3}
        {day.subid4}
        {day.subid5}
        {day.subid6}
        {day.subid7}
        {day.subid8}
        {day.subid9}
        {day.subid10}
        {day.subid11}
        {day.subid12}
        {day.subid13}
        {day.subid14}
        {day.subid15}
        {day.subid16}
        {day.subid17}
        {day.subid18}
        {day.utm_source}
        {day.utm_medium}
        {day.utm_campaign}
        {day.utm_content}
        {day.utm_term}
      </TableItem>

      <TableItem noRightBorder>{day.clicks}</TableItem>
      <TableItem>{day.unique_clicks}</TableItem>

      <TableItem noRightBorder>{day.created_rate}%</TableItem>
      <TableItem>{day.approval_rate}%</TableItem>

      <TableItem noRightBorder>{day.count_by_all_status}</TableItem>
      <TableItem noRightBorder>{day.count_by_created}</TableItem>
      <TableItem noRightBorder>{day.count_by_paid}</TableItem>
      <TableItem noRightBorder>{day.count_by_approved}</TableItem>
      <TableItem>{day.count_by_canceled}</TableItem>

      <TableItem noRightBorder>
        <Price amount={day.sum_paid_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>
        <Price amount={day.sum_approved_usd} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder>
        <Price amount={day.sum_paid_rub} currency={Currency.RUB} />
      </TableItem>
      <TableItem>
        <Price amount={day.sum_approved_rub} currency={Currency.RUB} />
      </TableItem>
    </TableRow>
  );
}
