import { Title } from './styles';
import { Card } from '../card';
import styled from 'styled-components';
import { SvgCheck, SvgClose } from '@partnerka-front/icons';
import { down } from 'styled-breakpoints';
import { useTranslation } from 'react-i18next';
import { RuleDto } from '@partnerka-front/types';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;

  ${down('md')} {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const Column = styled.div`
  width: 100%;
`;

const ColumnTitle = styled.h4`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.88;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      min-width: 20px;
      margin-right: 10px;
    }
  }
`;

interface CatalogTrafficSourcesProps {
  rules: Record<string, RuleDto>;
}

export function CatalogTrafficSources({ rules }: CatalogTrafficSourcesProps) {
  const { t } = useTranslation(['catalog', 'common']);
  const goodList: RuleDto[] = [];
  const badList: RuleDto[] = [];

  const objectKeys = Object.keys(rules);

  for (let i = 0; i < objectKeys.length; i++) {
    const key = objectKeys[i];
    const rule = rules[key];

    if (rule.is_permitted === true) {
      goodList.push({
        is_permitted: rule.is_permitted,
        description: rule.description,
        label: rule.label,
      });
    }

    if (rule.is_permitted === false) {
      badList.push({
        is_permitted: rule.is_permitted,
        description: rule.description,
        label: rule.label,
      });
    }
  }

  if (goodList.length < 1 && badList.length < 1) return null;

  return (
    <Card childrenBottom={10}>
      <Title>{t('catalog.traffic_sources', { ns: 'catalog' })}</Title>
      <Columns>
        {goodList.length > 0 && (
          <Column>
            <ColumnTitle>{t('catalog.permitted_sources', { ns: 'catalog' })}</ColumnTitle>
            <List>
              {goodList.map((rule, i) => (
                <li key={i}>
                  <SvgCheck />
                  {rule.label}
                </li>
              ))}
            </List>
          </Column>
        )}
        {badList.length > 0 && (
          <Column>
            <ColumnTitle>{t('catalog.prohibited_sources', { ns: 'catalog' })}</ColumnTitle>
            <List>
              {badList.map((rule, i) => (
                <li key={i}>
                  <SvgClose />
                  {rule.label}
                </li>
              ))}
            </List>
          </Column>
        )}
      </Columns>
    </Card>
  );
}
