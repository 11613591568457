import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '@partnerka-front/api';
import {
  Button,
  Line,
  Title,
  Container,
  PostbackSkeleton,
  Postback,
  Message,
} from '@partnerka-front/components';
import {
  CatalogDto,
  PostbackDto,
  PostbackTemplatesDto,
  ResponseError,
} from '@partnerka-front/types';

export function PostbackPage() {
  const { t, i18n } = useTranslation(['postback', 'validation', 'deals', 'errors', 'common']);
  const { id, catalogId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ResponseError | null>(null);

  const [templates, setTemplates] = useState<PostbackTemplatesDto[] | null>(null);
  const [catalogs, setCatalogs] = useState<CatalogDto[] | null>(null);
  const [postback, setPostback] = useState<undefined | PostbackDto>(undefined);

  // ~~ Functions

  const getDataPostback = async () => {
    setIsLoading(true);

    if (id) {
      await Promise.all([
        Api.getPostback(id).then((res) => setPostback(res.data)),
        Api.getUserCatalogs().then((res) => setCatalogs(res.data)),
        Api.getPostbackTemplates().then((res) => setTemplates(res.data)),
      ]).catch(setError);
    } else {
      await Promise.all([
        Api.getUserCatalogs().then((res) => setCatalogs(res.data)),
        Api.getPostbackTemplates().then((res) => setTemplates(res.data)),
      ]).catch(setError);
    }

    setIsLoading(false);
  };

  //  ~~ useEffect

  useEffect(() => {
    getDataPostback();
  }, [i18n.language]);

  return (
    <Container>
      <Title>PostBack</Title>

      <Line>
        <Button variant="inactive" onClick={() => navigate('/postback')}>
          {t('postback.btn_my', { ns: 'postback' })}
        </Button>
        <Button variant="active">
          {id
            ? t('postback.btn_change', { ns: 'postback' })
            : t('postback.btn_new', { ns: 'postback' })}
        </Button>
        <Button variant="inactive" onClick={() => navigate('/postback/logs')}>
          {t('postback.btn_logs', { ns: 'postback' })}
        </Button>
      </Line>

      {isLoading ? (
        <PostbackSkeleton />
      ) : (
        <>
          {!error && templates && catalogs && (
            <Postback
              postback={postback}
              catalogs={catalogs}
              templates={templates}
              defaultCatalog={catalogId}
            />
          )}
        </>
      )}

      {error?.response.data && (
        <Message>
          {error.response.data.detail
            ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
            : t('errors.try_again_later', { ns: 'errors' })}
        </Message>
      )}
    </Container>
  );
}
