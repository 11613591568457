import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Burger, MenuNav } from '@partnerka-front/components';
import { SvgLogo } from '@partnerka-front/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '@partnerka-front/utils';

const LogoStyle = styled.div`
  line-height: 1;
  font-size: 0;
`;

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 4px -4px rgba(0, 0, 0, 0.5);

  ${up('lg')} {
    display: none;
  }
`;

export function Header() {
  const { isAuth } = useUser();
  const [isMenu, setIsMenu] = useState(false);

  return (
    <>
      <HeaderWrapper>
        <LogoStyle>
          <Link to={'/'}>
            <SvgLogo />
          </Link>
        </LogoStyle>
        {isAuth && <Burger open={isMenu} setOpen={setIsMenu} />}
      </HeaderWrapper>
      <MenuNav isMenu={isMenu} setMenu={setIsMenu} />
    </>
  );
}
