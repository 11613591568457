export function SvgCopyNew() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33337 10.8334C8.33337 9.45262 9.45262 8.33337 10.8334 8.33337H15C16.3808 8.33337 17.5 9.45262 17.5 10.8334V15C17.5 16.3808 16.3808 17.5 15 17.5H10.8334C9.45262 17.5 8.33337 16.3808 8.33337 15V10.8334Z"
        stroke="#35434E"
      />
      <path
        d="M11.6667 5.17361V5C11.6667 3.61929 10.5474 2.5 9.16667 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V9.16667C2.5 10.5474 3.61929 11.6667 5 11.6667H5.17361"
        stroke="#35434E"
        strokeLinecap="round"
      />
    </svg>
  );
}
