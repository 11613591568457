import styled from 'styled-components';
import { Title3 } from '@partnerka-front/components';
import { ReactNode } from 'react';
import { SkeletonStyle } from '../skeleton-style';
import { down } from 'styled-breakpoints';
import { SvgSort } from '@partnerka-front/icons';
import { UnknownFunction } from '@partnerka-front/types';

interface TableWrapperProps {
  width?: 'fit-content' | '100%';
}

export const TableWrapper = styled.div<TableWrapperProps>`
  overflow-y: auto;
  width: ${({ width }) => {
    if (width === '100%') return '100%';
    return 'fit-content';
  }};
  max-width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 4px 20px rgba(144, 153, 200, 0.25);
`;

export const TableDescription = styled.p`
  max-width: 820px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`;

interface ItemProps {
  isChildren?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  fontWeight?: '400' | '500';
  fontSize?: number;
  height?: number;
  noRightBorder?: boolean;
  backgroundColor?: 'dark-blue';
  minWidth?: number;
}

export const TableItem = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: ${({ minWidth }) => {
    if (minWidth) return `${minWidth}px`;
    return 'auto';
  }};
  height: ${({ height }) => {
    if (height) return `${height}px`;
    return 'auto';
  }};
  padding: ${({ isChildren }) => (isChildren ? '0' : '10px')};
  border-right: ${({ noRightBorder, theme }) =>
    noRightBorder ? 'none !important' : `1px solid ${theme.colors.tableGrey}`};
  background-color: ${({ backgroundColor, theme }) => {
    if (backgroundColor === 'dark-blue') return `${theme.colors.darkBlue} !important`;
  }};
  text-align: ${({ textAlign }) => {
    if (textAlign === 'left') return 'left';
    if (textAlign === 'right') return 'right';
    return 'center';
  }};
  font-weight: ${({ fontWeight }) => {
    if (fontWeight === '500') return '500';
    return '400';
  }};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};

  &:last-child {
    border-right: none;
  }
`;

const TableSortingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 auto;
  cursor: pointer;
`;

interface TableSortingProps {
  children: ReactNode;
  onClick: UnknownFunction;
}

export function TableSorting({ children, onClick }: TableSortingProps) {
  return (
    <TableSortingWrapper onClick={onClick}>
      <div>{children}</div>
      <SvgSort />
    </TableSortingWrapper>
  );
}

export const TableParentTitle = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;
`;

interface TableChildrenProps {
  gridTemplateColumns: string;
  noTopBorder?: boolean;
}

export const TableChildren = styled.div<TableChildrenProps>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  width: 100%;
  border-top: ${({ noTopBorder, theme }) =>
    noTopBorder ? 'none !important' : `1px solid ${theme.colors.support}`};
`;

interface TableRowProps {
  gridTemplateColumns: string;
}

export const TableRow = styled.div<TableRowProps>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  width: fit-content;
  min-width: 100%;
  margin-bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.tableGrey};
  border-right: 1px solid ${({ theme }) => theme.colors.tableGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.tableGrey};
  background-color: ${({ theme }) => theme.colors.white};

  &:first-child {
    border-top: 1px solid #0c40e2;
    border-right: 1px solid #0c40e2;
    border-left: 1px solid #0c40e2;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    ${TableItem} {
      border-right: 1px solid #0c40e2;
      background-color: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.white};
    }

    ${TableChildren} {
      border-top: 1px solid #0c40e2;
    }
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.tableGrey};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.tableGrey};
  }
`;

const SkeletonTableWrapper = styled.div<SkeletonTableProps>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  width: ${({ width }) => {
    if (width === 'full') return '100%';
    return 'fit-content';
  }};
  border-right: 1px solid ${({ theme }) => theme.colors.tableGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.tableGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableGrey};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const SkeletonTableItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 38.16px;
  padding: 0 15px;

  & > div {
    width: 100%;
    height: 11px;

    ${SkeletonStyle};
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const TableSectionFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 8px;
  width: 100%;

  ${down('md')} {
    grid-template-columns: 1fr 1fr;
  }

  ${down('sm')} {
    grid-template-columns: 1fr;
  }
`;

export const TableFilterButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

interface SkeletonTableProps {
  gridTemplateColumns: string;
  width?: 'full';
}

export function SkeletonTable({ gridTemplateColumns, width }: SkeletonTableProps) {
  return (
    <SkeletonTableWrapper gridTemplateColumns={gridTemplateColumns} width={width}>
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
      {gridTemplateColumns.split(' ').map((_, i) => (
        <SkeletonTableItem key={i}>
          <div />
        </SkeletonTableItem>
      ))}
    </SkeletonTableWrapper>
  );
}

const TableNoDataWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

interface TableNoDataContentProps {
  descriptionWidth?: 'full';
}

const TableNoDataContent = styled.div<TableNoDataContentProps>`
  width: 100%;
  max-width: ${({ descriptionWidth }) => {
    if (descriptionWidth === 'full') return '100%';
    return '270px';
  }};
  padding: 32px;
  text-align: center;

  p {
    margin-top: 8px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const TableNoDataIcon = styled.div`
  margin-bottom: 25px;
`;

const TableNoDataBtn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
`;

interface TableNoDataProps {
  title: string;
  description?: ReactNode;
  descriptionWidth?: 'full';
  icon?: ReactNode;
  btn?: ReactNode;
}

export function TableNoData({ icon, title, description, descriptionWidth, btn }: TableNoDataProps) {
  return (
    <TableNoDataWrapper>
      <TableNoDataContent descriptionWidth={descriptionWidth}>
        {icon && <TableNoDataIcon>{icon}</TableNoDataIcon>}
        <Title3>{title}</Title3>
        {description && <p>{description}</p>}
        {btn && <TableNoDataBtn>{btn}</TableNoDataBtn>}
      </TableNoDataContent>
    </TableNoDataWrapper>
  );
}
