export function SvgArrows() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 19.5L15.5 12.5L8.5 5.5"
        stroke="#2356F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
