import { Button, Card, ModalLoading, TitleH2 } from '@partnerka-front/components';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { StatisticsDealsDto } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Description = styled.p`
  font-size: 12px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 16px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
`;

export function AccountStatistics() {
  const { t } = useTranslation(['deals']);
  const navigate = useNavigate();

  const [statistics, setStatistics] = useState<StatisticsDealsDto>({
    deal_canceled: 0,
    deal_approved: 0,
    deal_created: 0,
    deal_paid: 0,
  });
  const [pending, setPending] = useState(true);

  const getStatistics = async () => {
    await Api.getStatistics().then((res) => setStatistics(res.data));
    setPending(false);
  };

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <Card>
      {pending && <ModalLoading />}
      <div>
        <TitleH2>{t('deals.account_statistics', { ns: 'deals' })}</TitleH2>
        <Description>{t('deals.all_time', { ns: 'deals' })}</Description>
        <Item>
          {t('deals.deals_approved', { ns: 'deals' })}
          <span>{statistics.deal_approved}</span>
        </Item>
        <Item>
          {t('deals.deals_hold', { ns: 'deals' })}
          <span>{statistics.deal_paid}</span>
        </Item>
        <Item>
          {t('deals.deals_created', { ns: 'deals' })}
          <span>{statistics.deal_created}</span>
        </Item>
        <Item>
          {t('deals.deals_rejected', { ns: 'deals' })}
          <span>{statistics.deal_canceled}</span>
        </Item>
      </div>
      <Button variant={'primary-inactive'} onClick={() => navigate('statistics')} maxWidth={'full'}>
        {t('deals.detailed_statistics', { ns: 'deals' })}
      </Button>
    </Card>
  );
}
