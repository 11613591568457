export function SvgEdit() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_219_3531)">
        <path
          d="M3.29128 13.6785L7.32892 12.0152C7.43002 11.9467 7.53113 11.8782 7.59962 11.813L14.2497 3.7051C14.4519 3.39853 14.4192 2.99411 14.1485 2.75603L10.9915 0.143632C10.7306 -0.0487919 10.3294 -0.0781446 10.075 0.244736L3.39239 8.34936C3.3239 8.45047 3.29128 8.55157 3.25541 8.65594L2.40744 12.9643C2.36504 13.7014 2.95536 13.8285 3.29128 13.6785ZM10.6523 1.59822L12.7885 3.36265L6.99299 10.4432L4.87633 8.69181L10.6523 1.59822ZM4.35124 10.0094L5.79931 11.2096L3.93378 11.9793L4.35124 10.0094Z"
          fill="#35434E"
        />
        <path
          d="M15.2997 14.6275H3.90107C3.90107 14.6275 1.53654 14.7384 1.44849 14.1318C1.44849 14.0992 1.38978 13.9492 1.69635 13.5773C1.93444 13.2708 1.89856 12.8664 1.62786 12.6283C1.32129 12.3902 0.916875 12.4261 0.678791 12.6968C0.0330303 13.4762 -0.136564 14.1546 0.17001 14.7678C1.00167 16.3169 3.79997 15.9517 3.93369 15.9843H15.2997C15.6715 15.9843 15.9781 15.6777 15.9781 15.3059C15.9814 14.9308 15.6748 14.6275 15.2997 14.6275Z"
          fill="#35434E"
        />
      </g>
      <defs>
        <clipPath id="clip0_219_3531">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
