import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const CouponWrapper = styled.div`
  position: relative;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 100px 100px 42px;
  gap: 10px;

  ${down('sm')} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    & > *:nth-of-type(1) {
      width: 100%;
    }

    & > *:nth-of-type(2) {
      width: 60px;
      max-width: fit-content;
    }

    & > *:nth-of-type(3) {
      width: 1fr;
      max-width: fit-content;
    }

    & > *:nth-of-type(4) {
      position: relative;
      width: 42px;
    }
  }
`;

export const CouponHeader = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 100px 100px 42px;
  gap: 10px;

  label {
    display: block;
    height: 20px;
    color: ${({ theme }) => theme.colors.secondary};
    white-space: nowrap;
  }
`;

export const CouponForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface CouponItemProps {
  isActive?: boolean;
  isCursor?: boolean;
}

export const CouponItem = styled.div<CouponItemProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.accent : 'transparent')};
  cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'default')};
`;

export const CouponAction = styled.div`
  font-size: 0;
  line-height: 1;
  cursor: pointer;
`;
