import { AxiosResponse } from 'axios';
import { $api } from '@partnerka-front/api';
import {
  CreatePostbackPayload,
  Paginated,
  PostbackDto,
  PostbackTemplatesDto,
} from '@partnerka-front/types';

const basePath = '/postback';

export async function getPostbackList(
  page = 1,
  pageSize = 10
): Promise<AxiosResponse<Paginated<PostbackDto>>> {
  return $api.get(`${basePath}/`, {
    params: {
      page,
      page_size: pageSize,
    },
  });
}

export async function getPostback(id: string): Promise<AxiosResponse<PostbackDto>> {
  return $api.get(`${basePath}/${id}/`);
}

export async function createPostback(payload: CreatePostbackPayload) {
  return $api.post(`${basePath}/`, payload);
}

export async function patchPostback(id: string, payload: Partial<CreatePostbackPayload>) {
  return $api.patch(`${basePath}/${id}/`, payload);
}

export async function deletePostback(id: string) {
  return $api.delete(`${basePath}/${id}/`);
}

export async function testPostback(url: string): Promise<AxiosResponse> {
  return $api.get(`${basePath}/test_postback_url/?url=${url}`);
}

export async function getPostbackTemplates(): Promise<AxiosResponse<PostbackTemplatesDto[]>> {
  return $api.get(`${basePath}/get_postback_templates/`);
}
