export function SvgStartPostback() {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 2.4458L2.05078 15.0786V23.8041L21 11.1713L39.9492 23.8041V15.0786L21 2.4458ZM21 12.9493L8.61328 21.3103V28.7163L21 20.3553L33.3867 28.7163V21.3103L21 12.9493ZM21 22.1368L13.8633 26.9541V32.4994L21 27.7416L28.1367 32.4994V26.9541L21 22.1368ZM21 29.5161L13.8633 34.2739V39.5541L21 34.7963L28.1367 39.5541V34.2739L21 29.5161Z"
        fill="#868E95"
      />
    </svg>
  );
}
