/*  Valid Email */
export const regExpEmail = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
/* Valid Email v2 */
export const regExpEmailCharacter = /^[a-zA-Z0-9-_+.@]+$/g;

/* Valid Password */
export const regExpPasswordCharacter = /^[a-zA-Z0-9!@#$%^&*()-_+=;:,./?|`~{}]+$/g;

/* Valid Url */
export const regExpUrl = /^(ftp|http|https):\/\/([A-Za-z0-9-]{1,})\.([A-z0-9]{1,}).*\S$/;

/* Valid Promo code */
export const regExpPromo = /^[a-zA-Zа-яА-Я0-9-–—]*$/g;

/* Valid Spaces */
export const regExpNoSpaces = /^\S*$/;

/* Valid Telegram */
export const regExpTelegramNickname = /^(?:@|https:\/\/t\.me\/)[A-Za-z\d_]{5,32}$/;
