import { Card } from '../card';
import styled from 'styled-components';
import { TextRule } from '@partnerka-front/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageLabel } from '@partnerka-front/utils';

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  label {
    line-height: 1.43;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

interface ButtonProps {
  isActive?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.accent : theme.colors.basic)};
  border-radius: 5px;
  cursor: pointer;
`;

const Description = styled.div`
  h2 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }

  b {
    font-weight: 700;
  }

  ul,
  ol {
    padding-left: 20px;
  }
`;

interface CatalogRulesProps {
  id: string;
  rules: TextRule[];
}

export function CatalogRules({ id, rules }: CatalogRulesProps) {
  const { t } = useTranslation(['catalog']);
  const [currentRule, setCurrentRule] = useState(0);

  return (
    <Card>
      <Labels>
        <label>{t('catalog.offer_rules', { ns: 'catalog' })}</label>
        <label>
          {t('catalog.id_catalog', { ns: 'catalog' })}: {id}
        </label>
      </Labels>
      <Nav>
        {rules.map((rule, i) => (
          <Button key={i} onClick={() => setCurrentRule(i)} isActive={i === currentRule}>
            {languageLabel(rule.language)}
          </Button>
        ))}
      </Nav>
      <Description>
        {rules
          .filter((item, i) => i === currentRule)
          .map((rule, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: rule.text }} />
          ))}
      </Description>
    </Card>
  );
}
