export function SvgPlusRectangle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4.66667C2 3.19391 3.19391 2 4.66667 2H11.3333C12.8061 2 14 3.19391 14 4.66667V11.3333C14 12.8061 12.8061 14 11.3333 14H4.66667C3.19391 14 2 12.8061 2 11.3333V4.66667Z"
        stroke="#35434E"
      />
      <path
        d="M5.33337 8.00004H8.00004M8.00004 8.00004H10.6667M8.00004 8.00004V5.33337M8.00004 8.00004V10.6667"
        stroke="#35434E"
        strokeLinecap="round"
      />
    </svg>
  );
}
