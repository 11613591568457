import { CatalogDto } from '@partnerka-front/types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card } from '../card';
import { RemunerationPercent } from '@partnerka-front/components';
import { handleRewardType } from '@partnerka-front/utils';

const Row = styled.div`
  display: flex;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.support};
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  span {
    font-size: 16px;
    line-height: 1.2;
  }
`;

interface CatalogConditionsProps {
  catalog: CatalogDto;
}

export function CatalogConditions({ catalog }: CatalogConditionsProps) {
  const { t } = useTranslation(['catalog', 'common']);

  return (
    <Card>
      <Row>
        <Item>
          <label>{t('catalog.reward_type', { ns: 'catalog' })}</label>
          <span>{catalog.reward_type ? handleRewardType(catalog.reward_type) : '-'}</span>
        </Item>
        <Item>
          <label>{t('catalog.award', { ns: 'catalog' })}</label>
          <RemunerationPercent reward={catalog.reward} />
        </Item>
      </Row>
      <Row>
        <Item>
          <label>Cookie</label>
          <span>
            {catalog.cookies_storage_days
              ? `${catalog.cookies_storage_days} ${t('common.days', { ns: 'common' })}`
              : '-'}
          </span>
        </Item>
        <Item>
          <label>{t('catalog.id_storage', { ns: 'catalog' })}</label>
          <span>{t('common.indefinitely', { ns: 'common' })}</span>
        </Item>
      </Row>
    </Card>
  );
}
