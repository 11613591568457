import React from 'react';
import styled from 'styled-components';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { SvgArrowDrop, SvgChevron } from '@partnerka-front/icons';

const SelectWrapper = styled.div`
  position: relative;

  select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid ${({ theme }) => theme.colors.support};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.headliner};
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }
`;

const ImgSection = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40px;
  height: 20px;
  background: linear-gradient(270deg, #ffffff 43.23%, rgba(255, 255, 255, 0.1) 100%);
  pointer-events: none;

  svg {
    transform: rotate(90deg);

    path {
      stroke: ${({ theme }) => theme.colors.headliner};
    }
  }
`;

const SimpleError = styled.div`
  padding: 2px 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.negative};
`;

export type Options = Partial<Record<string | number, string>>;

interface SelectProps {
  options: Options;
  value?: string | number;
  onChange?: (value: string | number, originalEvent: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  onBlur?: (value: string | number, originalEvent: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  error?: FieldError | string | null;
}

export function Select({ options, value, onChange, label, onBlur, disabled, error }: SelectProps) {
  const handleOnChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (onChange) onChange(e.currentTarget.value, e);
  };

  const handleBlur: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (onBlur) onBlur(e.currentTarget.value, e);
  };

  return (
    <div>
      <SelectWrapper>
        <select value={value} onChange={handleOnChange} onBlur={handleBlur} disabled={disabled}>
          {label && <optgroup label={label} />}
          {Object.keys(options).map((value) => {
            return (
              <option key={value} value={value}>
                {`${options[value]}`}
              </option>
            );
          })}
        </select>
        <ImgSection>
          <SvgChevron />
        </ImgSection>
      </SelectWrapper>

      {error && <SimpleError>{typeof error === 'string' ? error : error?.message}</SimpleError>}
    </div>
  );
}

interface ControlledSelectProps extends SelectProps {
  name: string;
}

export function ControlledSelect({ name, options, label }: ControlledSelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => {
        return (
          <Select
            options={options}
            label={label}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={error}
          />
        );
      }}
    />
  );
}

const SelectNavWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 44px;
  height: 24px;

  select {
    width: 44px;
    height: 24px;
    border: none;
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
  }
`;

interface SelectNavProps {
  options: Options;
  value: string | number;
  onChange: (value: string | number, originalEvent: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function SelectNav({ options, value, onChange }: SelectNavProps) {
  const handleOnChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (onChange) onChange(e.currentTarget.value, e);
  };

  return (
    <SelectNavWrapper>
      <select value={value} onChange={handleOnChange}>
        {Object.keys(options).map((value) => {
          return (
            <option key={value} value={value}>
              {`${options[value]}`}
            </option>
          );
        })}
      </select>
      <SvgArrowDrop />
    </SelectNavWrapper>
  );
}
