import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { SectionRelative } from '@partnerka-front/components';
import { UnknownFunction } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';

interface FilterWrapperProps {
  isOpen: boolean;
}

const FilterWrapper = styled.div<FilterWrapperProps>`
  position: ${({ isOpen }) => (isOpen ? 'absolute' : 'relative')};
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '40px')};
  border: 1px solid ${({ theme, isOpen }) => (isOpen ? 'transparent' : theme.colors.secondary)};
  border-radius: 5px;
  padding: ${({ isOpen }) => (isOpen ? '20px' : '6px 20px')};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.07);
  z-index: 10;
`;

const FilterImitation = styled.div`
  width: 100%;
  max-width: 300px;
  height: 40px;
`;

const FilterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FilterTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.42;
`;

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  font-size: 12px;
  line-height: 1.66;
`;

const FilterBntClear = styled.button`
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 1.66;
  color: ${({ theme }) => theme.colors.secondary};
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.negative};
  }
`;

const FilterBtnOpen = styled.button`
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 1.66;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const FilterMenu = styled.div`
  width: 100%;
  max-width: 300px;
`;

const CountFilters = styled.span`
  color: ${({ theme }) => theme.colors.accent};
`;

interface FilterProps {
  onClearFilter: UnknownFunction;
  children?: ReactNode;
  countActiveFilters: number;
}

export function Filter({ onClearFilter, countActiveFilters, children }: FilterProps) {
  const { t } = useTranslation(['deals']);
  const [isOpen, setIsOpen] = useState(false);

  const handleClear = () => {
    onClearFilter();
  };

  const handleIsOpen = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <SectionRelative>
      {isOpen && <FilterImitation />}
      <FilterWrapper isOpen={isOpen}>
        <FilterTop>
          <FilterTitle>
            {t('deals.filter.title', { ns: 'deals' })}{' '}
            {countActiveFilters > 0 && <CountFilters>({countActiveFilters})</CountFilters>}
          </FilterTitle>
          <FilterButtons>
            <FilterBntClear onClick={handleClear}>
              {t('deals.filter.clear', { ns: 'deals' })}
            </FilterBntClear>{' '}
            |{' '}
            <FilterBtnOpen onClick={handleIsOpen}>
              {isOpen
                ? t('deals.filter.roll_up', { ns: 'deals' })
                : t('deals.filter.open', { ns: 'deals' })}
            </FilterBtnOpen>
          </FilterButtons>
        </FilterTop>
        {isOpen && <FilterMenu>{children}</FilterMenu>}
      </FilterWrapper>
    </SectionRelative>
  );
}
