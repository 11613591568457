import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
