export function SvgCancel() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_219_3511)">
        <path
          d="M8 0C3.57628 0 0 3.57628 0 8C0 12.4237 3.57628 16 8 16C12.4237 16 16 12.4237 16 8C16 3.57628 12.4237 0 8 0ZM8 14.6716C4.32229 14.6716 1.32843 11.6777 1.32843 8C1.32843 4.32229 4.32229 1.32843 8 1.32843C11.6777 1.32843 14.6716 4.32229 14.6716 8C14.6716 11.6777 11.6777 14.6716 8 14.6716Z"
          fill="#EE3D4A"
        />
        <path
          d="M11.6091 4.39118C11.3375 4.11961 10.9285 4.11961 10.657 4.39118L8.00012 7.04803L5.34654 4.39445C5.07496 4.12288 4.66596 4.12288 4.39439 4.39445C4.12282 4.66603 4.12282 5.07503 4.39439 5.3466L7.04797 8.00018L4.39439 10.6538C4.12282 10.9253 4.113 11.3441 4.39439 11.6059C4.60707 11.8022 5.00952 11.9462 5.34654 11.6059L8.00012 8.95233L10.6537 11.6059C11.0594 11.9887 11.4717 11.7433 11.6058 11.6059C11.8774 11.3343 11.8774 10.9253 11.6058 10.6538L8.95226 8.00018L11.6058 5.3466C11.8807 5.07175 11.8807 4.66276 11.6091 4.39118Z"
          fill="#EE3D4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_219_3511">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
