import styled from 'styled-components';
import { Status } from '@partnerka-front/types';

interface StatusProps {
  status: Status;
}

export const StatusModified = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.headliner};
  
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 6px;
    border-radius: 100%;
    background-color: ${({ status, theme }) => {
      if (status === 'Created') return theme.colors.secondary;
      if (status === 'Paid') return theme.colors.notification;
      if (status === 'Approved') return theme.colors.positive;
      if (status === 'Canceled') return theme.colors.negative;
      return theme.colors.secondary;
    }};
`;

export const StatusSpan = styled.span<StatusProps>`
  color: ${({ theme, status }) => {
    if (status === 'Created') return theme.colors.secondary;
    if (status === 'Paid') return theme.colors.notification;
    if (status === 'Approved') return theme.colors.positive;
    if (status === 'Canceled') return theme.colors.negative;
    return theme.colors.secondary;
  }};
`;
