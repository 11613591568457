import { useTranslation } from 'react-i18next';
import { CouponDto, ResponseError } from '@partnerka-front/types';
import * as Yup from 'yup';
import { regExpPromo } from '@partnerka-front/utils';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CouponAction, CouponForm, CouponItem, CouponWrapper } from './style';
import { ControlledSimpleInput } from '../../UI';
import { DateFormat } from '../../date-format';
import { Api } from '@partnerka-front/api';
import { useState } from 'react';
import { SvgEditRectangle, SvgNewEdit } from '@partnerka-front/icons';
import { toast } from 'react-toast';
import { SectionLoading } from '../../spinner';

interface CatalogCouponItemProps {
  coupon: CouponDto;
}

export function CatalogCouponItem({ coupon }: CatalogCouponItemProps) {
  const { t } = useTranslation(['promo', 'validation', 'errors']);

  const [isEditCoupon, setIsEditCoupon] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const CouponSchema = Yup.object().shape({
    code: Yup.string()
      .min(
        2,
        `${t('validation.coupon_must_be_at_least_characters', {
          characters: '2',
          ns: 'validation',
        })}`
      )
      .max(
        200,
        `${t('validation.coupon_must_be_at_most_characters', {
          characters: '200',
          ns: 'validation',
        })}`
      )
      .matches(regExpPromo, `${t('validation.promo', { ns: 'validation' })}`),
  });
  const formCoupon = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(CouponSchema),
    defaultValues: {
      code: coupon.code,
    },
  });
  const { handleSubmit, setFocus } = formCoupon;

  const patchCoupon = handleSubmit(async (data) => {
    setIsPending(true);
    setError(null);

    await Api.patchCoupon(coupon.id, { code: data.code })
      .then(() => {
        setIsEditCoupon(false);
        toast.success(`${t('coupon.promo_changed', { ns: 'coupon' })}`);
      })
      .catch(setError);

    setIsPending(false);
  });

  const handlerEditCoupon = async () => {
    if (isEditCoupon) {
      await patchCoupon();
    } else {
      setIsEditCoupon(true);
      setFocus('code');
    }
  };

  return (
    <FormProvider {...formCoupon}>
      <CouponForm onSubmit={patchCoupon}>
        {isPending && <SectionLoading />}

        <CouponWrapper>
          <ControlledSimpleInput
            name={'code'}
            mode={isEditCoupon ? 'default' : 'copy'}
            error={
              error &&
              (error.response.data && error.response.data.detail
                ? `${t(`errors.${error.response.data.detail}`, { ns: 'errors' })}`
                : t('errors.try_again_later', { ns: 'errors' }))
            }
          />
          <CouponItem>
            {t('coupon.ab', { ns: 'coupon' })} {coupon.fix_discount}
          </CouponItem>
          <CouponItem>
            <DateFormat date={coupon.created_at} />
          </CouponItem>
          <CouponItem onClick={handlerEditCoupon} isActive={isEditCoupon} isCursor>
            <CouponAction>{isEditCoupon ? <SvgEditRectangle /> : <SvgNewEdit />}</CouponAction>
          </CouponItem>
        </CouponWrapper>
      </CouponForm>
    </FormProvider>
  );
}
