import { useEffect, useRef, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { DealDto } from '@partnerka-front/types';
import { DateFormat, Price, StatusModified, StatusSpan } from '@partnerka-front/components';
import { useTranslation } from 'react-i18next';

const RewardsItemId = styled.td`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: underline;
  cursor: pointer;
`;

const WrapperRewardsItemModified = styled.div`
  ${down('md')} {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(53, 67, 78, 0.78);
  }
`;

const RewardsItemModified = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  padding: 10px 10px 5px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};

  span {
    display: block;
    text-decoration: underline;
  }

  ${down('md')} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const RewardsItemModifiedList = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  min-width: 180px;
  margin-left: 20px;
`;

const RewardsItemModifiedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};

  &:last-child {
    border-bottom: none;
  }

  span {
    display: block;
    width: 80px;
    min-width: 80px;
    color: ${({ theme }) => theme.colors.headliner};
    text-decoration: none;
  }
`;

const Approved = styled.span`
  color: ${({ theme }) => theme.colors.accent};
`;

const NoWrap = styled.div`
  white-space: nowrap;
`;

interface RewardsItemProps {
  deal: DealDto;
}

export function Deal({ deal }: RewardsItemProps) {
  const { t } = useTranslation(['deals']);
  const [isModified, setIsModified] = useState(false);
  const refInfo = useRef<HTMLTableDataCellElement>(null);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (e.target !== refInfo.current) setIsModified(false);
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <tr>
      <RewardsItemId onClick={() => setIsModified(!isModified)} ref={refInfo}>
        {deal.id}
        {isModified && (
          <WrapperRewardsItemModified>
            <RewardsItemModified>
              <span>{deal.id}</span>
              <RewardsItemModifiedList>
                <RewardsItemModifiedItem>
                  <StatusModified status={'Created'}>
                    {t('deals.created', { ns: 'deals' })}
                  </StatusModified>
                  <span>
                    <DateFormat date={deal.conversion_time} />
                  </span>
                </RewardsItemModifiedItem>
                <RewardsItemModifiedItem>
                  <StatusModified status={'Paid'}>
                    {t('deals.paid', { ns: 'deals' })}
                  </StatusModified>
                  <span>{deal.paid_at ? <DateFormat date={deal.paid_at} /> : '-'}</span>
                </RewardsItemModifiedItem>
                <RewardsItemModifiedItem>
                  <StatusModified status={'Approved'}>
                    {t('deals.approved', { ns: 'deals' })}
                  </StatusModified>
                  <span>{deal.approved_at ? <DateFormat date={deal.approved_at} /> : '-'}</span>
                </RewardsItemModifiedItem>
                <RewardsItemModifiedItem>
                  <StatusModified status={'Canceled'}>
                    {t('deals.canceled', { ns: 'deals' })}
                  </StatusModified>
                  <span>{deal.canceled_at ? <DateFormat date={deal.canceled_at} /> : '-'}</span>
                </RewardsItemModifiedItem>
              </RewardsItemModifiedList>
            </RewardsItemModified>
          </WrapperRewardsItemModified>
        )}
      </RewardsItemId>
      <td>
        {deal.status === 'Approved' && t('deals.approved', { ns: 'deals' })}
        {deal.status === 'Paid' && t('deals.paid', { ns: 'deals' })}
        {deal.status === 'Created' && t('deals.created', { ns: 'deals' })}
        {deal.status === 'Canceled' && t('deals.canceled', { ns: 'deals' })}
        {deal.status === 'Rejected' && t('deals.rejected', { ns: 'deals' })}
      </td>
      <td>
        <DateFormat date={deal.conversion_time} />
      </td>
      <td>
        {deal.offer.catalog.title}. {deal.offer.name}
      </td>
      <td>{deal.promo}</td>
      <td>
        <NoWrap>
          <Price amount={deal.cost} currency={deal.currency} />
        </NoWrap>
      </td>
      <td>
        <NoWrap>
          {deal.status === 'Approved' && (
            <Approved>
              <Price amount={deal.payment_sum} currency={deal.currency} />
            </Approved>
          )}
          {deal.status === 'Paid' && <Price amount={deal.payment_sum} currency={deal.currency} />}
          {deal.status === 'Created' && (
            <Price amount={deal.payment_sum} currency={deal.currency} />
          )}
          {deal.status === 'Canceled' && (
            <StatusSpan status={'Canceled'}>
              {deal.is_returned
                ? t('deals.refund', { ns: 'deals' })
                : t('deals.canceled', { ns: 'deals' })}
            </StatusSpan>
          )}
        </NoWrap>
      </td>
    </tr>
  );
}
