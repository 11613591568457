export function SvgManager() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.9021 3.5901 15.6665 4.59721 17.1199C4.70168 17.2707 4.7226 17.4653 4.64529 17.6317L3.42747 20.2519C3.23699 20.5853 3.47768 21 3.86159 21H12Z"
        stroke="#6E84A3"
        strokeWidth="1.5"
      />
      <path
        d="M8.25 12C8.25 12.1381 8.13806 12.25 8 12.25C7.86194 12.25 7.75 12.1381 7.75 12C7.75 11.8619 7.86194 11.75 8 11.75C8.13806 11.75 8.25 11.8619 8.25 12Z"
        fill="#6E84A3"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 12C16.25 12.138 16.138 12.25 16 12.25C15.862 12.25 15.75 12.138 15.75 12C15.75 11.862 15.862 11.75 16 11.75C16.138 11.75 16.25 11.862 16.25 12Z"
        fill="#6E84A3"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 12C12.25 12.138 12.138 12.25 12 12.25C11.862 12.25 11.75 12.138 11.75 12C11.75 11.862 11.862 11.75 12 11.75C12.138 11.75 12.25 11.862 12.25 12Z"
        fill="#6E84A3"
        stroke="#6E84A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
