export function SvgSumSignWhite() {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9683 10.2066V8.82902H4.01385L9.28297 14.4962C9.61284 14.8514 9.60838 15.4035 9.27243 15.7527L4.06275 21.1711H12.9681V20.339C12.9681 19.8349 13.3783 19.4248 13.8826 19.4248C14.3867 19.4248 14.7972 19.8349 14.7972 20.339V22.0853C14.7972 22.5896 14.3869 23 13.8826 23H1.91482C1.54784 23 1.21737 22.7818 1.0734 22.4437C0.929367 22.1058 1.00107 21.7164 1.25544 21.4516L7.35474 15.1084L1.245 8.53722C0.997436 8.27062 0.931217 7.88302 1.07644 7.54925C1.22183 7.21561 1.55105 7 1.91482 7H13.8828C14.3869 7 14.7974 7.41017 14.7974 7.91464V10.2066C14.7974 10.7109 14.3871 11.1212 13.8828 11.1212C13.3785 11.1212 12.9683 10.7111 12.9683 10.2066Z"
        fill="white"
      />
    </svg>
  );
}
