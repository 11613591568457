import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

interface ToggleWrapperProps {
  isDisabled?: boolean;
}

const ToggleWrapper = styled.label<ToggleWrapperProps>`
  position: relative;

  width: 36px;
  height: 22px;
  border-radius: 11px;
  background-color: ${({ theme }) => theme.colors.support};
  transition: 0.3s;
  cursor: pointer;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.6' : '1')};

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16);
    transition: 0.3s;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.accent};

    &::before {
      transform: translateX(14px);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0), 0px 0px 1px rgba(0, 0, 0, 0);
    }
  }

  input {
    display: none;
  }
`;

export interface ToggleProps {
  defaultValue?: boolean;
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}
export function Toggle({ defaultValue, onChange, disabled }: ToggleProps) {
  const [innerValue, setInnerValue] = useState(defaultValue ?? false);

  const handleToggle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInnerValue(e.currentTarget.checked);
    if (onChange) onChange(e.currentTarget.checked, e);
  };

  return (
    <ToggleWrapper className={innerValue ? 'active' : ''} isDisabled={disabled}>
      <input type={'checkbox'} checked={innerValue} onChange={handleToggle} disabled={disabled} />
    </ToggleWrapper>
  );
}

interface ControlledToggleProps extends ToggleProps {
  name: string;
}

export function ControlledToggle({ name, disabled }: ControlledToggleProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Toggle defaultValue={value} onChange={onChange} disabled={disabled} />
      )}
    />
  );
}
