import { useEffect, useMemo, useState } from 'react';
import { Paginated, SumByStatusDto } from '@partnerka-front/types';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toast';

type PaginatedCallback<T> = (
  page: number,
  pageSize: number
) => Promise<AxiosResponse<Paginated<T>>>;

export function usePaginated<T>(callback: PaginatedCallback<T>, textError?: string) {
  const [firstLoading, setFirstLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [items, setItems] = useState<Array<T>>([]);
  const [sumByStatus, setSumByStatus] = useState<SumByStatusDto | null>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(
    Number(localStorage.getItem('pageSize')) > 9 ? Number(localStorage.getItem('pageSize')) : 10
  );
  const [itemsTotal, setItemsTotal] = useState(0);
  const pagesTotal = useMemo(() => Math.ceil(itemsTotal / pageSize), [itemsTotal, pageSize]);

  const fetchItems = async () => {
    setPending(true);

    try {
      const { data } = await callback(page, pageSize);

      if (data.sum_by_status) setSumByStatus(data.sum_by_status);
      setItems(data.results);
      setItemsTotal(data.count);
      setFirstLoading(true);
    } catch (e) {
      toast.error(textError ? textError : 'Error! Please try again later');
    }

    setPending(false);
  };

  useEffect(() => {
    fetchItems();
  }, [page]);

  useEffect(() => {
    page === 1 ? fetchItems() : setPage(1);
  }, [pageSize]);

  return {
    firstLoading,
    pending,
    items,
    fetchItems,
    itemsTotal,
    pagesTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    sumByStatus,
  };
}
