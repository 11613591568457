import styled from 'styled-components';

interface InlineProps {
  gap?: number;
}

export const Inline = styled.div<InlineProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => (gap ? `${gap}px` : '64px')};
`;
