import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@partnerka-front/components';

const FilterCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  div {
    display: inline;
    font-size: 12px;
    line-height: 20px;
    line-height: 1.66;
  }

  span {
    margin-left: 10px;
    font-size: 10px;
  }
`;

interface FilterCheckboxProps {
  title: string;
  count?: number;
  value: boolean;
  onChange: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
}

export function FilterCheckbox({ title, count, value, onChange }: FilterCheckboxProps) {
  return (
    <FilterCheckboxWrapper>
      <Checkbox value={value} onChange={onChange}>
        <div>{title}</div>
        {count && <span>({count})</span>}
      </Checkbox>
    </FilterCheckboxWrapper>
  );
}
