export function SvgPaperclip() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3334 5.20215L5.96768 12.5679C5.47953 13.056 5.47953 13.8474 5.96768 14.3356C6.45584 14.8238 7.2473 14.8238 7.73545 14.3356L15.1011 6.96992C16.0775 5.9936 16.0775 4.4107 15.1011 3.43438C14.1248 2.45807 12.542 2.45807 11.5656 3.43438L4.19991 10.8001C2.73545 12.2645 2.73545 14.6389 4.19991 16.1034C5.66438 17.5679 8.03875 17.5679 9.50321 16.1034L17.0834 8.5232"
        stroke="#35434E"
        strokeLinecap="round"
      />
    </svg>
  );
}
