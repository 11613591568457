import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.accent};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export function Spinner() {
  return (
    <SpinnerWrapper>
      <div />
      <div />
      <div />
      <div />
    </SpinnerWrapper>
  );
}

const FullScreenSpinnerWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

export function FullScreenSpinner() {
  return (
    <FullScreenSpinnerWrapper>
      <Spinner />
    </FullScreenSpinnerWrapper>
  );
}

const ModalLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(2) blur(20px);
  box-shadow: inset 0 0 16px 10px #fff;
`;

export function ModalLoading() {
  return (
    <ModalLoadingWrapper>
      <Spinner />
    </ModalLoadingWrapper>
  );
}

const PageLoadingWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

export function PageLoading() {
  return (
    <PageLoadingWrapper>
      <Spinner />
    </PageLoadingWrapper>
  );
}

const SectionLoadingWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: brightness(1) blur(1px);
`;

export function SectionLoading() {
  return (
    <SectionLoadingWrapper>
      <Spinner />
    </SectionLoadingWrapper>
  );
}
