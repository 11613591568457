import { useTranslation } from 'react-i18next';
import { Balance, Container, DealsTable, Title } from '@partnerka-front/components';

export function FinancePage() {
  const { t } = useTranslation(['finance']);

  return (
    <>
      <Title>{t('finance.title', { ns: 'finance' })}</Title>
      <Container>
        <Balance />
        <DealsTable />
      </Container>
    </>
  );
}
