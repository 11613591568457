import { Card } from './card';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { useUser } from '@partnerka-front/utils';

const BannerWrapper = styled(Card)`
  height: 100%;
`;

interface BannerImg {
  img?: string;
}

const BannerImg = styled.div<BannerImg>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.inactiveGrey};
  border-radius: 10px;
  background-image: ${({ img }) =>
    img ? `url(${img})` : `url('./assets/img/mock/image-black.png')`};
  background-size: ${({ img }) => (img ? `cover` : `60px`)};
  background-repeat: no-repeat;
  background-position: center;

  ${down('xl')} {
    padding-top: 56.25%; // 16:9
  }
`;

export function Banner() {
  const { user } = useUser();

  if (!user) return null;

  return (
    <BannerWrapper size={'mini'}>
      <BannerImg img={user.banner} />
    </BannerWrapper>
  );
}
