import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Card } from '../../card';
import { CouponForm, CouponHeader, CouponItem, CouponWrapper } from './style';
import { Button, ControlledSimpleInput } from '@partnerka-front/components';
import { SvgEditRectangle, SvgPlusRectangle } from '@partnerka-front/icons';
import { CouponDto, GlobalEvents, ResponseError } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';
import { SectionLoading } from '../../spinner';
import { format } from 'date-fns';
import { regExpPromo, useEventBus } from '@partnerka-front/utils';
import { CatalogCouponItem } from './item';
import { toast } from 'react-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

interface CatalogCouponProps {
  catalogId: string;
  coupons?: CouponDto[];
}

export function CatalogCoupon({ catalogId, coupons }: CatalogCouponProps) {
  const { t } = useTranslation(['coupon', 'validation', 'errors']);
  const { emit } = useEventBus();

  const [isNewCoupon, setIsNewCoupon] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const CouponSchema = Yup.object().shape({
    code: Yup.string()
      .min(
        2,
        `${t('validation.coupon_must_be_at_least_characters', {
          characters: '2',
          ns: 'validation',
        })}`
      )
      .max(
        200,
        `${t('validation.coupon_must_be_at_most_characters', {
          characters: '200',
          ns: 'validation',
        })}`
      )
      .matches(regExpPromo, `${t('validation.promo', { ns: 'validation' })}`),
  });
  const formCreateCoupon = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(CouponSchema),
    defaultValues: {
      code: '',
    },
  });
  const { handleSubmit, reset, setFocus, watch } = formCreateCoupon;

  const createCoupon = handleSubmit(async (data) => {
    setIsPending(true);
    setError(null);

    await Api.createCoupon({ code: data.code, catalog: [catalogId] })
      .then(() => {
        reset();
        toast.success(`${t('coupon.promo_created', { ns: 'coupon' })}`);
        setIsNewCoupon(false);

        emit(GlobalEvents.fetchCatalog);
      })
      .catch(setError);

    setIsPending(false);
  });

  useEffect(() => {
    setError(null);
  }, [watch('code')]);

  return (
    <Card childrenBottom={10}>
      <CouponHeader>
        <label>{t('coupon.title', { ns: 'coupon' })}</label>
        <label>{t('coupon.size', { ns: 'coupon' })}</label>
        <label>{t('coupon.date_create', { ns: 'coupon' })}</label>
        <label></label>
      </CouponHeader>

      {isPending && <SectionLoading />}

      {coupons?.map((coupon) => (
        <CatalogCouponItem key={coupon.id} coupon={coupon} />
      ))}

      {isNewCoupon && (
        <FormProvider {...formCreateCoupon}>
          <CouponForm onSubmit={createCoupon}>
            <CouponWrapper>
              <ControlledSimpleInput
                name={'code'}
                error={
                  error &&
                  (error.response.data && error.response.data.detail
                    ? `${t(`errors.${error.response.data.detail}`, { ns: 'errors' })}`
                    : t('errors.try_again_later', { ns: 'errors' }))
                }
              />
              <CouponItem>{t('coupon.ab', { ns: 'coupon' })} ...</CouponItem>
              <CouponItem>{format(new Date(), 'dd.MM.yyyy')}</CouponItem>
              <CouponItem isActive isCursor onClick={createCoupon}>
                <SvgEditRectangle />
              </CouponItem>
            </CouponWrapper>
          </CouponForm>
        </FormProvider>
      )}

      {coupons && coupons.length > 9 ? (
        <div>{t('errors.MAX_COUNTS_COUPONS_IS_10', { ns: 'errors' })}</div>
      ) : (
        <Button
          variant={'basic'}
          onClick={() => {
            setIsNewCoupon(true);
            setTimeout(() => setFocus('code'));
          }}
          disabled={isNewCoupon}>
          <SvgPlusRectangle />
          {t('coupon.new_promo', { ns: 'coupon' })}
        </Button>
      )}
    </Card>
  );
}
