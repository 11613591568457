import { ChangeEvent, ChangeEventHandler, ReactNode, useMemo, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { uid } from 'uid';
import { SimpleInputError } from './input';

type CheckboxVariant = 'default' | 'big';

interface CheckboxStyleProps {
  variant?: CheckboxVariant;
}

const CheckboxWrapper = styled.div`
  position: relative;
  min-height: 20px;

  input {
    position: absolute;
    opacity: 0;
  }
`;

const CheckboxLabel = styled.label<CheckboxStyleProps>`
  display: block;
  min-height: 20px;
  padding-top: ${({ variant }) => {
    if (variant === 'big') return `5px`;
    return '3px';
  }};
  padding-left: ${({ variant }) => {
    if (variant === 'big') return `30px`;
    return '24px';
  }};
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: ${({ variant }) => {
      if (variant === 'big') return `20px`;
      return '16px';
    }};
    height: ${({ variant }) => {
      if (variant === 'big') return `20px`;
      return '16px';
    }};
    border: 1px solid ${({ theme }) => theme.colors.headliner};
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    box-sizing: border-box;
  }

  &:after {
    transform: scale(0);
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.75008 14.6667L3.66675 9.66675L5.50008 7.83341L8.75008 11.1667L14.9167 4.91675L16.7501 6.75008L8.75008 14.6667Z' fill='%23F6F8F9'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  input:checked + &::before {
    border-color: ${({ theme }) => theme.colors.accent};
    background-color: ${({ theme }) => theme.colors.accent};
    opacity: 1;
  }

  input:checked + &::after {
    border-color: ${({ theme }) => theme.colors.accent};
    transform: scale(1);
    opacity: 1;
  }
`;

interface CheckboxProps {
  value?: boolean;
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
  variant?: CheckboxVariant;
  children?: ReactNode;
  error?: FieldError | string;
  dataTestId?: string;
}

export function Checkbox({ value, onChange, variant, children, error, dataTestId }: CheckboxProps) {
  const id = useMemo(() => uid(), []);
  const [innerValue, setInnerValue] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInnerValue(e.currentTarget.checked);
    if (onChange) onChange(e.currentTarget.checked, e);
  };

  return (
    <CheckboxWrapper>
      <input
        id={id}
        type={'checkbox'}
        checked={value ?? innerValue}
        onChange={handleChange}
        data-testid={dataTestId}
      />
      <CheckboxLabel htmlFor={id} variant={variant}>
        {children}
        {error && (
          <SimpleInputError>{typeof error === 'string' ? error : error?.message}</SimpleInputError>
        )}
      </CheckboxLabel>
    </CheckboxWrapper>
  );
}

export interface ControlledCheckboxProps {
  name: string;
  variant?: CheckboxVariant;
  children?: ReactNode;
  dataTestId?: string;
}

export function ControlledCheckbox({
  name,
  variant,
  children,
  dataTestId,
}: ControlledCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Checkbox
            value={value}
            onChange={onChange}
            variant={variant}
            error={error}
            dataTestId={dataTestId}>
            {children}
          </Checkbox>
        );
      }}
    />
  );
}
