export function SvgVerified() {
  return (
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="32" rx="5" fill="#89C541" />
      <path
        d="M25.5646 9.46119L21.8315 8.28231C21.239 8.09522 20.6213 8 20 8C19.3787 8 18.761 8.09522 18.1685 8.28231L14.4354 9.46119C13.6553 9.70753 13.125 10.4311 13.125 11.2492V17.7301C13.125 18.9216 13.6913 20.0422 14.6505 20.7491L18.3078 23.4439C18.798 23.8051 19.391 24 20 24C20.609 24 21.202 23.8051 21.6923 23.4439L25.3495 20.7491C26.3088 20.0422 26.875 18.9216 26.875 17.7301V11.2492C26.875 10.4311 26.3447 9.70753 25.5646 9.46119ZM22.8726 15.1161L19.779 18.2097C19.4129 18.5758 18.8193 18.5758 18.4532 18.2097L17.1274 16.8839C16.7613 16.5178 16.7613 15.9242 17.1274 15.5581C17.4935 15.192 18.0871 15.192 18.4532 15.5581L19.1161 16.221L21.5468 13.7903C21.9129 13.4242 22.5065 13.4242 22.8726 13.7903C23.2388 14.1564 23.2388 14.75 22.8726 15.1161Z"
        fill="white"
      />
    </svg>
  );
}
