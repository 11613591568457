import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Api } from '@partnerka-front/api';
import {
  AuthCard,
  AuthCardTitle,
  AuthItem,
  AuthItemDescription,
  AuthItemTitle,
  AuthSection,
  AuthSubmitButtonSection,
  AuthTitleSection,
  Button,
  ButtonAction,
  ControlledCheckbox,
  ControlledSimpleInput,
  ControlledTextarea,
  FooterAuth,
  FormAuth,
  LinkNext,
  Message,
  PageAuth,
  PageLoading,
  Paragraph,
  SimpleInputError,
  Title,
  TitleH2,
} from '@partnerka-front/components';
import { Categories, ResponseError, UnknownFunction } from '@partnerka-front/types';
import { SvgEmailSuccessfully } from '@partnerka-front/icons';
import {
  regExpEmail,
  regExpEmailCharacter,
  regExpPasswordCharacter,
  regExpTelegramNickname,
} from '@partnerka-front/utils';
import styled from 'styled-components';

const SendAgain = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

enum RegisterSteps {
  confirm = 'confirm',
  success = 'success',
}

interface RegistrationPageProps {
  isBusiness?: boolean;
  onSubmit?: UnknownFunction;
}

export function RegistrationPage({ isBusiness, onSubmit }: RegistrationPageProps) {
  const { t } = useTranslation(['auth', 'validation', 'errors', 'common']);
  const { uid } = useParams();

  const formSchema = Yup.object()
    .shape({
      email: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(regExpEmailCharacter, `${t('validation.invalid_character', { ns: 'validation' })}`)
        .matches(regExpEmail, `${t('validation.incorrect_email', { ns: 'validation' })}`),
      password: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(
          regExpPasswordCharacter,
          `${t('validation.invalid_character', { ns: 'validation' })}`
        )
        .min(
          8,
          `${t('validation.password_must_be_at_least_characters', {
            characters: '8',
            ns: 'validation',
          })}`
        )
        .max(
          64,
          `${t('validation.password_must_be_at_most_characters', {
            characters: '64',
            ns: 'validation',
          })}`
        ),
      password_repeated: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .oneOf(
          [Yup.ref('password'), null],
          `${t('validation.passwords_must_match', { ns: 'validation' })}`
        ),
      telegram_username: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(
          regExpTelegramNickname,
          `${t('validation.telegram_nickname', { ns: 'validation' })}`
        ),
      registration_message: Yup.string().required(
        `${t('validation.required', { ns: 'validation' })}`
      ),
      privacy: Yup.boolean().isTrue(`${t('validation.required', { ns: 'validation' })}`),
      fraud: Yup.boolean().isTrue(`${t('validation.required', { ns: 'validation' })}`),
    })
    .test('isVerticals', 'Надо выбрать хотя бы одну вертикаль', function (values) {
      return values.isGambling || values.isBetting || values.isEducation || values.isSoftware;
    });
  const formSchemaBusiness = Yup.object()
    .shape({
      email: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(regExpEmailCharacter, `${t('validation.invalid_character', { ns: 'validation' })}`)
        .matches(regExpEmail, `${t('validation.incorrect_email', { ns: 'validation' })}`),
      password: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(
          regExpPasswordCharacter,
          `${t('validation.invalid_character', { ns: 'validation' })}`
        )
        .min(
          8,
          `${t('validation.password_must_be_at_least_characters', {
            characters: '8',
            ns: 'validation',
          })}`
        )
        .max(
          64,
          `${t('validation.password_must_be_at_most_characters', {
            characters: '64',
            ns: 'validation',
          })}`
        ),
      password_repeated: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .oneOf(
          [Yup.ref('password'), null],
          `${t('validation.passwords_must_match', { ns: 'validation' })}`
        ),
      telegram_username: Yup.string()
        .required(`${t('validation.required', { ns: 'validation' })}`)
        .matches(
          regExpTelegramNickname,
          `${t('validation.telegram_nickname', { ns: 'validation' })}`
        ),
      registration_message: Yup.string().required(
        `${t('validation.required', { ns: 'validation' })}`
      ),
      privacy: Yup.boolean().isTrue(`${t('validation.required', { ns: 'validation' })}`),
    })
    .test('isVerticals', 'Надо выбрать хотя бы одну вертикаль', function (values) {
      return values.isGambling || values.isBetting || values.isEducation || values.isSoftware;
    });
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    resolver: yupResolver(isBusiness ? formSchemaBusiness : formSchema),
    defaultValues: {
      email: '',
      password: '',
      password_repeated: '',
      telegram_username: '',
      isGambling: true,
      isBetting: false,
      isEducation: false,
      isSoftware: false,
      privacy: false,
      fraud: false,
      email_newsletter: false,
      registration_message: '',
    },
  });
  const { handleSubmit, getValues, watch } = formMethods;

  const [step, setStep] = useState(RegisterSteps.confirm);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);
  const [errorResend, setErrorResend] = useState<ResponseError | null>(null);
  const [successResend, setSuccessResend] = useState(false);

  const resendActivation = async () => {
    setPending(true);
    await Api.resendActivation({
      email: getValues('email'),
    })
      .then(() => {
        setSuccessResend(true);
        setError(null);
      })
      .catch((err) => {
        setErrorResend(err);
        setError(err);
      })
      .finally(() => setPending(false));
  };

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setSuccessResend(false);
    setError(null);

    const {
      email,
      email_newsletter,
      password,
      registration_message,
      telegram_username,
      isBetting,
      isEducation,
      isGambling,
      isSoftware,
    } = data;
    const ref = localStorage.getItem('ref');
    const categories: Categories[] = [];
    if (isBetting === true) categories.push(Categories.Betting);
    if (isEducation === true) categories.push(Categories.Education);
    if (isGambling === true) categories.push(Categories.Gambling);
    if (isSoftware === true) categories.push(Categories.Software);

    await Api.registration({
      email,
      email_newsletter,
      password,
      registration_message,
      telegram_username,
      referral: ref,
      categories,
    })
      .then(() => {
        setStep(RegisterSteps.success);
        localStorage.removeItem('ref');
      })
      .catch(setError);

    if (onSubmit)
      onSubmit({
        email,
        email_newsletter,
        password,
        registration_message,
        telegram_username,
        referral: ref,
        categories,
      });
    setPending(false);
  });

  useEffect(() => {
    setError(null);
    setErrorResend(null);
    setSuccessResend(false);
  }, [step]);

  useEffect(() => {
    if (uid) localStorage.setItem('ref', uid);
  }, []);

  if (step === RegisterSteps.success) {
    return (
      <PageAuth>
        <FormAuth isBusiness={isBusiness}>
          {pending && <PageLoading />}
          <SvgEmailSuccessfully />
          <TitleH2>{t('auth.link_sent', { ns: 'auth' })}</TitleH2>
          <Paragraph>{t('auth.successfully_registration_description', { ns: 'auth' })}</Paragraph>
          <ButtonAction onClick={() => resendActivation()}>
            {t('auth.send_again', { ns: 'auth' })}
          </ButtonAction>
          {successResend && (
            <Message variant={'success'} onClose={() => setSuccessResend(false)}>
              {t('auth.send_success', { ns: 'auth' })}
            </Message>
          )}
          {errorResend && (
            <Message onClose={() => setErrorResend(null)}>
              {t('errors.try_again_later', { ns: 'errors' })}
            </Message>
          )}
          <ButtonAction onClick={() => setStep(RegisterSteps.confirm)}>
            {t('auth.change_email', { ns: 'auth' })}
          </ButtonAction>
        </FormAuth>
        {!isBusiness && <FooterAuth />}
      </PageAuth>
    );
  }

  return (
    <AuthSection isBusiness={isBusiness} onSubmit={submit} data-testid={'form-registration'}>
      <FormProvider {...formMethods}>
        {pending && <PageLoading />}

        <AuthTitleSection>
          <Title>{t('auth.creating_account', { ns: 'auth' })}</Title>
          <LinkNext to={'/login'}>{t('auth.have_account', { ns: 'auth' })}</LinkNext>
        </AuthTitleSection>

        <AuthCard>
          <AuthCardTitle>
            <span>1</span>
            {t('auth.registration_data', { ns: 'auth' })}
          </AuthCardTitle>

          <AuthItem>
            <AuthItemTitle>Email</AuthItemTitle>
            <ControlledSimpleInput
              name={'email'}
              placeholder={'mail@example.com'}
              dataTestId={'email'}
            />
          </AuthItem>

          <AuthItem>
            <AuthItemTitle>{t('auth.come_up_password', { ns: 'auth' })}</AuthItemTitle>
            <AuthItemDescription>
              {t('auth.new_password_description', { ns: 'auth' })}
            </AuthItemDescription>
            <ControlledSimpleInput
              name={'password'}
              placeholder={`${t('auth.enter_password', { ns: 'auth' })}`}
              type={'password'}
              dataTestId={'password'}
            />
            <ControlledSimpleInput
              name={'password_repeated'}
              placeholder={`${t('auth.repeat_password', { ns: 'auth' })}`}
              type={'password'}
              dataTestId={'password_repeated'}
            />
          </AuthItem>

          <AuthItem>
            <AuthItemTitle>Telegram</AuthItemTitle>
            <AuthItemDescription>
              {t('auth.required_for_identity', { ns: 'auth' })}
            </AuthItemDescription>
            <ControlledSimpleInput
              name={'telegram_username'}
              placeholder={`${t('auth.placeholder_telegram', { ns: 'auth' })}`}
              dataTestId={'telegram_username'}
            />
          </AuthItem>
        </AuthCard>

        <AuthCard>
          <AuthCardTitle>
            <span>2</span>
            {t('auth.traffic_sources_and_experience', { ns: 'auth' })}
          </AuthCardTitle>

          <AuthItem>
            <AuthItemTitle>{t('auth.verticals_title', { ns: 'auth' })}</AuthItemTitle>
            <AuthItemDescription>
              {t('auth.verticals_description', { ns: 'auth' })}
            </AuthItemDescription>
            <ControlledCheckbox name={'isGambling'} dataTestId={'isGambling'}>
              {t('common.gambling', { ns: 'common' })}
            </ControlledCheckbox>
            <ControlledCheckbox name={'isBetting'}>
              {t('common.betting', { ns: 'common' })}
            </ControlledCheckbox>
            <ControlledCheckbox name={'isEducation'}>
              {t('common.education', { ns: 'common' })}
            </ControlledCheckbox>
            <ControlledCheckbox name={'isSoftware'}>
              {t('common.software', { ns: 'common' })}
            </ControlledCheckbox>
            {watch('isGambling') === false &&
              watch('isBetting') === false &&
              watch('isEducation') === false &&
              watch('isSoftware') === false && (
                <SimpleInputError>{t('auth.verticals_error', { ns: 'auth' })}</SimpleInputError>
              )}
          </AuthItem>

          <AuthItem>
            <AuthItemTitle>{t('auth.experience_title', { ns: 'auth' })}</AuthItemTitle>
            <AuthItemDescription>
              {t('auth.experience_description', { ns: 'auth' })}
            </AuthItemDescription>
            <ControlledTextarea
              name={'registration_message'}
              placeholder={`${t('auth.experience_placeholder', { ns: 'auth' })}`}
              max={3500}
              dataTestId={'registration_message'}
            />
          </AuthItem>
        </AuthCard>

        <AuthCard>
          <AuthCardTitle>
            <span>3</span>
            {t('auth.service_policy_other_agreements', { ns: 'auth' })}
          </AuthCardTitle>

          <AuthItem>
            <ControlledCheckbox name={'email_newsletter'} variant={'big'}>
              {t('auth.email_newsletters', { ns: 'auth' })}
            </ControlledCheckbox>
            <ControlledCheckbox name={'privacy'} variant={'big'} dataTestId={'privacy'}>
              {t('auth.accept_privacy_text1', { ns: 'auth' })}
              {t('auth.accept_privacy_terms', { ns: 'auth' })}
              {t('auth.accept_privacy_text2', { ns: 'auth' })}
              {t('auth.accept_privacy_link', { ns: 'auth' })}
              {t('auth.accept_privacy_text3', { ns: 'auth' })}
            </ControlledCheckbox>
            {!isBusiness && (
              <ControlledCheckbox name={'fraud'} variant={'big'} dataTestId={'fraud'}>
                {t('auth.fraud', { ns: 'auth' })}
              </ControlledCheckbox>
            )}
          </AuthItem>

          {error?.response.data && (
            <Message onClose={() => setError(null)}>
              {error.response.data.detail
                ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                : t('errors.try_again_later', { ns: 'errors' })}
              {error.response.data.detail === 'EMAIL_EXIST_BUT_NOT_ACTIVATE' && (
                <>
                  {'. '}
                  <SendAgain onClick={resendActivation}>
                    {t('auth.send_again_activate', { ns: 'auth' })}
                  </SendAgain>
                </>
              )}
            </Message>
          )}
          {successResend && (
            <Message variant={'success'} onClose={() => setSuccessResend(false)}>
              {t('auth.send_success', { ns: 'auth' })}
            </Message>
          )}

          <AuthSubmitButtonSection>
            <Button variant={'accent'} onClick={submit} dataTestId={'submit-registration'}>
              {t('auth.creat_account', { ns: 'auth' })}
            </Button>
          </AuthSubmitButtonSection>
        </AuthCard>
      </FormProvider>
      {!isBusiness && <FooterAuth />}
    </AuthSection>
  );
}
